import React, { useEffect } from "react";
import { Bar } from "@ant-design/plots";
import "./siteassessment.scss";

const BarChart = ({
  data,
  setData,
  stored,
  setStored,
  xField,
  yField,
  storageKey,
}) => {
  useEffect(() => {
    if (
      storageKey &&
      (!stored || !JSON.parse(sessionStorage.getItem(storageKey)))
    ) {
      sessionStorage.setItem(storageKey, JSON.stringify(data));
      setStored(true);
    }
  }, [data]);

  const config = {
    data: data,
    xField,
    yField,
    seriesField: "selected",
    legend: false,
    tooltip: {
      formatter: (d) => {
        let findData = data.find((item)=> item.name === d.name) 
        if (findData.data_status_flag === "partial data") {
          return { value: d.value + "*" + " %" };
        } else {
          return { value: d.value + " %" };
        }
      },
    },
    animation: false,
    label: {
      formatter: (d) => {
        if (d.value === -1) {
          return "No Data";
        } else if (d.data_status_flag === "partial data") {
          return d.value + "*" + " %";
        } else {
          return d.value + " %";
        }
      },
      position: "left",
      style: {
        fill: "#000000",
        fontWeight: "700",
        fontSize: 10.5,
      },
    },
    color: ({ selected }) => (selected ? "#0000a0" : "#8080cf"),
    scrollbar: {
      type: "vertical",
    },
    xAxis: {
      title: { text: "Adherence %", style: { fontWeight: "400" } },
    },
  };

  const onEvent = (e, event) => {
    if (event.type === "element:click") {
      let newData = JSON.parse(sessionStorage.getItem(storageKey));
      if (!newData) newData = JSON.parse(JSON.stringify(data));
      const index = newData.findIndex(
        (item) => event.data.data[yField] === item[yField]
      );

      if (index > -1) {
        newData[index].selected = !Boolean(newData[index].selected);
        setData(newData);
        sessionStorage.setItem(storageKey, JSON.stringify(newData));
      }
    }
  };

  return storageKey ? (
    <Bar
      {...config}
      onEvent={onEvent}
      style={{ background: "white", height: "60vh" }}
      className="barChart"
    />
  ) : (
    <Bar
      {...config}
      style={{ background: "white", height: "60vh" }}
      className="barChart"
    />
  );
};

export default BarChart;
