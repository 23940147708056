import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Collapse } from "antd";
import {
  raw_site_columns,
  raw_site_data,
  pack_site_columns,
  pack_site_data,
  raw_material_columns,
  raw_material_data,
  pack_material_columns,
  pack_material_data,
} from "./faq-columns";
import titles from "../../utilities/generic-titles";
const { Panel } = Collapse;

const FaqCalculations = () => {
  const onChange = (key) => {};
  const { selectedView } = useSelector((state) => state.homeView);
  const [componentsToRender, setComponentsToRender] = useState({});
  useEffect(() => {
    if (selectedView === "Raw") {
      setComponentsToRender({
        materialColumns: raw_material_columns,
        materialData: raw_material_data,
        matAdhContent: titles.faq_titles.raw_mat_adh_content,
        siteColumns: raw_site_columns,
        siteData: raw_site_data,
      });
    } else if (selectedView === "Packaging") {
      setComponentsToRender({
        materialColumns: pack_material_columns,
        materialData: pack_material_data,
        matAdhContent: titles.faq_titles.pack_mat_adh_content,
        siteColumns: pack_site_columns,
        siteData: pack_site_data,
      });
    }
  }, [selectedView]);
  return (
    <>
      <Collapse
        onChange={onChange}
        className="calculations-tab"
        defaultActiveKey="333"
      >
        <Panel header={titles.faq_titles.adh_formula_header} key="333">
          <span className="faq-text-black">
            {titles.faq_titles.adh_formula}
          </span>
        </Panel>
        <Panel header={titles.faq_titles.mat_adh} key="111">
          <Table
            columns={componentsToRender.materialColumns}
            dataSource={componentsToRender.materialData}
            size="small"
            pagination={false}
          />
          <span className="faq-text-blue">
            {componentsToRender.matAdhContent}
          </span>
        </Panel>
        <Panel header={titles.faq_titles.site_adh} key="222">
          <span className="faq-text-black">
            {titles.faq_titles.site_adh_content}
          </span>
          <Table
            className="calculation-table"
            columns={componentsToRender.siteColumns}
            dataSource={componentsToRender.siteData}
            size="small"
            pagination={false}
          />
        </Panel>
      </Collapse>
    </>
  );
};
export default FaqCalculations;
