import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getManageAccessTableData,
  actionForAdmin,
} from "../../slicers/manage-access-slicer";
import { getColumnSearchProps } from "../../utilities/helper";
import moment from "moment";
import { Space } from "antd";
import tickicon from "../../assets/images/tickicon.png";
import crossicon from "../../assets/images/crossicon.png";

const ManageAccessColumns = () => {
  const dispatch = useDispatch();
  const { appRole } = useSelector((state) => state.manageAccess);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleApproval = (record) => {
    const { user_oid, name, email } = record;
    dispatch(
      actionForAdmin({
        oid: user_oid,
        name: name,
        email: email,
        approvalOption: "Approve",
      })
    );
    dispatch(getManageAccessTableData());
  };
  const handleRejection = (record) => {
    const { user_oid, name, email } = record;
    dispatch(
      actionForAdmin({
        oid: user_oid,
        name: name,
        email: email,
        approvalOption: "Reject",
      })
    );
    dispatch(getManageAccessTableData());
  };
  const columns = [
    {
      title: "Name",
      width: "2.5vw",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps(
        "Name",
        "name",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.name.toLowerCase() !== b.name.toLowerCase()
          ? a.name.toLowerCase() < b.name.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Segment",
      width: "2vw",
      dataIndex: "requested_segment",
      key: "requested_segment",
      ...getColumnSearchProps(
        "requested_Segment",
        "requested_segment",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.requested_segment.toLowerCase() !== b.requested_segment.toLowerCase()
          ? a.requested_segment.toLowerCase() <
            b.requested_segment.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Division",
      width: "2vw",
      dataIndex: "requested_division",
      key: "requested_division",
      ...getColumnSearchProps(
        "requested_division",
        "requested_division",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.requested_division.toLowerCase() !==
        b.requested_division.toLowerCase()
          ? a.requested_division.toLowerCase() <
            b.requested_division.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "BU",
      width: "2vw",
      dataIndex: "requested_bu",
      key: "requested_bu",
      ...getColumnSearchProps(
        "BU",
        "requested_bu",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.requested_bu.toLowerCase() !== b.requested_bu.toLowerCase()
          ? a.requested_bu.toLowerCase() < b.requested_bu.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Function",
      width: "2vw",
      dataIndex: "user_function",
      key: "user_function",
      ...getColumnSearchProps(
        "Function",
        "user_function",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.user_function.toLowerCase() !== b.user_function.toLowerCase()
          ? a.user_function.toLowerCase() < b.user_function.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Reason",
      width: "3vw",
      dataIndex: "reason",
      key: "reason",
      ...getColumnSearchProps(
        "Reason",
        "reason",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.reason.toLowerCase() !== b.reason.toLowerCase()
          ? a.reason.toLowerCase() < b.reason.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Approval Status",
      width: "2vw",
      dataIndex: "request_status",
      key: "request_status",
      ...getColumnSearchProps(
        "Approval Status",
        "request_status",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.request_status.toLowerCase() !== b.request_status.toLowerCase()
          ? a.request_status.toLowerCase() < b.request_status.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Created",
      width: "2vw",
      dataIndex: "created_on",
      key: "created_on",
      ...getColumnSearchProps(
        "Created",
        "created_on",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      render: (val, recor) => {
        if (val != "None" && val) {
          var a = moment(val);
          var b = moment();
          let diff = b.diff(a, "days"); // 1
          let diff_hours = b.diff(a, "hours");
          let days = diff + " day(s) ago";
          let hours = diff_hours + " hr(s)";

          return diff > 0 ? days : "Today";
        } else {
          return "-";
        }
      },
      sorter: (a, b) =>
        a.created_on.toLowerCase() !== b.created_on.toLowerCase()
          ? a.created_on.toLowerCase() < b.created_on.toLowerCase()
            ? -1
            : 1
          : 0,
    },
  ];
  if (appRole === "Super.Admin" || appRole === "Super-Admin") {
    columns.push({
      title: "Actions",
      width: "1.5vw",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) =>
        record.request_status == "pending" ? (
          <Space size="middle">
            <img
              className="tick-icon"
              src={tickicon}
              width={20}
              height={20}
              onClick={() => {
                {
                  handleApproval(record);
                }
              }}
            />
            <img
              className="tick-icon"
              src={crossicon}
              width={20}
              height={20}
              onClick={() => {
                {
                  handleRejection(record);
                }
              }}
            />
          </Space>
        ) : (
          <Space size="middle">
            <img className="grey-tick" src={tickicon} width={20} height={20} />
            <img className="grey-tick" src={crossicon} width={20} height={20} />
          </Space>
        ),
      align: "center",
    });
  }
  return columns;
};

export default ManageAccessColumns;
