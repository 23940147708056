import { configureStore } from "@reduxjs/toolkit";
import homeViewReducer from "./slicers/home-slicer";
import corporateAssessmentReducer from "./slicers/corporate-assessment-slicer";
import materialAssessmentReducer from "./slicers/material-assessment-slicer";
import siteAssessmentReducer from "./slicers/site-assessment-slicer";
import sidebarFiltersReducer from "./slicers/sidebar-filters-slicer";
import adminInputToolsReducer from "./slicers/admin-input-tools-slicer";
import trendViewReducer from "./slicers/trend-view-slicer";
import requestAccess from "./slicers/request-access-slicer";
import manageAccessReducer from "./slicers/manage-access-slicer";

const store = configureStore({
  reducer: {
    homeView: homeViewReducer,
    corporateAssessment: corporateAssessmentReducer,
    materialAssessment: materialAssessmentReducer,
    siteAssessment: siteAssessmentReducer,
    sidebarFilters: sidebarFiltersReducer,
    adminInputTools: adminInputToolsReducer,
    trendView: trendViewReducer,
    requestAccess: requestAccess,
    manageAccess: manageAccessReducer,
  },
});

export default store;

const getStore = () => store;
const getState = () => store.getState();

export { getState, getStore };
