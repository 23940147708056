import React, { memo, useEffect, useState } from "react";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSelectedSiteCode } from "../../slicers/corporate-assessment-slicer";
import {
  setAppliedFilters,
  setFilters,
  getLeftSectionFilters,
} from "../../slicers/sidebar-filters-slicer";
import "./style.scss";
import zoom_in from "../../assets/images/zoom_in.png";
import zoom_out from "../../assets/images/zoom_out.png";
import { Popover, Col } from "antd";

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";
const width = 1200;
const height = 750;

const MapChart = ({ legends, thresholdAPIData, thresholdIDMapping }) => {
  const { worldMap_points } = useSelector((state) => state.corporateAssessment);
  const {
    filters,
    appliedFilters,
    is_out_of_scope_selected,
    last_selected_filter_name,
    last_selected_filter_val,
  } = useSelector((state) => state.sidebarFilters);
  const { selectedView } = useSelector((state) => state.homeView);

  const dispatch = useDispatch();
  const history = useHistory();
  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1.25 });
  const [points, setPoints] = useState([]);
  const [circleRadius, setCircleRadius] = useState(3);
  const [squareSide, setSquareSide] = useState(5);
  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
    setCircleRadius(circleRadius - 1);
    setSquareSide(squareSide - 1);
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
    setCircleRadius(circleRadius + 1);
    setSquareSide(squareSide + 1);
  }

  function handleMoveEnd() {
    let tempPosition = position;
    setPosition(tempPosition);
  }
  function handleOnMove(e) {
    if (e.zoom <= 2) {
      setCircleRadius(3);
    } else if (e.zoom <= 4) {
      setCircleRadius(2);
    } else {
      setCircleRadius(1);
    }
  }
  useEffect(() => {
    if (!worldMap_points || !Array.isArray(worldMap_points))
      return setPoints([]);
    if (
      !is_out_of_scope_selected &&
      Object.entries(thresholdIDMapping).length
    ) {
      const newPoints = worldMap_points.filter(({ compliance_percent }) => {
        return (
          (legends[2] ? compliance_percent === -1 : false) ||
          (legends[3]
            ? compliance_percent >= thresholdIDMapping[3].lt &&
              compliance_percent <= thresholdIDMapping[3].ut
            : false) ||
          (legends[4]
            ? compliance_percent > thresholdIDMapping[4].lt &&
              compliance_percent <= thresholdIDMapping[4].ut
            : false) ||
          (legends[5]
            ? compliance_percent > thresholdIDMapping[5].lt &&
              compliance_percent <= thresholdIDMapping[5].ut
            : false) ||
          (legends[6]
            ? compliance_percent > thresholdIDMapping[6].lt &&
              compliance_percent <= thresholdIDMapping[6].ut
            : false) ||
          (legends[7]
            ? compliance_percent > thresholdIDMapping[7].lt &&
              compliance_percent <= thresholdIDMapping[7].ut
            : false)
        );
      });
      setPoints(newPoints);
    } else {
      setPoints(worldMap_points);
    }
  }, [legends, worldMap_points]);

  const getMapPointShape = (
    data_status_flag,
    selectedView,
    circleRadius,
    squareSide,
    compliance_color,
    filters,
    site_code,
    is_out_of_scope_selected,
    appliedFilters
  ) => {
    if (data_status_flag === "out of scope") {
      if (selectedView === "Packaging") {
        return (
          <rect
            width={squareSide}
            height={squareSide}
            fill={compliance_color}
            stroke="#fff"
            strokeWidth="0.5"
          />
        );
      } else {
        return (
          <circle
            r={circleRadius}
            fill={"white"}
            stroke="black"
            strokeWidth="1"
          />
        );
      }
    } else {
      let currentFilters =
        Object.entries(filters).length > 0
          ? {
              [last_selected_filter_name]: last_selected_filter_val,
            }
          : filters;
      if (selectedView === "Packaging") {
        return (
          <rect
            width={squareSide}
            height={squareSide}
            fill={compliance_color}
            stroke="#fff"
            strokeWidth="0.5"
            onClick={() =>
              dispatch(
                getLeftSectionFilters(
                  "corpAssessment",
                  {
                    currentFilters,
                    site_code: [site_code],
                  },
                  is_out_of_scope_selected,
                  "site_code"
                )
              ) &&
              dispatch(setSelectedSiteCode(site_code)) &&
              dispatch(
                setFilters({
                  ...filters,
                  site_code: [site_code],
                })
              ) &&
              dispatch(
                setAppliedFilters({
                  ...appliedFilters,
                  site_code: [site_code],
                })
              ) &&
              history.push("/assessment")
            }
          />
        );
      } else {
        return (
          <circle
            r={circleRadius}
            fill={compliance_color}
            stroke="#fff"
            strokeWidth="0.5"
            onClick={() =>
              dispatch(
                getLeftSectionFilters(
                  "corpAssessment",
                  {
                    currentFilters,
                    site_code: [site_code],
                  },
                  is_out_of_scope_selected,
                  "site_code"
                )
              ) &&
              dispatch(setSelectedSiteCode(site_code)) &&
              dispatch(
                setFilters({
                  ...filters,
                  site_code: [site_code],
                })
              ) &&
              dispatch(
                setAppliedFilters({
                  ...appliedFilters,
                  site_code: [site_code],
                })
              ) &&
              history.push("/assessment")
            }
          />
        );
      }
    }
  };
  return (
    <div data-tip="" className="corporate-map">
      <ComposableMap
        width={width}
        height={height}
        projection="geoEquirectangular"
        projectionConfig={{
          rotate: [-2.7, -2.7, -2.7],
          center: [0, 0],
          scale: 150,
        }}
      >
        <ZoomableGroup
          zoom={position.zoom}
          center={position.coordinates}
          onMoveEnd={handleMoveEnd}
          onMove={handleOnMove}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: {
                      fill: "#E4E5E6",
                      outline: "none",
                      stroke: "#232323",
                      strokeWidth: "0.3",
                    },
                    hover: {
                      fill: "#E4E5E6",
                      outline: "none",
                      stroke: "#232323",
                      strokeWidth: "0.3",
                    },
                    pressed: {
                      fill: "#E4E5E6",
                      outline: "none",
                      stroke: "#232323",
                      strokeWidth: "0.3",
                    },
                  }}
                />
              ))
            }
          </Geographies>
          {points.map(
            ({
              site_code,
              site_unit_name,
              map_latitude,
              map_longitude,
              compliance_color,
              compliance_percent,
              site_id,
              site_region,
              partial_data_flag,
              data_status_flag,
              atlas_plant_codes,
            }) => (
              <Marker
                key={site_unit_name}
                coordinates={[map_longitude, map_latitude]}
              >
                <Popover
                  content={
                    <div className="marker-tooltip-content">
                      <Col className="wrapper">
                        <li className="list">
                          <h4 className="title">Site</h4>
                          <span className="list-value">{site_unit_name}</span>
                        </li>
                      </Col>
                      {atlas_plant_codes && (
                        <Col className="wrapper">
                          <li className="list">
                            <h4 className="title">Plant code</h4>
                            <span className="list-value">
                              {atlas_plant_codes}
                            </span>
                          </li>
                        </Col>
                      )}
                      <Col className="wrapper">
                        {partial_data_flag === true &&
                        data_status_flag === "partial data" ? (
                          <li className="list">
                            <h4 className="title">Adherence %</h4>
                            <span className="list-value">
                              {compliance_percent.toFixed(2) + "*"}
                            </span>
                          </li>
                        ) : data_status_flag === "full data" ? (
                          <li className="list">
                            <h4 className="title">Adherence %</h4>
                            <span className="list-value">
                              {compliance_percent.toFixed(2)}
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col className="wrapper">
                        <li className="list">
                          <h4 className="title">Region</h4>
                          <span className="list-value">{site_region}</span>
                        </li>
                      </Col>
                      <Col className="wrapper">
                        {data_status_flag === "no data" ? (
                          <li className="note-list">
                            <span className="list-value">
                              Site details not available
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                        {data_status_flag === "out of scope" ? (
                          <li className="note-list">
                            <span className="list-value">
                              Site is out of scope
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                      </Col>
                    </div>
                  }
                >
                  {getMapPointShape(
                    data_status_flag,
                    selectedView,
                    circleRadius,
                    squareSide,
                    compliance_color,
                    filters,
                    site_code,
                    is_out_of_scope_selected,
                    appliedFilters
                  )}
                </Popover>
              </Marker>
            )
          )}
        </ZoomableGroup>
      </ComposableMap>
      <div className="controls">
        <button onClick={handleZoomIn}>
          <img src={zoom_in}></img>
        </button>
        <button onClick={handleZoomOut}>
          <img src={zoom_out}></img>
        </button>
      </div>
    </div>
  );
};

export default memo(MapChart);
