import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaterialAssessmentData,
  setMatSearchParams,
  setSearchFlag,
} from "../../slicers/material-assessment-slicer";
import { Input, Space, Button } from "antd";
import { SearchOutlined, InfoCircleFilled } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "./materialassessment.scss";

const MaterialAssessmentColumns = (flagForResetSearch, pageVal) => {
  const dispatch = useDispatch();
  const {
    is_out_of_scope_selected,
    appliedFilters,
    last_selected_filter_name,
    last_selected_filter_val,
    oos_last_selected_filter_name,
    oos_last_selected_filter_val,
  } = useSelector((state) => state.sidebarFilters);
  const { material_pagination, resetSearchFlag, searchParams } = useSelector(
    (state) => state.materialAssessment
  );
  const { selectedView } = useSelector((state) => state.homeView);
  const [searchTextInput, setSearchTextInput] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (
    confirm,
    setSearchTextInput,
    setSearchedColumn,
    selectedKeys,
    dataIndex
  ) => {
    confirm();
    setSearchTextInput(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  useEffect(() => {
    if (flagForResetSearch === true) {
      setSearchTextInput("");
      setSearchedColumn("");
      //anusha why check only for in scope applied filters ? why not for oos
      if (Object.entries(appliedFilters).length) {
        let currentFilters;
        //change payload
        if (is_out_of_scope_selected) {
          currentFilters = {
            [oos_last_selected_filter_name]: oos_last_selected_filter_val,
          };
        } else {
          currentFilters = {
            [last_selected_filter_name]: last_selected_filter_val,
          };
        }
        dispatch(
          getMaterialAssessmentData(
            currentFilters,
            material_pagination,
            [],
            is_out_of_scope_selected,
            searchParams,
            selectedView
          )
        );
      } else {
        dispatch(
          getMaterialAssessmentData(
            {},
            material_pagination,
            [],
            is_out_of_scope_selected,
            searchParams,
            selectedView
          )
        );
      }
    }
  }, [resetSearchFlag]);

  const getMaterialColSearchProps = (
    title,
    dataIndex,
    searchTextInput,
    setSearchTextInput,
    searchedColumn,
    setSearchedColumn,
    searchInput
  ) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            handleSearch(
              confirm,
              setSearchTextInput,
              setSearchedColumn,
              selectedKeys,
              dataIndex
            );
            let tempText = selectedKeys[0];
            dispatch(setSearchFlag(true));
            dispatch(
              setMatSearchParams({
                searchDataIndex: dataIndex,
                searchTerm: tempText,
              })
            );
          }}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              handleSearch(
                confirm,
                setSearchTextInput,
                setSearchedColumn,
                selectedKeys,
                dataIndex
              );
              let tempText = selectedKeys[0];
              dispatch(setSearchFlag(true));
              dispatch(
                setMatSearchParams({
                  searchDataIndex: dataIndex,
                  searchTerm: tempText,
                })
              );
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : "#0000a0",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchTextInput]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const rawMaterialColumns = [
    {
      title: "Material Description",
      width: "14vw",
      dataIndex: "material_description",
      key: "material_description",
      ...getMaterialColSearchProps(
        "Material Description",
        "material_description",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.material_description.toLowerCase() !==
          b.material_description.toLowerCase()
        ) {
          if (
            a.material_description.toLowerCase() <
            b.material_description.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
    },
    {
      title: "Site Name",
      width: "14vw",
      dataIndex: "site_name",
      key: "site_name",
      ...getMaterialColSearchProps(
        "Site Name",
        "site_name",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (a.site_name.toLowerCase() !== b.site_name.toLowerCase()) {
          if (a.site_name.toLowerCase() < b.site_name.toLowerCase()) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
    },
    {
      title: "Process Adherence Status",
      width: "10vw",
      dataIndex: "overall_compliance",
      key: "overall_compliance",
      ...getMaterialColSearchProps(
        "Process Adherence Status",
        "overall_compliance",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.overall_compliance.toLowerCase() !==
          b.overall_compliance.toLowerCase()
        ) {
          if (
            a.overall_compliance.toLowerCase() <
            b.overall_compliance.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (overall_compliance, record) => {
        if (record.overall_compliance === "Adhering") {
          return (
            <span>
              <span className="overall_compliance adhering" />
              &nbsp;&nbsp;
              {overall_compliance}
            </span>
          );
        } else if (record.overall_compliance === "Non-Adhering") {
          return (
            <span>
              <span className="overall_compliance nonadhering" />
              &nbsp;&nbsp;
              {record.overall_compliance}
            </span>
          );
        } else if (record.overall_compliance === "Partially Adhering") {
          return (
            <span>
              <span className="overall_compliance partially-adhering" />
              &nbsp;&nbsp;
              {record.overall_compliance}
            </span>
          );
        } else if (record.overall_compliance === "No Data") {
          return (
            <span>
              <span className="overall_compliance no-data" />
              &nbsp;&nbsp;
              {record.overall_compliance}{" "}
            </span>
          );
        } else {
          return <span>{record.overall_compliance}</span>;
        }
      },
    },
    // {
    //   title: "Days of non-adherence",
    //   width: "10vw",
    //   dataIndex: "countofdays_adhto_nonadh",
    //   key: "countofdays_adhto_nonadh",
    //   ...getMaterialColSearchProps(
    //     "Days of non-adherence",
    //     "countofdays_adhto_nonadh",
    //     searchTextInput,
    //     setSearchTextInput,
    //     searchedColumn,
    //     setSearchedColumn,
    //     searchInput
    //   ),
    //   sorter: (a, b) => a.countofdays_adhto_nonadh - b.countofdays_adhto_nonadh,
    // },
    {
      title: "Supplier Name",
      width: "10vw",
      dataIndex: "supplier_name",
      key: "supplier_name",
      ...getMaterialColSearchProps(
        "Supplier Name",
        "supplier_name",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => a.supplier_name - b.supplier_name,
    },
    {
      title: "Inbound Verification Plan Readiness",
      width: "10vw",
      dataIndex: "ibv_status",
      key: "ibv_status",
      ...getMaterialColSearchProps(
        "Inbound Verification Plan Readiness",
        "ibv_status",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (a.ibv_status.toLowerCase() !== b.ibv_status.toLowerCase()) {
          if (a.ibv_status.toLowerCase() < b.ibv_status.toLowerCase()) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (text) => {
        if (text.trim() === "Approved") {
          return <span className="green">{text}</span>;
        } else if (text.trim() === "Not Approved") {
          return <span className="red">{text}</span>;
        } else if (text.trim() === "No Data") {
          return <span className="black">{text}</span>;
        } else {
          return <span>{text}</span>;
        }
      },
    },
    {
      title: "MRA Number",
      width: "10vw",
      dataIndex: "mra_pra_number",
      key: "mra_pra_number",
      ...getMaterialColSearchProps(
        "MRA Number",
        "mra_pra_number",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (a.mra_pra_number.toLowerCase() !== b.mra_pra_number.toLowerCase()) {
          if (a.mra_pra_number.toLowerCase() < b.mra_pra_number.toLowerCase()) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Material Risk Assessment (MRA) Status",
      width: "10vw",
      dataIndex: "mra_pra_status_val",
      key: "mra_pra_status_val",
      ...getMaterialColSearchProps(
        "Material Risk Assessment (MRA) Status",
        "mra_pra_status_val",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.mra_pra_status_val.toLowerCase() !==
          b.mra_pra_status_val.toLowerCase()
        ) {
          if (
            a.mra_pra_status_val.toLowerCase() <
            b.mra_pra_status_val.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Material Risk",
      width: "10vw",
      dataIndex: "mra_pra_overallqfsrisk",
      key: "mra_pra_overallqfsrisk",
      ...getMaterialColSearchProps(
        "Material Risk",
        "mra_pra_overallqfsrisk",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.mra_pra_overallqfsrisk.toLowerCase() !==
          b.mra_pra_overallqfsrisk.toLowerCase()
        ) {
          if (
            a.mra_pra_overallqfsrisk.toLowerCase() <
            b.mra_pra_overallqfsrisk.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Specification Status",
      width: "10vw",
      dataIndex: "spec_status_val",
      key: "spec_status_val",
      ...getMaterialColSearchProps(
        "Specification Status",
        "spec_status_val",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.spec_status_val.toLowerCase() !== b.spec_status_val.toLowerCase()
        ) {
          if (
            a.spec_status_val.toLowerCase() < b.spec_status_val.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Supplier Status",
      width: "8vw",
      dataIndex: "supplier_status_val",
      key: "supplier_status_val",
      ...getMaterialColSearchProps(
        "Supplier Status",
        "supplier_status_val",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.supplier_status_val.toLowerCase() !==
          b.supplier_status_val.toLowerCase()
        ) {
          if (
            a.supplier_status_val.toLowerCase() <
            b.supplier_status_val.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Supplier Audit Current",
      width: "10vw",
      dataIndex: "supplier_audit_current",
      key: "supplier_audit_current",
      ...getMaterialColSearchProps(
        "Supplier Audit Current",
        "supplier_audit_current",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.supplier_audit_current.toLowerCase() !==
          b.supplier_audit_current.toLowerCase()
        ) {
          if (
            a.supplier_audit_current.toLowerCase() <
            b.supplier_audit_current.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
    },
    {
      title: "Last Supplier Audit Outcome",
      width: "10vw",
      dataIndex: "supplier_last_audit_outcome_val",
      key: "supplier_last_audit_outcome_val",
      ...getMaterialColSearchProps(
        "Last Supplier Audit Outcome",
        "supplier_last_audit_outcome_val",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.supplier_last_audit_outcome_val.toLowerCase() !==
          b.supplier_last_audit_outcome_val.toLowerCase()
        ) {
          if (
            a.supplier_last_audit_outcome_val.toLowerCase() <
            b.supplier_last_audit_outcome_val.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "GSL Address",
      width: "10vw",
      dataIndex: "supplier_address",
      key: "supplier_address",
      ...getMaterialColSearchProps(
        "GSL Address",
        "supplier_address",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.supplier_address.toLowerCase() !== b.supplier_address.toLowerCase()
        ) {
          if (
            a.supplier_address.toLowerCase() < b.supplier_address.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
    },
    {
      title: "Months since Last Goods Receipt",
      width: "8vw",
      dataIndex: "ibv_month_since_last_use",
      key: "ibv_month_since_last_use",
      ...getMaterialColSearchProps(
        "Months since Last Goods Receipt",
        "ibv_month_since_last_use",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => a.ibv_month_since_last_use - b.ibv_month_since_last_use,
    },
  ];
  const packagingColumns = [
    {
      title: "Material Description",
      width: "14vw",
      dataIndex: "material_description",
      key: "material_description",
      ...getMaterialColSearchProps(
        "Material Description",
        "material_description",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.material_description.toLowerCase() !==
          b.material_description.toLowerCase()
        ) {
          if (
            a.material_description.toLowerCase() <
            b.material_description.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
    },
    {
      title: "Site Name",
      width: "14vw",
      dataIndex: "site_name",
      key: "site_name",
      ...getMaterialColSearchProps(
        "Site Name",
        "site_name",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (a.site_name.toLowerCase() !== b.site_name.toLowerCase()) {
          if (a.site_name.toLowerCase() < b.site_name.toLowerCase()) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
    },
    {
      title: "Process Adherence Status",
      width: "10vw",
      dataIndex: "overall_compliance",
      key: "overall_compliance",
      ...getMaterialColSearchProps(
        "Process Adherence Status",
        "overall_compliance",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.overall_compliance.toLowerCase() !==
          b.overall_compliance.toLowerCase()
        ) {
          if (
            a.overall_compliance.toLowerCase() <
            b.overall_compliance.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (overall_compliance, record) => {
        if (record.overall_compliance === "Adhering") {
          return (
            <span>
              <span className="overall_compliance adhering" />
              &nbsp;&nbsp;
              {overall_compliance}
            </span>
          );
        } else if (record.overall_compliance === "Non-Adhering") {
          return (
            <span>
              <span className="overall_compliance nonadhering" />
              &nbsp;&nbsp;
              {record.overall_compliance}
            </span>
          );
        } else if (record.overall_compliance === "Partially Adhering") {
          return (
            <span>
              <span className="overall_compliance partially-adhering" />
              &nbsp;&nbsp;
              {record.overall_compliance}
            </span>
          );
        } else if (record.overall_compliance === "No Data") {
          return (
            <span>
              <span className="overall_compliance no-data" />
              &nbsp;&nbsp;
              {record.overall_compliance}{" "}
            </span>
          );
        } else {
          return <span>{record.overall_compliance}</span>;
        }
      },
    },
    // {
    //   title: "Days of non-adherence",
    //   width: "10vw",
    //   dataIndex: "countofdays_adhto_nonadh",
    //   key: "countofdays_adhto_nonadh",
    //   ...getMaterialColSearchProps(
    //     "Days of non-adherence",
    //     "countofdays_adhto_nonadh",
    //     searchTextInput,
    //     setSearchTextInput,
    //     searchedColumn,
    //     setSearchedColumn,
    //     searchInput
    //   ),
    //   sorter: (a, b) => a.countofdays_adhto_nonadh - b.countofdays_adhto_nonadh,
    // },
    {
      title: "Supplier Name",
      width: "10vw",
      dataIndex: "supplier_name",
      key: "supplier_name",
      ...getMaterialColSearchProps(
        "Supplier Name",
        "supplier_name",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => a.supplier_name - b.supplier_name,
    },
    {
      title: "Inbound Verification Plan Readiness",
      width: "10vw",
      dataIndex: "ibv_status",
      key: "ibv_status",
      ...getMaterialColSearchProps(
        "Inbound Verification Plan Readiness",
        "ibv_status",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (a.ibv_status.toLowerCase() !== b.ibv_status.toLowerCase()) {
          if (a.ibv_status.toLowerCase() < b.ibv_status.toLowerCase()) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (text) => {
        if (text.trim() === "Approved") {
          return <span className="green">{text}</span>;
        } else if (text.trim() === "Not Approved") {
          return <span className="red">{text}</span>;
        } else if (text.trim() === "No Data") {
          return <span className="black">{text}</span>;
        } else {
          return <span>{text}</span>;
        }
      },
    },
    {
      title: "Specification Status",
      width: "10vw",
      dataIndex: "spec_status_val",
      key: "spec_status_val",
      ...getMaterialColSearchProps(
        "Specification Status",
        "spec_status_val",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.spec_status_val.toLowerCase() !== b.spec_status_val.toLowerCase()
        ) {
          if (
            a.spec_status_val.toLowerCase() < b.spec_status_val.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Supplier Status",
      width: "8vw",
      dataIndex: "supplier_status_val",
      key: "supplier_status_val",
      ...getMaterialColSearchProps(
        "Supplier Status",
        "supplier_status_val",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.supplier_status_val.toLowerCase() !==
          b.supplier_status_val.toLowerCase()
        ) {
          if (
            a.supplier_status_val.toLowerCase() <
            b.supplier_status_val.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Last Supplier Audit Outcome",
      width: "10vw",
      dataIndex: "supplier_last_audit_outcome_val",
      key: "supplier_last_audit_outcome_val",
      ...getMaterialColSearchProps(
        "Last Supplier Audit Outcome",
        "supplier_last_audit_outcome_val",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.supplier_last_audit_outcome_val.toLowerCase() !==
          b.supplier_last_audit_outcome_val.toLowerCase()
        ) {
          if (
            a.supplier_last_audit_outcome_val.toLowerCase() <
            b.supplier_last_audit_outcome_val.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "PRA Number",
      width: "10vw",
      dataIndex: "mra_pra_number",
      key: "mra_pra_number",
      ...getMaterialColSearchProps(
        "PRA Number",
        "mra_pra_number",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (a.mra_pra_number.toLowerCase() !== b.mra_pra_number.toLowerCase()) {
          if (a.mra_pra_number.toLowerCase() < b.mra_pra_number.toLowerCase()) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
    },
    {
      title: "Packaging Risk Assessment (PRA) Status",
      width: "10vw",
      dataIndex: "mra_pra_status_val",
      key: "mra_pra_status_val",
      ...getMaterialColSearchProps(
        "Packaging Risk Assessment (PRA) Status",
        "mra_pra_status_val",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.mra_pra_status_val.toLowerCase() !==
          b.mra_pra_status_val.toLowerCase()
        ) {
          if (
            a.mra_pra_status_val.toLowerCase() <
            b.mra_pra_status_val.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Packaging Risk",
      width: "10vw",
      dataIndex: "mra_pra_overallqfsrisk",
      key: "mra_pra_overallqfsrisk",
      ...getMaterialColSearchProps(
        "Packaging Risk",
        "mra_pra_overallqfsrisk",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.mra_pra_overallqfsrisk.toLowerCase() !==
          b.mra_pra_overallqfsrisk.toLowerCase()
        ) {
          if (
            a.mra_pra_overallqfsrisk.toLowerCase() <
            b.mra_pra_overallqfsrisk.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
    },
    {
      title: "GSL Address",
      width: "10vw",
      dataIndex: "supplier_address",
      key: "supplier_address",
      ...getMaterialColSearchProps(
        "GSL Address",
        "supplier_address",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => {
        if (
          a.supplier_address.toLowerCase() !== b.supplier_address.toLowerCase()
        ) {
          if (
            a.supplier_address.toLowerCase() < b.supplier_address.toLowerCase()
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
    },
    {
      title: "Months since Last Goods Receipt",
      width: "8vw",
      dataIndex: "ibv_month_since_last_use",
      key: "ibv_month_since_last_use",
      ...getMaterialColSearchProps(
        "Months since Last Goods Receipt",
        "ibv_month_since_last_use",
        searchTextInput,
        setSearchTextInput,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) => a.ibv_month_since_last_use - b.ibv_month_since_last_use,
    },
  ];
  if (pageVal === "Raw") {
    return rawMaterialColumns;
  } else if (pageVal === "Packaging") {
    return packagingColumns;
  }
};

export default MaterialAssessmentColumns;
