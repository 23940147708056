import { createSlice } from "@reduxjs/toolkit";
import {
  Base_URL,
  left_section_filters,
  trendCharts,
  changeSummaryData,
} from "../utilities/constants";
import CustomAxios from "../utilities/service/api-service";
import CheckAuthorization from "../utilities/auth/check-authorization";
import { showErrorMessage } from "../utilities/helper";
const initialState = {
  chartsLoader: false,
  dropdownLoader: false,
  checkboxOn: true,
  noDataSelected: false,
  trendDataFilters: {},
  trendViewFilters: {
    site_segment: [],
    division: [],
    site_business_unit: [],
    site_unit_name: [],
  },
  site_segment: [],
  division: [],
  site_business_unit: [],
  site_unit_name: [],
  chartsData: [],
  segmentSelected: false,
  buSelected: false,
  siteSelected: false,
  selectedSegOptions: [],
  selectedBUOptions: [],
  selectedSiteOptions: [],
  selectedDivOptions: [],
  noDataSegments: [],
  noDataBUs: [],
  noDataSites: [],
  noDataDivisions: [],
  chartParameter: "",
  changeSummaryTableLoader: false,
  changeSummaryTableData: [],
  changeSummaryLegendData: [],
  resetSearch: false,
  divisionSelected: false,
  selectedDivOptions: [],
  noDataDivisions: [],
};

const trendViewSlicer = createSlice({
  name: "trendView",
  initialState,
  reducers: {
    setChartsLoader: (state, action) => ({
      ...state,
      chartsLoader: action.payload,
    }),
    setDropdownLoading: (state, action) => ({
      ...state,
      dropdownLoader: action.payload,
    }),
    setCheckboxOn: (state, action) => ({
      ...state,
      checkboxOn: action.payload,
    }),
    setNoDataSelected: (state, action) => ({
      ...state,
      noDataSelected: action.payload,
    }),
    setDataFilters: (state, action) => ({
      ...state,
      trendDataFilters: action.payload,
    }),
    setTrendFilters: (state, action) => ({
      ...state,
      trendViewFilters: action.payload,
    }),
    setDataDropdown: (state, action) => ({
      //sets otions for individual fields
      ...state,
      [action.payload[0]]: action.payload[1],
    }),
    setChartsData: (state, action) => ({
      ...state,
      chartsData: action.payload,
    }),
    setSegmentSelected: (state, action) => ({
      ...state,
      segmentSelected: action.payload,
    }),
    setDivisionSelected: (state, action) => ({
      ...state,
      divisionSelected: action.payload,
    }),
    setBUSelected: (state, action) => ({
      ...state,
      buSelected: action.payload,
    }),
    setSiteSelected: (state, action) => ({
      ...state,
      siteSelected: action.payload,
    }),
    setSelectedSegOptions: (state, action) => ({
      ...state,
      selectedSegOptions: action.payload,
    }),
    setSelectedDivOptions: (state, action) => ({
      ...state,
      selectedDivOptions: action.payload,
    }),
    setSelectedBUOptions: (state, action) => ({
      ...state,
      selectedBUOptions: action.payload,
    }),
    setSelectedSiteOptions: (state, action) => ({
      ...state,
      selectedSiteOptions: action.payload,
    }),
    setNoDataSegments: (state, action) => ({
      ...state,
      noDataSegments: action.payload,
    }),
    setNoDataDivisions: (state, action) => ({
      ...state,
      noDataDivisions: action.payload,
    }),
    setNoDataBUs: (state, action) => ({
      ...state,
      noDataBUs: action.payload,
    }),
    setNoDataSites: (state, action) => ({
      ...state,
      noDataSites: action.payload,
    }),
    setChartParameter: (state, action) => ({
      ...state,
      chartParameter: action.payload,
    }),
    setChangeSummaryTableLoader: (state, action) => ({
      ...state,
      changeSummaryTableLoader: action.payload,
    }),
    setChangeSummaryTableData: (state, action) => ({
      ...state,
      changeSummaryTableData: action.payload,
    }),
    setChangeSummaryLegendData: (state, action) => ({
      ...state,
      changeSummaryLegendData: action.payload,
    }),
    setResetSearch: (state, action) => ({
      ...state,
      resetSearch: action.payload,
    }),
  },
});

export const {
  setChartsLoader,
  setDropdownLoading,
  setCheckboxOn,
  setNoDataSelected,
  setDataFilters,
  setTrendFilters,
  setDataDropdown,
  setChartsData,
  setSegmentSelected,
  setDivisionSelected,
  setBUSelected,
  setSiteSelected,
  setSelectedSegOptions,
  setSelectedDivOptions,
  setSelectedBUOptions,
  setSelectedSiteOptions,
  setNoDataSegments,
  setNoDataDivisions,
  setNoDataBUs,
  setNoDataSites,
  setChartParameter,
  setChangeSummaryTableLoader,
  setChangeSummaryTableData,
  setChangeSummaryLegendData,
  setResetSearch,
} = trendViewSlicer.actions;

export const getTrendViewFilters =
  (module, filters, oos_flag, keyName = "") =>
  async (dispatch, getState) => {
    let urlFilters = { ...filters };
    Object.keys(urlFilters).forEach((key) => {
      if (urlFilters[key].length === 0) {
        delete urlFilters[key];
      }
    });
    dispatch(setDropdownLoading(true));
    CustomAxios(
      Base_URL +
        left_section_filters +
        "?only_oos=" +
        oos_flag +
        "&module=" +
        module,
      "POST",
      urlFilters
    )
      .then((response) => {
        if (response.data.error_code === undefined) {
          let dataFilters = response.data;
          let temp = dataFilters.division.filter(
            (d) => d !== "Food N/A"
          );
          let newData = Object.assign({}, dataFilters);
          newData.division = temp;

          dispatch(setDataFilters(newData));

          dataFilters.site_business_unit &&
            dataFilters.site_business_unit.length &&
            dispatch(
              setDataDropdown([
                "site_business_unit",
                dataFilters.site_business_unit,
              ])
            );
          dataFilters.site_segment &&
            dataFilters.site_segment.length &&
            dispatch(
              setDataDropdown(["site_segment", dataFilters.site_segment])
            );
          dataFilters.site_unit_name &&
            dataFilters.site_unit_name.length &&
            dispatch(
              setDataDropdown(["site_unit_name", dataFilters.site_unit_name])
            );
          if (dataFilters.division && dataFilters.division.length) {
            let temp = dataFilters.division.filter((d) => d !== "Food N/A");
            dispatch(setDataDropdown(["division", temp]));
          }
          dispatch(setDropdownLoading(false));
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error("Left Section Filters Dropdown API Failed", err);
        showErrorMessage();
        dispatch(setDropdownLoading(false));
      });
  };

export const getChartData =
  (level, options, timeSelection, type) => async (dispatch, getState) => {
    dispatch(setChartsLoader(true));
    const encodedOptions = [];
    for (const element of options) {
      encodedOptions.push(encodeURIComponent(element));
    }
    const selectedOptions = encodedOptions.join(",");
    CustomAxios(
      Base_URL +
        trendCharts +
        level +
        "?type=" +
        type +
        "&selections=" +
        selectedOptions +
        "&time_selection=" +
        timeSelection,
      "GET"
    )
      .then((response) => {
        dispatch(setChartsData(response.data));
        dispatch(setChartsLoader(false));
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error("Charts API Failed", err);
        dispatch(setChartsLoader(false));
      });
  };

export const getChangeSummaryTableData =
  (type) => async (dispatch, getState) => {
    dispatch(setChangeSummaryTableLoader(true));
    CustomAxios(Base_URL + changeSummaryData + "?type=" + type, "GET")
      .then((response) => {
        dispatch(setChangeSummaryTableData(response.data.cs));
        dispatch(setChangeSummaryLegendData(response.data.cl));
        dispatch(setChangeSummaryTableLoader(false));
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error("Change Summary API Failed", err);
        showErrorMessage();
      })
      .finally(() => {
        dispatch(setChangeSummaryTableLoader(false));
        dispatch(setResetSearch(false));
      });
  };

export default trendViewSlicer.reducer;
