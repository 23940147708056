import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Layout,
  Row,
  Col,
  Select,
  Radio,
  Switch,
  Checkbox,
  Button,
} from "antd";
import Wrapper from "../../components/wrapper";
import titles from "../../utilities/generic-titles";
import LineChart from "./line-chart";
import Loader from "../../components/loader";
import {
  setCheckboxOn,
  setNoDataSelected,
  setTrendFilters,
  setSegmentSelected,
  setBUSelected,
  setSiteSelected,
  setSelectedSegOptions,
  setSelectedBUOptions,
  setSelectedSiteOptions,
  setNoDataSegments,
  setNoDataDivisions,
  setNoDataBUs,
  setNoDataSites,
  getTrendViewFilters,
  getChartData,
  setDivisionSelected,
  setSelectedDivOptions,
} from "../../slicers/trend-view-slicer";
import { setSelectedView } from "../../slicers/home-slicer";
import { getLeadingLaggingData } from "../../slicers/corporate-assessment-slicer";
import ChangeSummaryTable from "./change-summary-table";
import RadioButtons from "../../components/raw-pack-radio-btns/radioButtons";
import RefreshFailureAlert from "../../components/alerts/refresh-failure";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./trendingview.scss";
const { Content } = Layout;

const TrendingView = () => {
  const {
    checkboxOn,
    noDataSelected,
    chartsData,
    chartParameter,
    trendDataFilters,
    trendViewFilters,
    site_segment,
    site_business_unit,
    site_unit_name,
    dropdownLoader,
    segmentSelected,
    buSelected,
    siteSelected,
    selectedSegOptions,
    selectedBUOptions,
    selectedSiteOptions,
    noDataSegments,
    noDataBUs,
    noDataSites,
    division,
    divisionSelected,
    selectedDivOptions,
    noDataDivisions,
  } = useSelector((state) => state.trendView);
  const { selectedView } = useSelector((state) => state.homeView);
  const { last_Refresh_date, alertRefreshDate, currentDateIST, actualDateIST } =
    useSelector((state) => state.corporateAssessment);
  const { is_out_of_scope_selected } = useSelector(
    (state) => state.sidebarFilters
  );
  const emptyFilters = {
    site_segment: [],
    site_business_unit: [],
    //site_sub_business_unit: [],
    site_unit_name: [],
    division: [],
  };
  const dispatch = useDispatch();
  const [homeRadioSelection, setHomeRadioSelection] = useState("segment");
  const [timeSelection, setTimeSelection] = useState("year_period");
  const [switchOn, setSwitchOn] = useState(false);
  const [switchState, setSwitchState] = useState("chart");
  //to show only filtered data (to exclude -1 placeholder data of no data selections from charts)
  const filteredData = chartsData.filter(
    (d) =>
      d.data_status_flag !== "no data" &&
      d.compliance_percent >= 0 &&
      d.mra_pra_status_approved_count_percent >= 0 &&
      d.spec_status_approved_count_percent >= 0 &&
      d.supplier_status_approved_count_percent >= 0
  );
  useEffect(() => {
    let tempView = selectedView;
    if (
      selectedView === "" ||
      selectedView === "home" ||
      selectedView === undefined
    ) {
      dispatch(setSelectedView("Raw"));
    } else {
      dispatch(setSelectedView(tempView));
    }
  }, []);
  //to maintain the list of no data segments, BUs, sites, divisions
  useEffect(() => {
    if (homeRadioSelection === "segment") {
      const noDataSegments = [];
      chartsData.map((d) => {
        if (d.data_status_flag === "no data" && d.latest_period === "y") {
          noDataSegments.push(d.self_name);
        }
      });
      const noDataSegArray = [...new Set(noDataSegments)];
      dispatch(setNoDataSegments(noDataSegArray));
    } else if (homeRadioSelection === "division") {
      //division_added
      const noDataDivisions = [];
      chartsData.map((d) => {
        if (d.data_status_flag === "no data" && d.latest_period === "y") {
          noDataDivisions.push(d.self_name);
        }
      });
      const noDataDivArray = [...new Set(noDataDivisions)];
      dispatch(setNoDataDivisions(noDataDivArray));
    } else if (homeRadioSelection === "business_unit") {
      const noDataBUs = [];
      chartsData.map((d) => {
        if (d.data_status_flag === "no data" && d.latest_period === "y") {
          noDataBUs.push(d.self_name);
        }
      });
      const noDataBUArray = [...new Set(noDataBUs)];
      dispatch(setNoDataBUs(noDataBUArray));
    } else if (homeRadioSelection === "site") {
      const noDataSites = [];
      chartsData.map((d) => {
        if (d.data_status_flag === "no data" && d.latest_period === "y") {
          noDataSites.push(d.site_code);
        }
      });
      const noDataSiteArray = [...new Set(noDataSites)];
      dispatch(setNoDataSites(noDataSiteArray));
    }
  }, [chartsData]);
  //to maintain default, on landing of the page
  useEffect(() => {
    dispatch(getTrendViewFilters("corpAssessment", trendViewFilters, false));
    if (selectedView === "Raw" || selectedView === "Packaging") {
      dispatch(
        getLeadingLaggingData({}, is_out_of_scope_selected, selectedView)
      );
    }
  }, [selectedView]);
  useEffect(() => {
    if (selectedView === "Raw" || selectedView === "Packaging") {
      if (checkboxOn === true) {
        dispatch(getChartData("mars", ["MARS"], timeSelection, selectedView));
      }
    }
  }, [timeSelection, selectedView]);
  //to populate the charts based on selections
  useEffect(() => {
    if (
      selectedSegOptions.length > 0 ||
      selectedDivOptions.length > 0 ||
      selectedBUOptions.length > 0 ||
      selectedSiteOptions.length > 0
    ) {
      dispatch(setCheckboxOn(false));
      if (homeRadioSelection === "segment") {
        dispatch(
          getChartData(
            homeRadioSelection,
            selectedSegOptions,
            timeSelection,
            selectedView
          )
        );
      } else if (homeRadioSelection === "division") {
        //division_added
        dispatch(
          getChartData(
            homeRadioSelection,
            selectedDivOptions,
            timeSelection,
            selectedView
          )
        );
      } else if (homeRadioSelection === "business_unit") {
        dispatch(
          getChartData(
            homeRadioSelection,
            selectedBUOptions,
            timeSelection,
            selectedView
          )
        );
      } else if (homeRadioSelection === "site") {
        dispatch(
          getChartData(
            homeRadioSelection,
            selectedSiteOptions,
            timeSelection,
            selectedView
          )
        );
      }
    }
  }, [
    homeRadioSelection,
    selectedSegOptions,
    selectedDivOptions,
    selectedBUOptions,
    selectedSiteOptions,
    timeSelection,
    selectedView,
  ]);
  //function to update selected options
  const onSelection = (key, value) => {
    let selectedFilters = {
      ...trendViewFilters,
      [key]: value,
    };
    if (key === "site_segment") {
      dispatch(setCheckboxOn(false));
      let values = Object.values(selectedFilters.site_segment);
      let valArr = values.filter(function (item) {
        return item.length !== 0;
      });
      dispatch(setSelectedSegOptions(valArr));
      dispatch(setSegmentSelected(true));
    } else if (key === "division") {
      //division_added
      dispatch(setCheckboxOn(false));
      let values = Object.values(selectedFilters.division);
      let valArr = values.filter(function (item) {
        return item.length !== 0;
      });
      dispatch(setSelectedDivOptions(valArr));
      dispatch(setDivisionSelected(true));
    } else if (key === "site_business_unit") {
      dispatch(setCheckboxOn(false));
      let values = Object.values(selectedFilters.site_business_unit);
      let valArr = values.filter(function (item) {
        return item.length !== 0;
      });
      dispatch(setSelectedBUOptions(valArr));
      dispatch(setBUSelected(true));
    } else if (key === "site_unit_name") {
      dispatch(setCheckboxOn(false));
      let values = Object.values(selectedFilters.site_unit_name);
      let valArr = values.filter(function (item) {
        return item.length !== 0;
      });
      dispatch(setSelectedSiteOptions(valArr));
      dispatch(setSiteSelected(true));
    }
    dispatch(setTrendFilters(selectedFilters));
  };
  //function to handle on clear property of select tag
  let handleOnClear = (key) => {
    dispatch(getChartData(homeRadioSelection, [], timeSelection, selectedView));
    if (key === "site_segment") {
      dispatch(setSelectedSegOptions([]));
      dispatch(setNoDataSelected(false));
    } else if (key === "division") {
      //division_added
      dispatch(setSelectedDivOptions([]));
      dispatch(setNoDataSelected(false));
    } else if (key === "site_business_unit") {
      dispatch(setSelectedBUOptions([]));
      dispatch(setNoDataSelected(false));
    } else if (key === "site_unit_name") {
      dispatch(setSelectedSiteOptions([]));
      dispatch(setNoDataSelected(false));
    }
  };
  //function for fetching cascaded filter options after selection is made
  const fetchFilterOptions = (keyName) => {
    dispatch(
      getTrendViewFilters("corpAssessment", trendViewFilters, false, keyName)
    );
  };
  //function to handle reset button
  const filtersReset = () => {
    dispatch(setTrendFilters(emptyFilters));
    dispatch(setSelectedSegOptions([]));
    dispatch(setSelectedDivOptions([]));
    dispatch(setSelectedBUOptions([]));
    dispatch(setSelectedSiteOptions([]));
    dispatch(getTrendViewFilters("corpAssessment", {}, false));
    dispatch(setCheckboxOn(true));
    dispatch(getChartData("mars", ["MARS"], timeSelection, selectedView));
    dispatch(setNoDataSelected(false));
    dispatch(setNoDataSegments([]));
    dispatch(setNoDataDivisions([]));
    dispatch(setNoDataBUs([]));
    dispatch(setNoDataSites([]));
    dispatch(setSegmentSelected(false));
    dispatch(setDivisionSelected(false));
    dispatch(setBUSelected(false));
    dispatch(setSiteSelected(false));
  };
  //function to handle All Mars Info checkbox
  const overallInfoChecked = () => (e) => {
    if (e.target.checked === true) {
      dispatch(setCheckboxOn(true));
      dispatch(getChartData("mars", ["MARS"], timeSelection, selectedView));
      dispatch(setNoDataSelected(false));
      dispatch(setSelectedSegOptions([]));
      dispatch(setSelectedBUOptions([]));
      dispatch(setSelectedSiteOptions([]));
      dispatch(setTrendFilters(emptyFilters));
      dispatch(setSegmentSelected(false));
      dispatch(setBUSelected(false));
      dispatch(setSiteSelected(false));
    } else if (e.target.checked === false) {
      dispatch(setCheckboxOn(false));
      if (homeRadioSelection === "segment") {
        dispatch(
          getChartData(
            homeRadioSelection,
            selectedSegOptions,
            timeSelection,
            selectedView
          )
        );
      } else if (homeRadioSelection === "division") {
        dispatch(
          getChartData(
            homeRadioSelection,
            selectedDivOptions,
            timeSelection,
            selectedView
          )
        );
      } else if (homeRadioSelection === "business_unit") {
        dispatch(
          getChartData(
            homeRadioSelection,
            selectedBUOptions,
            timeSelection,
            selectedView
          )
        );
      } else if (homeRadioSelection === "site") {
        dispatch(
          getChartData(
            homeRadioSelection,
            selectedSiteOptions,
            timeSelection,
            selectedView
          )
        );
      }
    }
  };
  //function to handle radio button group - Segment, Division, BU, Site
  const onHomeRadioChange = (e) => {
    setHomeRadioSelection(e.target.value);
  };
  //function to handle radio button group - Year, Quarter, Period
  const onTimeSelectionChange = (e) => {
    setTimeSelection(e.target.value);
  };
  //function for handling toggle
  const onSwitchChange = (e) => {
    setSwitchOn(!switchOn);
  };
  useEffect(() => {
    switchOn === false ? setSwitchState("chart") : setSwitchState("table");
  }, [switchOn]);
  //for auto-population of charts
  useEffect(() => {
    if (selectedSegOptions.length > 0 && segmentSelected) {
      dispatch(setSelectedSegOptions(selectedSegOptions));
      {
        buSelected === false
          ? dispatch(setSelectedBUOptions(trendDataFilters.site_business_unit))
          : dispatch(setSelectedBUOptions(selectedBUOptions));
      }
      {
        siteSelected === false
          ? dispatch(setSelectedSiteOptions(trendDataFilters.site_unit_name))
          : dispatch(setSelectedSiteOptions(selectedSiteOptions));
      }
      {
        divisionSelected === false
          ? dispatch(setSelectedDivOptions(trendDataFilters.division))
          : dispatch(setSelectedDivOptions(selectedDivOptions));
      }
    } else if (selectedDivOptions.length > 0 && divisionSelected) {
      //division_added
      dispatch(setSelectedDivOptions(selectedDivOptions));
      {
        segmentSelected === false
          ? dispatch(setSelectedSegOptions(trendDataFilters.site_segment))
          : dispatch(setSelectedSegOptions(selectedSegOptions));
      }
      {
        buSelected === false
          ? dispatch(setSelectedBUOptions(trendDataFilters.site_business_unit))
          : dispatch(setSelectedBUOptions(selectedBUOptions));
      }
      {
        siteSelected === false
          ? dispatch(setSelectedSiteOptions(trendDataFilters.site_unit_name))
          : dispatch(setSelectedSiteOptions(selectedSiteOptions));
      }
    } else if (selectedBUOptions.length > 0 && buSelected) {
      dispatch(setSelectedBUOptions(selectedBUOptions));
      {
        segmentSelected === false
          ? dispatch(setSelectedSegOptions(trendDataFilters.site_segment))
          : dispatch(setSelectedSegOptions(selectedSegOptions));
      }
      {
        siteSelected === false
          ? dispatch(setSelectedSiteOptions(trendDataFilters.site_unit_name))
          : dispatch(setSelectedSiteOptions(selectedSiteOptions));
      }
      {
        divisionSelected === false
          ? dispatch(setSelectedDivOptions(trendDataFilters.division))
          : dispatch(setSelectedDivOptions(selectedDivOptions));
      }
    } else if (selectedSiteOptions.length > 0 && siteSelected) {
      dispatch(setSelectedSiteOptions(selectedSiteOptions));
      {
        segmentSelected === false
          ? dispatch(setSelectedSegOptions(trendDataFilters.site_segment))
          : dispatch(setSelectedSegOptions(selectedSegOptions));
      }
      {
        buSelected === false
          ? dispatch(setSelectedBUOptions(trendDataFilters.site_business_unit))
          : dispatch(setSelectedBUOptions(selectedBUOptions));
      }
      {
        divisionSelected === false
          ? dispatch(setSelectedDivOptions(trendDataFilters.division))
          : dispatch(setSelectedDivOptions(selectedDivOptions));
      }
    }
  }, [
    trendDataFilters,
    segmentSelected,
    buSelected,
    siteSelected,
    divisionSelected,
    selectedView,
  ]);
  //to check and update the no data selections from selected options, for the no data note
  useEffect(() => {
    if (homeRadioSelection === "segment") {
      let segArray = selectedSegOptions.filter((val) =>
        noDataSegments.includes(val)
      );
      if (segArray.length > 0 || noDataSegments.length > 0) {
        dispatch(setNoDataSelected(true));
      }
    } else if (homeRadioSelection === "business_unit") {
      let buArray = selectedBUOptions.filter((val) => noDataBUs.includes(val));
      if (buArray.length > 0 || noDataBUs.length > 0) {
        dispatch(setNoDataSelected(true));
      }
    } else if (homeRadioSelection === "site") {
      if (noDataSites.length > 0) {
        dispatch(setNoDataSelected(true));
      }
    } else if (homeRadioSelection === "division") {
      //division_addition
      let divArray = selectedDivOptions.filter((val) =>
        noDataDivisions.includes(val)
      );
      if (divArray.length > 0 || noDataDivisions.length > 0) {
        dispatch(setNoDataSelected(true));
      }
    }
  }, [
    chartsData,
    noDataSegments,
    noDataBUs,
    noDataSites,
    selectedSegOptions,
    selectedBUOptions,
    selectedSiteOptions,
    selectedView,
    noDataDivisions,
    selectedDivOptions,
  ]);
  //function to display line charts accordingly
  const componentToRender = () => {
    if (chartParameter !== "") {
      return (
        <Content className="trendingview-wrapper">
          <LineChart
            dataTrend={filteredData}
            time={timeSelection}
            focusMode={chartParameter}
            view={selectedView}
          />
        </Content>
      );
    } else {
      return (
        <Loader loading={dropdownLoader === true}>
          <Content className="trendingview-wrapper">
            <Row className="title_row">
              <Col span={24}>
                <p className="title-text">
                  {titles.trending_view_content.sourcing_title}
                </p>
              </Col>
            </Row>
            <Row className="radio" wrap={false}>
              <Col span={12} align="left" className="radio-buttons-wrapper">
                <RadioButtons />
              </Col>
              <Col span={6} className="switch-col" align="left">
                <div className="charts-table-switch">
                  <span className="text">Trend View</span>
                  <Switch
                    className="switchtotable"
                    checked={switchOn}
                    onChange={onSwitchChange}
                  />
                  <span className="text">Change Summary</span>
                </div>
              </Col>
              <Col span={6} className="last_updated_text" align="right">
                <span>
                  {last_Refresh_date ? (
                    <div>
                      Daily Refresh
                      <br /> Last Refreshed Date is:{" "}
                      <span
                        className={alertRefreshDate ? "alert-refresh-date" : ""}
                      >
                        {last_Refresh_date}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </span>
                {currentDateIST !== last_Refresh_date &&
                actualDateIST !== last_Refresh_date ? (
                  <RefreshFailureAlert />
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            {switchState === "chart" ? (
              <>
                <Row className="filters-row" wrap={false}>
                  <Col
                    xs={8}
                    sm={16}
                    md={24}
                    lg={32}
                    className="dimension-filters"
                  >
                    <Col className="aggregate-info">
                      <Checkbox
                        onChange={overallInfoChecked()}
                        checked={checkboxOn}
                      >
                        <span className="label">All Mars Info</span>
                      </Checkbox>
                    </Col>
                    <Col className="ou">
                      <Radio.Group
                        className="level-radio-selection"
                        defaultValue="Segment"
                        value={homeRadioSelection}
                        onChange={onHomeRadioChange}
                      >
                        <Radio value="segment">Segment</Radio>
                        <Radio value="division">Division</Radio>
                        <Radio value="business_unit">BU</Radio>
                        <Radio value="site">Site</Radio>
                      </Radio.Group>
                    </Col>
                    <Col className="time">
                      <Radio.Group
                        className="time-radio-selection"
                        defaultValue="Period"
                        value={timeSelection}
                        onChange={onTimeSelectionChange}
                      >
                        <Radio value="year">Year</Radio>
                        <Radio value="year_quarter">Quarter</Radio>
                        <Radio value="year_period">Period</Radio>
                      </Radio.Group>
                    </Col>
                    <Col className="filter">
                      <span className="selected-level-label">
                        {homeRadioSelection === "segment"
                          ? "Segment"
                          : homeRadioSelection === "division"
                          ? "Division"
                          : homeRadioSelection === "business_unit"
                          ? "BU"
                          : "Site"}
                      </span>
                      {homeRadioSelection === "segment" ? (
                        <Select
                          className="selected-level-filter"
                          size="small"
                          value={
                            selectedSegOptions.length > 0 && segmentSelected
                              ? trendViewFilters.site_segment
                              : selectedSegOptions
                          }
                          onChange={(val) => onSelection("site_segment", val)}
                          onBlur={() => fetchFilterOptions("site_segment")}
                          onClear={(val) => handleOnClear("site_segment")}
                          placeholder="Select Segment"
                          mode="multiple"
                          maxTagCount="responsive"
                          allowClear
                          loading={dropdownLoader}
                          maxTagPlaceholder={(rec) => {
                            if (Object.entries(rec).length) {
                              return rec.length + " selected";
                            }
                          }}
                        >
                          {site_segment?.map((val) => (
                            <Select.Option key={val} value={val}>
                              {val}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : homeRadioSelection === "division" ? (
                        <Select
                          className="selected-level-filter"
                          size="small"
                          value={
                            selectedDivOptions.length > 0 && divisionSelected
                              ? trendViewFilters.division
                              : selectedDivOptions
                          }
                          onChange={(val) => onSelection("division", val)}
                          onBlur={() => fetchFilterOptions("division")}
                          onClear={(val) => handleOnClear("division")}
                          placeholder="Select Division"
                          mode="multiple"
                          maxTagCount="responsive"
                          allowClear
                          loading={dropdownLoader}
                          maxTagPlaceholder={(rec) => {
                            if (Object.entries(rec).length) {
                              return rec.length + " selected";
                            }
                          }}
                        >
                          {division?.map((val) => (
                            <Select.Option key={val} value={val}>
                              {val}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : homeRadioSelection === "business_unit" ? (
                        <Select
                          className="selected-level-filter"
                          size="small"
                          value={
                            selectedBUOptions.length > 0 && buSelected
                              ? trendViewFilters.site_business_unit
                              : selectedBUOptions
                          }
                          onChange={(val) =>
                            onSelection("site_business_unit", val)
                          }
                          onBlur={() =>
                            fetchFilterOptions("site_business_unit")
                          }
                          onClear={(val) => handleOnClear("site_business_unit")}
                          placeholder="Select BU"
                          mode="multiple"
                          maxTagCount="responsive"
                          allowClear
                          loading={dropdownLoader}
                          maxTagPlaceholder={(rec) => {
                            if (Object.entries(rec).length) {
                              return rec.length + " selected";
                            }
                          }}
                        >
                          {site_business_unit?.map((val) => (
                            <Select.Option key={val} value={val}>
                              {val}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : (
                        <Select
                          className="selected-level-filter"
                          size="small"
                          value={
                            selectedSiteOptions.length > 0 && siteSelected
                              ? trendViewFilters.site_unit_name
                              : selectedSiteOptions
                          }
                          onChange={(val) => onSelection("site_unit_name", val)}
                          onBlur={() => fetchFilterOptions("site_unit_name")}
                          onClear={(val) => handleOnClear("site_unit_name")}
                          placeholder="Select Site Name"
                          mode="multiple"
                          maxTagCount="responsive"
                          allowClear
                          loading={dropdownLoader}
                          maxTagPlaceholder={(rec) => {
                            if (Object.entries(rec).length) {
                              return rec.length + " selected";
                            }
                          }}
                        >
                          {site_unit_name?.map((val) => (
                            <Select.Option key={val} value={val}>
                              {val}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Col>
                    <Col className="apply-reset-buttons">
                      <Button
                        className="clear-all-filters"
                        type="custom"
                        shape="round"
                        onClick={() => filtersReset()}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Col>
                </Row>
                <Row gutter={4} wrap={false}>
                  <Col
                    xs={8}
                    sm={16}
                    md={24}
                    lg={32}
                    align="left"
                    className="charts-section"
                  >
                    <Col className="charts-heading">
                      Process Adherence Indicator Trends
                      {noDataSelected === true &&
                      noDataSegments.length > 0 &&
                      homeRadioSelection === "segment" ? (
                        <span className="no-data-note">
                          You have selected the following with no data:{" "}
                          {noDataSegments.map((val) => {
                            return val + ",";
                          })}
                        </span>
                      ) : noDataSelected === true &&
                        noDataDivisions.length > 0 &&
                        homeRadioSelection === "division" ? (
                        <span className="no-data-note">
                          You have selected the following with no data:{" "}
                          {noDataDivisions.map((val) => {
                            return val + ",";
                          })}
                        </span>
                      ) : noDataSelected === true &&
                        noDataBUs.length > 0 &&
                        homeRadioSelection === "business_unit" ? (
                        <span className="no-data-note">
                          You have selected the following with no data:{" "}
                          {noDataBUs.map((val) => {
                            return val + ",";
                          })}
                        </span>
                      ) : noDataSelected === true &&
                        noDataSites.length > 0 &&
                        homeRadioSelection === "site" ? (
                        <span className="no-data-note">
                          You have selected the following with no data:{" "}
                          {noDataSites.map((val) => {
                            return val + ",";
                          })}
                        </span>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={8} sm={16} md={16} lg={24} className="line-charts">
                      <LineChart
                        dataTrend={filteredData}
                        time={timeSelection}
                        focusMode={chartParameter}
                        view={selectedView}
                      />
                    </Col>
                  </Col>
                </Row>
              </>
            ) : (
              <Col xs={8} sm={16} md={24} lg={32}>
                <ChangeSummaryTable />
              </Col>
            )}
            <ToastContainer />
          </Content>
        </Loader>
      );
    }
  };
  return <Wrapper heading={"5"}>{componentToRender()}</Wrapper>;
};

export default withRouter(TrendingView);
