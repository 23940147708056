import { createSlice } from "@reduxjs/toolkit";
import CustomAxios, { CustomAxiosDE } from "../utilities/service/api-service";
import CheckAuthorization from "../utilities/auth/check-authorization";
import {
  world_map_url,
  leading_lagging_units,
  Base_URL,
  get_input_tool_data,
} from "../utilities/constants";
import { showErrorMessage } from "../utilities/helper";

const initialState = {
  loading: 0,
  dropdownLoading: 0,
  loadingExportData: 0,
  worldMap_points: [],
  leading_lagging_data: [],
  last_Refresh_date: "",
  currentDateIST: "",
  actualDateIST: "",
  alertRefreshDate: false,
  leading_lagging_loader: false,
  siteMaterials_loading: 0,
  materials_of_site_data: [],
  isSiteSelected: false,
  selected_SiteCode: "",
  is_out_of_scope_selected: false,
  show_out_of_scope_warning: false,
  thresholds_data: [],
};

const corporateAssessmentSlicer = createSlice({
  name: "corporateAssessment",
  initialState,
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      loading: state.loading + (action.payload ? 1 : -1),
    }),
    setDropdownLoading: (state, action) => ({
      ...state,
      dropdownLoading: state.dropdownLoading + (action.payload ? 1 : -1),
    }),
    setExportDataLoading: (state, action) => ({
      ...state,
      loadingExportData: state.loadingExportData + (action.payload ? 1 : -1),
    }),

    setWorldMapPoints: (state, action) => ({
      ...state,
      worldMap_points: action.payload,
    }),
    setLeadingLaggingData: (state, action) => ({
      ...state,
      leading_lagging_data: action.payload,
    }),
    setLastRefreshDate: (state, action) => ({
      ...state,
      last_Refresh_date: action.payload,
    }),
    setCurrentDateIST: (state, action) => ({
      ...state,
      currentDateIST: action.payload,
    }),
    setActualDateIST: (state, action) => ({
      ...state,
      actualDateIST: action.payload,
    }),
    setAlertRefreshDate: (state, action) => ({
      ...state,
      alertRefreshDate: action.payload,
    }),
    setLeadingLaggingLoader: (state, action) => ({
      ...state,
      leading_lagging_loader: action.payload,
    }),
    setLoadingofOneSiteMaterialAsssessment: (state, action) => ({
      ...state,
      siteMaterials_loading:
        state.siteMaterials_loading + (action.payload ? 1 : -1),
    }),
    setOneSiteMaterialAssessmentData: (state, action) => ({
      ...state,
      materials_of_site_data: action.payload,
    }),
    setisSiteSelected: (state, action) => ({
      ...state,
      isSiteSelected: action.payload,
    }),
    setSelectedSiteCode: (state, action) => ({
      ...state,
      selected_SiteCode: action.payload,
    }),
    setThresholdData: (state, action) => ({
      ...state,
      thresholds_data: action.payload,
    }),
  },
});

export const {
  setLoading,
  setDropdownLoading,
  setExportDataLoading,
  setWorldMapPoints,
  setLeadingLaggingData,
  setLastRefreshDate,
  setCurrentDateIST,
  setActualDateIST,
  setAlertRefreshDate,
  setLeadingLaggingLoader,
  setLoadingofOneSiteMaterialAsssessment,
  setOneSiteMaterialAssessmentData,
  setisSiteSelected,
  setSelectedSiteCode,
  is_out_of_scope_selected,
  setThresholdData,
} = corporateAssessmentSlicer.actions;

export const getWorldMappoints =
  (filters, oos_flag, type) => async (dispatch, getState) => {
    let urlFilters = { ...filters };
    Object.keys(urlFilters).forEach((key) => {
      if (urlFilters[key].length === 0) {
        delete urlFilters[key];
      }
    });
    dispatch(setDropdownLoading(true));
    CustomAxios(
      Base_URL + world_map_url + "?only_oos=" + oos_flag + "&type=" + type,
      "POST",
      urlFilters
    )
      .then((response) => {
        if (response.data.error_code === undefined) {
          let worldMap_points = response.data;
          dispatch(setWorldMapPoints(worldMap_points));
        }
        dispatch(setDropdownLoading(false));
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error("World Map API Failed", err);
        dispatch(setDropdownLoading(false));
      });
  };

export const getLeadingLaggingData =
  (filters, oos_flag, type) => async (dispatch, getState) => {
    dispatch(setLeadingLaggingLoader(true));
    let urlFilters = { ...filters };
    Object.keys(urlFilters).forEach((key) => {
      if (urlFilters[key].length === 0) {
        delete urlFilters[key];
      }
    });

    CustomAxios(
      Base_URL +
        leading_lagging_units +
        "?only_oos=" +
        oos_flag +
        "&n=200" +
        "&type=" +
        type,
      "POST",
      urlFilters
    )
      .then((response) => {
        if (response.data.error_code === undefined) {
          let response_data = response.data;

          dispatch(setLeadingLaggingData(response_data));
          dispatch(setLastRefreshDate(response_data.last_refresh_date));
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error("Left Section Filters Dropdown API Failed", err);
        showErrorMessage();
      })
      .finally(() => {
        dispatch(setLeadingLaggingLoader(false));
      });
  };

export const getThresholdsData = () => async (dispatch, getState) => {
  dispatch(setDropdownLoading(true));

  CustomAxios(Base_URL + get_input_tool_data, "GET")
    .then((response) => {
      if (response.data.error_code === undefined) {
        let input_tools_data = response.data;
        let temp_data = [];
        input_tools_data.map((ele, i) => {
          if (ele.id !== 1) {
            temp_data.push(ele);
          }
        });
        dispatch(setThresholdData(temp_data));
      }
    })
    .catch((err) => {
      if (err.status === 403) {
        CheckAuthorization();
      }
      console.error("Map View Threshold API Failed", err);
      showErrorMessage();
    })
    .finally(() => {
      dispatch(setDropdownLoading(false));
    });
};

export default corporateAssessmentSlicer.reducer;
