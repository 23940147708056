import { createSlice } from "@reduxjs/toolkit";
import CustomAxios, { CustomAxiosDE } from "../utilities/service/api-service";
import CheckAuthorization from "../utilities/auth/check-authorization";
import {
  Base_URL,
  material_assessment_data_url,
  material_data_download_excel,
} from "../utilities/constants";
const webAppURL = process.env.REACT_APP_WEB_APP_URL;
const devEnv = webAppURL.substr(32, 34);
const qaEnv = webAppURL.substr(32, 33);
const initialState = {
  loading: 0,
  dropdownLoading: 0,
  loadingExportData: 0,
  data_filters: {},
  material_filters: {
    supplier_id: [],
    material_id: [],
    overall_compliance: [],
  },
  appliedFilters: {
    site_segment: ["ALL"],
    site_business_unit: ["ALL"],
    // site_sub_business_unit: ["ALL"],
    site_unit_type: ["ALL"],
    site_unit_name: ["ALL"],
    supplier_id: ["ALL"],
    material_id: ["ALL"],
    overall_compliance: ["ALL"],
  },
  supplier_id: [],
  material_id: [],
  overall_compliance: [],
  materials_api_data: [],
  material_pagination: {
    current: 1,
    pageSize: 10,
  },
  material_sorting: [],
  searchParams: {},
  searchFlag: false,
  resetSearchFlag: false,
};

const materialAssessmentSlicer = createSlice({
  name: "materialAssessment",
  initialState,
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      loading: state.loading + (action.payload ? 1 : -1),
    }),
    setDropdownLoading: (state, action) => ({
      ...state,
      dropdownLoading: state.dropdownLoading + (action.payload ? 1 : -1),
    }),
    setExportDataLoading: (state, action) => ({
      ...state,
      loadingExportData: state.loadingExportData + (action.payload ? 1 : -1),
    }),
    setDataFilters: (state, action) => ({
      ...state,
      data_filters: action.payload,
    }),
    setDataDropdown: (state, action) => ({
      ...state,
      [action.payload[0]]: action.payload[1],
    }),
    setMaterialTableData: (state, action) => ({
      ...state,
      materials_api_data: action.payload,
    }),
    setMaterialPagination: (state, action) => ({
      ...state,
      material_pagination: action.payload,
    }),
    setMaterialSorting: (state, action) => ({
      ...state,
      material_sorting: action.payload,
    }),
    setMatSearchParams: (state, action) => {
      const { searchDataIndex, searchTerm } = action.payload;

      if (Object.keys(action.payload).length === 0) {
        state.searchParams = {};
      } else {
        state.searchParams = {
          ...state.searchParams,
          [searchDataIndex]: searchTerm,
        };
      }
    },
    setSearchFlag: (state, action) => ({
      ...state,
      searchFlag: action.payload,
    }),
    setResetSearchFlag: (state, action) => ({
      ...state,
      resetSearchFlag: action.payload,
    }),
  },
});

export const {
  setLoading,
  setDropdownLoading,
  setExportDataLoading,
  setDataFilters,
  setDataDropdown,
  setMaterialTableData,
  setMaterialPagination,
  setMaterialSorting,
  setMatSearchParams,
  setSearchFlag,
  setResetSearchFlag,
} = materialAssessmentSlicer.actions;

export const getMaterialAssessmentData =
  (material_filters, pagination, sorting, oos_flag, searchTermParams, type) =>
  async (dispatch, getState) => {
    let page =
      pagination && Object.entries(pagination).length ? pagination.current : 1;
    let limit =
      pagination && Object.entries(pagination).length
        ? pagination.pageSize
        : 10;
    dispatch(setLoading(1));
    let urlFilters = { ...material_filters };
    Object.keys(urlFilters).forEach((key) => {
      if (urlFilters[key].length === 0) {
        delete urlFilters[key];
      }
    });
    urlFilters["ordering"] = sorting;
    let offset = limit * (page - 1);
    let url = "";
    function serialize(obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p) && obj[p] != undefined) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    }
    let materialSearch = serialize(searchTermParams);
    url =
      Base_URL +
      material_assessment_data_url +
      "?page=" +
      page +
      "&offset=" +
      offset +
      "&only_oos=" +
      oos_flag +
      "&limit=" +
      limit +
      "&type=" +
      type +
      `&${materialSearch}`;
    CustomAxios(url, "POST", urlFilters)
      .then((response) => {
        if (response.data.error_code === undefined) {
          let response_data = response.data;
          dispatch(setMaterialTableData(response_data));
          dispatch(
            setMaterialPagination({
              current: page,
              pageSize: limit,
              total: response_data.count,
            })
          );
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error("Material Data API Failed", err);
      })
      .finally(() => {
        dispatch(setLoading(0));
        dispatch(setSearchFlag(false));
        dispatch(setResetSearchFlag(false));
      });
  };

export const getMaterialDataDownloadExcel =
  (material_filters, type) => async (dispatch, getState) => {
    dispatch(setExportDataLoading(true));
    let newFilters = { ...material_filters };
    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key].length === 0) {
        delete newFilters[key];
      }
    });
    dispatch(setExportDataLoading(true));
    CustomAxiosDE(
      Base_URL + material_data_download_excel + "?type=" + type,
      "POST",
      newFilters,
      "blob"
    )
      .then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          type === "Raw"
            ? link.setAttribute(
                "download",
                `[qa]${type}-Material-Assessment-Export.xlsx`
              )
            : link.setAttribute(
                "download",
                `[qa]${type}-Assessment-Export.xlsx`
              );
          document.body.appendChild(link);
          link.click();
        }
        dispatch(setExportDataLoading(false));
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error("error, Error initiating download", err);
        dispatch(setExportDataLoading(false));
      });
  };

export default materialAssessmentSlicer.reducer;
