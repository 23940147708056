import React, { useState } from "react";
import Wrapper from "../../components/wrapper";
import "./admin-layout.scss";
import { Layout } from "antd";
import AdminSider from "./admin-sider";
import AdminContent from "./admin-content";
const { Header, Content, Footer, Sider } = Layout;

const AdminLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeModule, setActiveModule] = useState("Manage Access");

  const updateModuleSelection = (val) => {
    setActiveModule(val);
  };
  return (
    <Wrapper heading={"4"}>
      <Layout className="admin-layout">
        <AdminSider
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          changeModule={updateModuleSelection}
        />
        <AdminContent
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          activeModule={activeModule}
        />
      </Layout>
    </Wrapper>
  );
};
export default AdminLayout;
