import { createSlice } from "@reduxjs/toolkit";
import { Base_URL, getAdminRequestData } from "../utilities/constants";
import CustomAxios from "../utilities/service/api-service";
import axios from "axios";
import CheckAuthorization from "../utilities/auth/check-authorization";
import {
  showErrorMessage,
  showSuccessMessage,
  showErrorWithCustomMessage,
} from "../utilities/helper";

const initialState = {
  appRole: "Approvers", //approvers for Approver, Super.Admin for super admin
  manageAccessTableLoader: false,
  manageAccessTableData: [],
  approvalFlag: false,
};

const manageAccessSlicer = createSlice({
  name: "manageAccess",
  initialState,
  reducers: {
    setAppRole: (state, action) => ({
      ...state,
      appRole: action.payload,
    }),
    setManageAccessTableLoader: (state, action) => ({
      ...state,
      manageAccessTableLoader: action.payload,
    }),
    setManageAccessTableData: (state, action) => ({
      ...state,
      manageAccessTableData: action.payload,
    }),
    setApprovalFlag: (state, action) => ({
      ...state,
      approvalFlag: action.payload,
    }),
  },
});

export const {
  setAppRole,
  setManageAccessTableLoader,
  setManageAccessTableData,
  setApprovalFlag,
} = manageAccessSlicer.actions;

export const getManageAccessTableData = () => async (dispatch, getState) => {
  dispatch(setManageAccessTableLoader(true));
  CustomAxios(Base_URL + getAdminRequestData, "GET")
    .then((response) => {
      dispatch(setManageAccessTableData(response.data));
      dispatch(setManageAccessTableLoader(false));
    })
    .catch((err) => {
      if (err.status === 403) {
        CheckAuthorization();
      }
      console.error("Manage Access API Failed", err);
      showErrorMessage();
      dispatch(setManageAccessTableLoader(false));
    });
};

export const actionForAdmin = (params) => async (dispatch, getState) => {
  dispatch(setManageAccessTableLoader(true));
  dispatch(setApprovalFlag(true));
  const payload = {
    email_for_assistance: process.env.REACT_APP_ASSISTANCE_EMAIL_ID,
    group_id: process.env.REACT_APP_TOOL_USER_GROUP_ID,
    subject_for_user: process.env.REACT_APP_SUBJECT_FOR_USER,
    tool_link: process.env.REACT_APP_WEB_APP_URL,
    user_email: params.email,
    user_id: params.oid,
    user_name: params.name,
    selected_option: params.approvalOption,
  };
  axios({
    url: process.env.REACT_APP_UI_APPROVAL_ENDPOINT,
    method: "POST",
    data: payload,
  })
    .then((response) => {
      params.approvalOption === "Approve"
        ? showSuccessMessage("User request approved!")
        : showSuccessMessage("User request rejected!");
    })
    .catch((err) => {
      if (err.status === 403) {
        CheckAuthorization();
      }
      showErrorWithCustomMessage("Action failed!");
    })
    .finally(() => {
      dispatch(setManageAccessTableLoader(false));
      dispatch(setApprovalFlag(false));
    });
};

export default manageAccessSlicer.reducer;
