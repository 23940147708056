import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Select, Button, Switch, Popover } from "antd";
import {
  getWorldMappoints,
  getLeadingLaggingData,
} from "../../slicers/corporate-assessment-slicer";
import { getMaterialAssessmentData } from "../../slicers/material-assessment-slicer";
import {
  getSiteLocationBarchartData,
  getSiteTableData,
  getOverallTableData,
} from "../../slicers/site-assessment-slicer";

import {
  setFilters,
  getLeftSectionFilters,
  getDropDownOptions,
  getSelectedSiteOOSStatus,
  setAppliedFilters,
  setApplyFilter,
  setOutOfScopeValue,
  setOOSSitesList,
  setOOSAppliedFilters,
  setOOSFilters,
  setLastSelectedFilter,
  setOOSLastSelectedFilter,
} from "../../slicers/sidebar-filters-slicer";
import titles from "../../utilities/generic-titles";
import "./filters.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FiltersSection = ({ module, pagination }) => {
  const {
    filters,
    site_segment,
    division,
    site_business_unit,
    // site_sub_business_unit,
    site_unit_type,
    site_unit_name,
    applyFilter,
    is_out_of_scope_selected,
    supplier_name,
    material_description,
    overall_compliance,
    oos_filters,
    site_segment_oos,
    division_oos,
    site_business_unit_oos,
    // site_sub_business_unit_oos,
    site_unit_type_oos,
    site_unit_name_oos,
    supplier_name_oos,
    material_description_oos,
    overall_compliance_oos,
    dropdownLoading,
    last_selected_filter_name,
    last_selected_filter_val,
    oos_last_selected_filter_name,
    oos_last_selected_filter_val,
  } = useSelector((state) => state.sidebarFilters);
  const { selectedView } = useSelector((state) => state.homeView);
  const { searchParams, material_sorting } = useSelector(
    (state) => state.materialAssessment
  );
  const [moduleName, setmoduleName] = useState("");
  const [componentFilters, setComponentFilters] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);

  const dispatch = useDispatch();
  const empty_filters = {
    site_segment: [],
    division: [],
    site_business_unit: [],
    // site_sub_business_unit: [],
    site_unit_type: [],
    site_unit_name: [],
    supplier_name: [],
    material_description: [],
    overall_compliance: [],
    compliance_indicator: [],
    compliance_value: [],
  };
  useEffect(() => {
    let temp = "";
    let currentFilters;
    //change payload
    if (is_out_of_scope_selected) {
      currentFilters =
        Object.entries(oos_filters).length > 0
          ? {
              [oos_last_selected_filter_name]: oos_last_selected_filter_val,
            }
          : oos_filters;
    } else {
      currentFilters =
        Object.entries(filters).length > 0
          ? {
              [last_selected_filter_name]: last_selected_filter_val,
            }
          : filters;
    }
    if (
      module == "corporate_assessment" ||
      module == "site_assessment_location" ||
      module == "site_assessment_element"
    ) {
      setmoduleName("corpAssessment");
      temp = "corpAssessment";
      setComponentFilters(filters);
      //change payload
      dispatch(
        getLeftSectionFilters(temp, currentFilters, is_out_of_scope_selected)
      );
    } else if (module == "material_assessment") {
      setmoduleName("materialAssessment");
      temp = "materialAssessment";
      //remove data_status_flag from MA filters as its not present in it's db
      let urlFilters = { ...filters };
      Object.keys(urlFilters).forEach((key) => {
        if (key === "data_status_flag") {
          delete urlFilters[key];
        }
      });
      setComponentFilters(urlFilters);
      //change payload
      dispatch(
        getLeftSectionFilters(temp, currentFilters, is_out_of_scope_selected)
      );
    }
  }, [module]);
  useEffect(() => {
    console.log(
      last_selected_filter_name,
      "------------------------in scope filter change--------------------------",
      last_selected_filter_val
    );
    console.log(
      oos_last_selected_filter_name,
      "------------------------out of scope filter change--------------------------",
      oos_last_selected_filter_val
    );
  }, [
    last_selected_filter_name,
    last_selected_filter_val,
    oos_last_selected_filter_name,
    oos_last_selected_filter_val,
  ]);

  useEffect(() => {
    if (is_out_of_scope_selected) {
      setComponentFilters(oos_filters);
    } else {
      setComponentFilters(filters);
    }
  }, [filters, oos_filters, is_out_of_scope_selected]);

  const onSelection = (key, value) => {
    setDisableBtn(true);
    if (is_out_of_scope_selected) {
      //setting last selected filter
      dispatch(setOOSLastSelectedFilter({ name: key, val: value }));
      let newFilters = {
        ...oos_filters,
        [key]: value,
      };
      dispatch(setOOSFilters(newFilters));
    } else {
      //setting last selected filter
      dispatch(setLastSelectedFilter({ name: key, val: value }));
      let newFilters = {
        ...filters,
        [key]: value,
      };
      dispatch(setFilters(newFilters));
    }
    dispatch(setApplyFilter(false));
  };
  let handleOnClear = (key, value) => {
    if (is_out_of_scope_selected) {
      let newFilters = {
        ...oos_filters,
        [key]: value,
      };
      dispatch(setOOSFilters(newFilters));
      if (oos_filters.site_unit_name.length > 0)
        dispatch(
          getSelectedSiteOOSStatus({
            site_unit_name: newFilters.site_unit_name,
          })
        );
    } else {
      let newFilters = {
        ...filters,
        [key]: value,
      };
      dispatch(setFilters(newFilters));
    }
    dispatch(setApplyFilter(false));
  };

  let filters_reset = () => {
    //change payload
    dispatch(setLastSelectedFilter({ name: "", val: [] }));
    dispatch(getLeftSectionFilters(moduleName, empty_filters, false));
    dispatch(setOOSFilters(empty_filters));
    dispatch(setOOSAppliedFilters(empty_filters));
    dispatch(setFilters(empty_filters));
    dispatch(setAppliedFilters(empty_filters));
    dispatch(setOutOfScopeValue(false));
    dispatch(setOOSSitesList([]));
    dispatch(
      getMaterialAssessmentData(
        { ...empty_filters },
        pagination,
        material_sorting,
        false,
        searchParams,
        selectedView
      )
    );
    dispatch(getSiteLocationBarchartData(empty_filters, false, selectedView));
    dispatch(setApplyFilter(true));
    dispatch(getOverallTableData(empty_filters, false, selectedView));
    dispatch(getSiteTableData(empty_filters, false, selectedView));
    dispatch(getWorldMappoints(empty_filters, false, selectedView));
    dispatch(getLeadingLaggingData(empty_filters, false, selectedView));
  };

  const handleApplyFilters = () => {
    let currentFilters;
    if (is_out_of_scope_selected) {
      currentFilters =
        Object.entries(oos_filters).length > 0
          ? {
              [oos_last_selected_filter_name]: oos_last_selected_filter_val,
            }
          : oos_filters;
      dispatch(setOOSFilters(oos_filters));
      dispatch(setOOSAppliedFilters({ ...empty_filters, ...oos_filters }));
    } else {
      currentFilters =
        Object.entries(filters).length > 0
          ? {
              [last_selected_filter_name]: last_selected_filter_val,
            }
          : filters;

      dispatch(setFilters(filters));
      dispatch(setAppliedFilters({ ...empty_filters, ...filters }));
    }
    dispatch(setApplyFilter(true));
    if (module == "material_assessment") {
      dispatch(
        getMaterialAssessmentData(
          { ...currentFilters },
          pagination,
          material_sorting,
          is_out_of_scope_selected,
          searchParams,
          selectedView
        )
      );
    } else if (module == "site_assessment_location") {
      dispatch(
        getSiteLocationBarchartData(
          currentFilters,
          is_out_of_scope_selected,
          selectedView
        )
      );
      dispatch(
        getOverallTableData(
          currentFilters,
          is_out_of_scope_selected,
          selectedView
        )
      );
      dispatch(
        getSiteTableData(currentFilters, is_out_of_scope_selected, selectedView)
      );
    } else if (module == "corporate_assessment") {
      dispatch(
        getWorldMappoints(
          currentFilters,
          is_out_of_scope_selected,
          selectedView
        )
      );
      dispatch(
        getLeadingLaggingData(
          currentFilters,
          is_out_of_scope_selected,
          selectedView
        )
      );
    }
  };
  const onSwitchChange = (value) => {
    if (value) {
      console.log(oos_filters,"/////////////////////////")
      let currentFilters =
        Object.entries(oos_filters).length > 0
          ? {
              [oos_last_selected_filter_name]: oos_last_selected_filter_val,
            }
          : oos_filters;
      dispatch(setOOSFilters(oos_filters));
      //change payload
      dispatch(getLeftSectionFilters(moduleName, currentFilters, value));
      dispatch(setAppliedFilters({ ...oos_filters }));

      dispatch(
        getMaterialAssessmentData(
          currentFilters,
          pagination,
          [],
          value,
          searchParams,
          selectedView
        )
      );

      dispatch(
        getSiteLocationBarchartData(currentFilters, value, selectedView)
      );
      dispatch(getSiteTableData(currentFilters, value, selectedView));
      dispatch(getOverallTableData(currentFilters, value, selectedView));
      dispatch(getWorldMappoints(currentFilters, value, selectedView));

      dispatch(setOutOfScopeValue(value));
      dispatch(getLeadingLaggingData(currentFilters, value, selectedView));
    } else {
      let currentFilters =
        Object.entries(filters).length > 0
          ? {
              [last_selected_filter_name]: last_selected_filter_val,
            }
          : filters;
      dispatch(setFilters(filters));
      //change payload
      dispatch(getLeftSectionFilters(moduleName, currentFilters, value));

      dispatch(setAppliedFilters({ ...filters }));

      dispatch(
        getMaterialAssessmentData(
          currentFilters,
          pagination,
          [],
          value,
          searchParams,
          selectedView
        )
      );

      dispatch(
        getSiteLocationBarchartData(currentFilters, value, selectedView)
      );
      dispatch(getSiteTableData(currentFilters, value, selectedView));
      dispatch(getOverallTableData(currentFilters, value, selectedView));
      dispatch(getWorldMappoints(currentFilters, value, selectedView));

      dispatch(setOutOfScopeValue(value));
      dispatch(getLeadingLaggingData(currentFilters, value, selectedView));
    }
  };
  const fetchFilterOptions = (keyName) => {
    //no need to change payload here
    setDisableBtn(false);
    if (is_out_of_scope_selected) {
      dispatch(
        getDropDownOptions(
          moduleName,
          filters,
          is_out_of_scope_selected,
          keyName
        )
      );
      if (oos_filters.site_unit_name.length > 0)
        dispatch(
          getSelectedSiteOOSStatus({
            site_unit_name: oos_filters.site_unit_name,
          })
        );
    } else {
      dispatch(
        getDropDownOptions(
          moduleName,
          filters,
          is_out_of_scope_selected,
          keyName
        )
      );
      if (filters.site_unit_name.length > 0)
        dispatch(
          getSelectedSiteOOSStatus({ site_unit_name: filters.site_unit_name })
        );
    }
  };

  return (
    <div className="d-left-section-filters">
      <span className="heading">Filters</span>
      <Row className="selectWrapper">
        <Col>
          <Button
            className="apply-btn"
            type="custom"
            shape="round"
            disabled={disableBtn || dropdownLoading ? true : false}
            onClick={handleApplyFilters}
          >
            Apply
          </Button>
          <Button
            className="clear-all-filters"
            type="custom"
            shape="round"
            disabled={disableBtn || dropdownLoading ? true : false}
            onClick={() => filters_reset()}
          >
            Reset
          </Button>
        </Col>
      </Row>
      {!applyFilter ? (
        <Row>
          <Col className="fieldWrapper apply-note-wrapper">
            <span className="apply-note">
              *Click on Apply to replicate filter <br />
              changes on Right Side View
            </span>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row className="selectWrapper">
        <Col className="fieldWrapper">
          <label className="label-block">{titles.filter_names.segment}</label>
          <Select
            showArrow
            dropdownMatchSelectWidth={false}
            value={componentFilters.site_segment}
            onChange={(val) => onSelection("site_segment", val)}
            onBlur={() => fetchFilterOptions("site_segment")}
            onClear={(val) => handleOnClear("site_segment", [])}
            placeholder="Select Segment"
            mode="multiple"
            maxTagCount="responsive"
            placement="bottomLeft"
            allowClear
            className="select-input"
            size="small"
            loading={dropdownLoading}
            maxTagPlaceholder={(rec) => {
              if (Object.entries(rec).length) {
                return rec.length + " selected";
              }
            }}
          >
            {is_out_of_scope_selected
              ? site_segment_oos?.map((val) => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))
              : site_segment?.map((val) => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))}
          </Select>
        </Col>
      </Row>
      <Row className="selectWrapper">
        <Col className="fieldWrapper">
          <label className="label-block">{titles.filter_names.division}</label>
          <Select
            showArrow
            dropdownMatchSelectWidth={false}
            value={componentFilters.division}
            onChange={(val) => onSelection("division", val)}
            onBlur={() => fetchFilterOptions("division")}
            onClear={(val) => handleOnClear("division", [])}
            placeholder="Select Division"
            mode="multiple"
            maxTagCount="responsive"
            placement="bottomLeft"
            allowClear
            className="select-input"
            size="small"
            loading={dropdownLoading}
            maxTagPlaceholder={(rec) => {
              if (Object.entries(rec).length) {
                return rec.length + " selected";
              }
            }}
          >
            {is_out_of_scope_selected
              ? division_oos?.map((val) => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))
              : division?.map((val) => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))}
          </Select>
        </Col>
      </Row>
      <Row className="selectWrapper">
        <Col className="fieldWrapper">
          <label className="label-block">{titles.filter_names.bu}</label>
          <Select
            showArrow
            dropdownMatchSelectWidth={false}
            value={componentFilters.site_business_unit}
            onChange={(val) => onSelection("site_business_unit", val)}
            onBlur={() => fetchFilterOptions("site_business_unit")}
            onClear={(val) => handleOnClear("site_business_unit", [])}
            placeholder="Select BU"
            mode="multiple"
            maxTagCount="responsive"
            placement="bottomLeft"
            allowClear
            className="select-input"
            size="small"
            loading={dropdownLoading}
            maxTagPlaceholder={(rec) => {
              if (Object.entries(rec).length) {
                return rec.length + " selected";
              }
            }}
          >
            {is_out_of_scope_selected
              ? site_business_unit_oos?.map((val) => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))
              : site_business_unit?.map((val) => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))}
          </Select>
        </Col>
      </Row>
      {/* <Row className="selectWrapper">
        <Col className="fieldWrapper">
          <label className="label-block">{titles.filter_names.sub_bu}</label>
          <Select
            showArrow
            dropdownMatchSelectWidth={false}
            value={componentFilters.site_sub_business_unit}
            onChange={(val) => onSelection("site_sub_business_unit", val)}
            onBlur={() => fetchFilterOptions("site_sub_business_unit")}
            onClear={(val) => handleOnClear("site_sub_business_unit", [])}
            placeholder="Select Sub BU"
            mode="multiple"
            maxTagCount="responsive"
            placement="bottomLeft"
            allowClear
            className="select-input"
            size="small"
            loading={dropdownLoading}
            maxTagPlaceholder={(rec) => {
              if (Object.entries(rec).length) {
                return rec.length + " selected";
              }
            }}
          >
            {is_out_of_scope_selected
              ? site_sub_business_unit_oos?.map((val) => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))
              : site_sub_business_unit?.map((val) => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))}
          </Select>
        </Col>
      </Row> */}
      <Row className="selectWrapper">
        <Col className="fieldWrapper">
          <label className="label-block">{titles.filter_names.unit_type}</label>
          <Select
            showArrow
            dropdownMatchSelectWidth={false}
            value={componentFilters.site_unit_type}
            onChange={(val) => onSelection("site_unit_type", val)}
            onBlur={() => fetchFilterOptions("site_unit_type")}
            onClear={(val) => handleOnClear("site_unit_type", [])}
            placeholder="Select Unit Type"
            mode="multiple"
            maxTagCount="responsive"
            placement="bottomLeft"
            allowClear
            className="select-input"
            size="small"
            loading={dropdownLoading}
            maxTagPlaceholder={(rec) => {
              if (Object.entries(rec).length) {
                return rec.length + " selected";
              }
            }}
          >
            {is_out_of_scope_selected
              ? site_unit_type_oos?.map((val) => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))
              : site_unit_type?.map((val) => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))}
          </Select>
        </Col>
      </Row>
      <Row className="selectWrapper">
        <Col className="fieldWrapper">
          <label className="label-block">
            {titles.filter_names.site_name}
            <Popover
              placement="right"
              content={
                is_out_of_scope_selected
                  ? "Currently out of scope sites are visible, You can turn off the toggle to see in scope sites"
                  : "Currently in scope sites are visible, You can turn on the toggle to see out of scope sites"
              }
            >
              <InfoCircleFilled className="site-info" />
            </Popover>
          </label>
          <Select
            showArrow
            dropdownMatchSelectWidth={false}
            value={componentFilters.site_unit_name}
            onChange={(val) => onSelection("site_unit_name", val)}
            onBlur={() => fetchFilterOptions("site_unit_name")}
            onClear={(val) => handleOnClear("site_unit_name", [])}
            placeholder="Select Site Name"
            mode="multiple"
            maxTagCount="responsive"
            placement="bottomLeft"
            allowClear
            className="select-input"
            size="small"
            loading={dropdownLoading}
            maxTagPlaceholder={(rec) => {
              if (Object.entries(rec).length) {
                return rec.length + " selected";
              }
            }}
          >
            {is_out_of_scope_selected
              ? site_unit_name_oos?.map((val) => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))
              : site_unit_name?.map((val) => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))}
          </Select>
        </Col>
        <Col className="fieldWrapper">
          <span className="oos-toggle">Out of scope sites</span>
          <Switch
            checked={is_out_of_scope_selected}
            onChange={onSwitchChange}
            size="small"
          />
        </Col>
      </Row>
      {module == "material_assessment" ? (
        <>
          <Row className="selectWrapper">
            <Col className="fieldWrapper">
              <label className="label-block">
                {titles.filter_names.material_description}
              </label>
              <Select
                showArrow
                dropdownMatchSelectWidth={false}
                value={componentFilters.material_description}
                onChange={(val) => onSelection("material_description", val)}
                onBlur={() => fetchFilterOptions("material_description")}
                onClear={(val) => handleOnClear("material_description", [])}
                placeholder="Select Description"
                mode="multiple"
                maxTagCount="responsive"
                placement="bottomLeft"
                allowClear
                className="select-input"
                size="small"
                loading={dropdownLoading}
                maxTagPlaceholder={(rec) => {
                  if (Object.entries(rec).length) {
                    return rec.length + " selected";
                  }
                }}
              >
                {is_out_of_scope_selected
                  ? material_description_oos?.map((val) => (
                      <Select.Option key={val} value={val}>
                        {val}
                      </Select.Option>
                    ))
                  : material_description?.map((val) => (
                      <Select.Option key={val} value={val}>
                        {val}
                      </Select.Option>
                    ))}
              </Select>
            </Col>
          </Row>
          <Row className="selectWrapper">
            <Col className="fieldWrapper">
              <label className="label-block">
                {titles.filter_names.supplier_name}
              </label>
              <Select
                showArrow
                dropdownMatchSelectWidth={false}
                value={componentFilters.supplier_name}
                onChange={(val) => onSelection("supplier_name", val)}
                onBlur={() => fetchFilterOptions("supplier_name")}
                onClear={(val) => handleOnClear("supplier_name", [])}
                placeholder="Select Supplier Name"
                mode="multiple"
                maxTagCount="responsive"
                placement="bottomLeft"
                allowClear
                className="select-input"
                size="small"
                loading={dropdownLoading}
                maxTagPlaceholder={(rec) => {
                  if (Object.entries(rec).length) {
                    return rec.length + " selected";
                  }
                }}
              >
                {is_out_of_scope_selected
                  ? supplier_name_oos?.map((val) => (
                      <Select.Option key={val} value={val}>
                        {val}
                      </Select.Option>
                    ))
                  : supplier_name?.map((val) => (
                      <Select.Option key={val} value={val}>
                        {val}
                      </Select.Option>
                    ))}
              </Select>
            </Col>
          </Row>
          <Row className="selectWrapper">
            <Col className="fieldWrapper">
              <label className="label-block">
                {titles.filter_names.adh_status}
              </label>
              <Select
                showArrow
                dropdownMatchSelectWidth={false}
                value={componentFilters.overall_compliance}
                onChange={(val) => onSelection("overall_compliance", val)}
                onBlur={() => fetchFilterOptions("overall_compliance")}
                onClear={(val) => handleOnClear("overall_compliance", [])}
                placeholder="Select Status"
                mode="multiple"
                maxTagCount="responsive"
                placement="bottomLeft"
                allowClear
                className="select-input"
                size="small"
                loading={dropdownLoading}
                maxTagPlaceholder={(rec) => {
                  if (Object.entries(rec).length) {
                    return rec.length + " selected";
                  }
                }}
              >
                {is_out_of_scope_selected
                  ? overall_compliance_oos?.map((val) => (
                      <Select.Option key={val} value={val}>
                        {val}
                      </Select.Option>
                    ))
                  : overall_compliance?.map((val) => (
                      <Select.Option key={val} value={val}>
                        {val}
                      </Select.Option>
                    ))}
              </Select>
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}
      <ToastContainer />
    </div>
  );
};
export default FiltersSection;
