import React from "react";

export const raw_qna_table_columns = [
  {
    title: "Question",
    dataIndex: "question",
  },
  {
    title: "Q/A Category",
    dataIndex: "category",
  },
  {
    title: "Answer",
    dataIndex: "answer",
  },
];
export const raw_qna_table_data = [
  {
    question: "How do I navigate to different report sections?",
    category: "Navigation and Selection",
    answer:
      "On the top right hand side menu, the user can select the tab of the report they want to visit by clicking on the tab name.",
  },
  {
    question:
      "How do I filter out specific Segment/BU/Site to get a detailed perspective?",
    category: "Navigation and Selection",
    answer:
      "Use the filter panel at the left side of the report. After making a selection in all the filters, click on the'Apply' button to reflect the same on the charts.",
  },
  {
    question: "What is the time period?",
    category: "Data Related",
    answer: "Latest 26 periods",
  },
  {
    question: "How do I reset all the selections on a tab?",
    category: "Navigation and Selection",
    answer:
      "User can press the 'Reset' button available under filter panel at the left side of the report ",
  },

  {
    question:
      "To whom should I reach out for dashboard improvement/suggestions?",
    category: "Dashboard Improvement",
    answer:
      "Please reach out to the Product Owner (Lydia Gutierrez) for any further questions/feedback/improvement/suggestions.",
  },
  {
    question:
      "To whom should I reach out for data concerns/queries and what is the process?",
    category: "Dashboard Improvement",
    answer:
      "If the query is related to the definition of KPIs, calculations involved and other issues or further clarifications, Please reach out to Product Owner (Lydia Gutierrez).",
  },
  {
    question: "What is the refresh frequency of the data in the dashboard?",
    category: "Data Related",
    answer: "Daily",
  },

  {
    question: "What are the data sources in this report?",
    category: "Data Related",
    answer:
      "Data comes from the Nexus, Atlas T&C and Veritas. Inbound Verification Plan (IBV) - Atlas T&C,  MRA - Nexus (MRA Module)      Supplier Profile - Nexus (Supplier Profile Module),      Material Specifications - Veritas",
  },

  {
    question:
      "Do we have the logic specified for the various KPIs used in the report?",
    category: "Data Related",
    answer:
      "Refer to the Definitions & Rules and Calculations in the below section ",
  },
  {
    question:
      "The report visuals sometime don't work as expected - what can I do?",
    category: "Navigation and Selection",
    answer:
      "Refresh your browser tab or clear cache. If this doesn't help, try to open it in a different browser. ",
  },

  {
    question: "Tabs/Visuals take more time than usual (15 sec) to load.",
    category: "Navigation and Selection",
    answer:
      "Ensure that you use a standard updated web browser like Microsoft Edge or Google Chrome. A stable and high speed internet connection will assist with performance.",
  },
  {
    question: "Why are some sites missing from the data",
    category: "Data Related",
    answer: "They are out of scope in MVP1",
  },
  {
    question: "How to access the User Guide?",
    category: "Documents",
    answer: "TBD",
  },
  {
    question: "Why can I not see my site?",
    category: "No Data Related",
    answer:
      "Your site is out of scope.  Please use the OOS toggle to see your site on the world view",
  },
  {
    question: "Why is there no data for the Supplier?",
    category: "No Data Related",
    answer:
      "The Inbound entry has a blank supplier field in SAP Atlas or it is an inter-company transfer (ICB PO).  This will be filtered out in Release #2",
  },
  {
    question: "Why is there no data for Inbound Verification Plan Readiness?",
    category: "No Data Related",
    answer: "Inbound information is not in SAP Atlas",
  },
  {
    question: "Why is there no data for MRA Status?",
    category: "No Data Related",
    answer:
      "There are a few reasons for this:  1. The MRA needs to join at the material AND GSL combination. 2. The MRA exists but the corresponding supplier location is not identified; 3. The supplier number does not match the IBV; 4. the MRA is not found for the material number from IBV; or 4. the material is related to an intercompany transfer",
  },
  {
    question: "Why is there no data for Specification Status?",
    category: "No Data Related",
    answer:
      "The specificiation status is connected on GRD; no data shows when the connection between GRD and specification could not be located.",
  },
  {
    question: "Why is there no data for Supplier Audit Current?",
    category: "No Data Related",
    answer:
      "The Supplier Audit field in Nexus is empty; the material is an inter-company transfer (ICB PO)",
  },
  {
    question: "Why does Last Supplier Audit Outcome show no data?",
    category: "No Data Related",
    answer:
      " 1. The Nexus Supplier Management module is not populated; 2. the Nexus MRA field is empty; or 3. the material is in inter-company transfer (ICB PO)",
  },
  {
    question: "Why does the Supplier Status show no data?",
    category: "No Data Related",
    answer:
      "1. Supplier number is Atlas for the material does not match Nexus data. Or 2. the material is an inter-company transfer (ICB PO)",
  },
];
export const pack_qna_table_columns = [
  {
    title: "Question",
    dataIndex: "question",
  },
  {
    title: "Q/A Category",
    dataIndex: "category",
  },
  {
    title: "Answer",
    dataIndex: "answer",
  },
];
export const pack_qna_table_data = [
  {
    question: "How do I navigate to different report sections?",
    category: "Navigation and Selection",
    answer:
      "On the top right hand side menu, the user can select the tab of the report they want to visit by clicking on the tab name.",
  },
  {
    question:
      "How do I filter out specific Segment/BU/Site to get a detailed perspective?",
    category: "Navigation and Selection",
    answer:
      "Use the filter panel at the left side of the report. After making a selection in all the filters, click on the'Apply' button to reflect the same on the charts.",
  },
  {
    question: "What is the time period?",
    category: "Data Related",
    answer: "Latest 26 periods",
  },
  {
    question: "How do I reset all the selections on a tab?",
    category: "Navigation and Selection",
    answer:
      "User can press the 'Reset' button available under filter panel at the left side of the report ",
  },

  {
    question:
      "To whom should I reach out for dashboard improvement/suggestions?",
    category: "Dashboard Improvement",
    answer:
      "Please reach out to the Product Owner (Lydia Gutierrez) for any further questions/feedback/improvement/suggestions.",
  },
  {
    question:
      "To whom should I reach out for data concerns/queries and what is the process?",
    category: "Dashboard Improvement",
    answer:
      "If the query is related to the definition of KPIs, calculations involved and other issues or further clarifications, Please reach out to Product Owner (Lydia Gutierrez).",
  },
  {
    question: "What is the refresh frequency of the data in the dashboard?",
    category: "Data Related",
    answer: "Daily",
  },

  {
    question: "What are the data sources in this report?",
    category: "Data Related",
    answer:
      "Data comes from the Nexus, Atlas T&C and Veritas. Inbound Verification Plan (IBV) - Atlas T&C,  MRA - Nexus (MRA Module)      Supplier Profile - Nexus (Supplier Profile Module),      Material Specifications - Veritas",
  },

  {
    question:
      "Do we have the logic specified for the various KPIs used in the report?",
    category: "Data Related",
    answer:
      "Refer to the Definitions & Rules and Calculations in the below section ",
  },
  {
    question:
      "The report visuals sometime don't work as expected - what can I do?",
    category: "Navigation and Selection",
    answer:
      "Refresh your browser tab or clear cache. If this doesn't help, try to open it in a different browser. ",
  },

  {
    question: "Tabs/Visuals take more time than usual (15 sec) to load.",
    category: "Navigation and Selection",
    answer:
      "Ensure that you use a standard updated web browser like Microsoft Edge or Google Chrome. A stable and high speed internet connection will assist with performance.",
  },
  {
    question: "Why are some sites missing from the data",
    category: "Data Related",
    answer: "They are out of scope in MVP1",
  },
  {
    question: "How to access the User Guide?",
    category: "Documents",
    answer: "TBD",
  },
  {
    question: "Why can I not see my site?",
    category: "No Data Related",
    answer:
      "Your site is out of scope.  Please use the OOS toggle to see your site on the world view",
  },
  {
    question: "Why is there no data for the Supplier?",
    category: "No Data Related",
    answer:
      "The Inbound entry has a blank supplier field in SAP Atlas or it is an inter-company transfer (ICB PO).  This will be filtered out in Release #2",
  },
  {
    question: "Why is there no data for Inbound Verification Plan Readiness?",
    category: "No Data Related",
    answer: "Inbound information is not in SAP Atlas",
  },
  {
    question: "Why is there no data for MRA Status?",
    category: "No Data Related",
    answer:
      "There are a few reasons for this:  1. The MRA needs to join at the material AND GSL combination. 2. The MRA exists but the corresponding supplier location is not identified; 3. The supplier number does not match the IBV; 4. the MRA is not found for the material number from IBV; or 4. the material is related to an intercompany transfer",
  },
  {
    question: "Why is there no data for Specification Status?",
    category: "No Data Related",
    answer:
      "The specificiation status is connected on GRD; no data shows when the connection between GRD and specification could not be located.",
  },
  {
    question: "Why is there no data for Supplier Audit Current?",
    category: "No Data Related",
    answer:
      "The Supplier Audit field in Nexus is empty; the material is an inter-company transfer (ICB PO)",
  },
  {
    question: "Why does Last Supplier Audit Outcome show no data?",
    category: "No Data Related",
    answer:
      " 1. The Nexus Supplier Management module is not populated; 2. the Nexus MRA field is empty; or 3. the material is in inter-company transfer (ICB PO)",
  },
  {
    question: "Why does the Supplier Status show no data?",
    category: "No Data Related",
    answer:
      "1. Supplier number is Atlas for the material does not match Nexus data. Or 2. the material is an inter-company transfer (ICB PO)",
  },
];
export const raw_inp_info_cols = [
  {
    title: "Component",
    dataIndex: "component",
  },
  {
    title: "Explanation",
    dataIndex: "explanation",
  },
  {
    title: "Type of input",
    dataIndex: "input_type",
  },
];
export const raw_inp_info_data = [
  {
    component: "Material GRD",
    explanation: "Identifies materials by number format",
    input_type: "Data point",
  },
  {
    component: "Material Description",
    explanation: "Identifies materials by description/name",
    input_type: "Data point",
  },
  {
    component: "Site Name",
    explanation:
      "Identifies the Mars receiving site for the specific material and supplier combination ",
    input_type: "Data point",
  },
  {
    component: "Supplier Location (GSL)",
    explanation:
      "Supplier manufacturing location for the specific material is identified and linked to a specific geographical location",
    input_type: "Data point",
  },
  {
    component: "Months since Last Goods Receipt",
    explanation: "Shows last time the material was received at the Mars site.",
    input_type:
      "Data point to determine if a material has been used within 12 months",
  },
  {
    component: "Supplier Audit Current",
    explanation:
      "Audit execution date records the completion of audit of the supplier manufacturing location as part of the SQA audit program. ",
    input_type:
      "Data point for future use. Information to confirm that a supplier audit is current.",
  },
];
export const pack_inp_info_cols = [
  {
    title: "Component",
    dataIndex: "component",
  },
  {
    title: "Explanation",
    dataIndex: "explanation",
  },
  {
    title: "Type of input",
    dataIndex: "input_type",
  },
];
export const pack_inp_info_data = [
  {
    component: "Material GRD",
    explanation: "Identifies materials by number format",
    input_type: "Data point",
  },
  {
    component: "Material Description",
    explanation: "Identifies materials by description/name",
    input_type: "Data point",
  },
  {
    component: "Site Name",
    explanation:
      "Identifies the Mars receiving site for the specific material and supplier combination ",
    input_type: "Data point",
  },
  {
    component: "Supplier Location (GSL)",
    explanation:
      "Supplier manufacturing location for the specific material is identified and linked to a specific geographical location",
    input_type: "Data point",
  },
  {
    component: "Months since Last Goods Receipt",
    explanation: "Shows last time the material was received at the Mars site.",
    input_type:
      "Data point to determine if a material has been used within 12 months",
  },
  {
    component: "Supplier Audit Current",
    explanation:
      "Audit execution date records the completion of audit of the supplier manufacturing location as part of the SQA audit program. ",
    input_type:
      "Data point for future use. Information to confirm that a supplier audit is current.",
  },
];
export const raw_adh_req_cols = [
  {
    title: "Requirement",
    dataIndex: "requirement",
  },
  {
    title: "Explanation",
    dataIndex: "explanation_2",
  },
  {
    title: "Adhering if:",
    dataIndex: "adheringIf",
  },
  {
    title: "Non-Adherence if:",
    dataIndex: "nonadheringIf",
  },
];
export const raw_adh_req_data = [
  {
    requirement: "Material Risk Assessment (MRA) Status",
    explanation_2:
      "Informs status of MRA as a confirmation that material assessment is found. Does not verify the content of the MRA nor the quality of the assessment of the intrinsic food safety hazards and risks associated with a material.",
    adheringIf: "Approved MRA was found",
    nonadheringIf:
      "Not Approved or not available for the specific GRD or Draft only revision",
  },
  {
    requirement: "Specification Status",
    explanation_2:
      "Material specification is approved in Veritas for targeted material/ Mars Site combination. Does not validate the information on the specification or how and where in the pipeline these risks and hazards are controlled.",
    adheringIf:
      "Approved specification can be mapped to the material and GSL combination ",
    nonadheringIf:
      "Not Approved, No Started, Does not exist, In Process, Review, Draft",
  },
  {
    requirement: "Inbound Verification Plan Readiness",
    explanation_2:
      "Material and supplier manufacturing site are set up in the Atlas and assigned to the specific receiving Mars Site with inbound verification either visual or pre-shipment. It doesn't demonstrate conformity to specification.",
    adheringIf:
      "The material/supplier combination require inbound verification in T&C SAP",
    nonadheringIf:
      "No match for Supplier/material was found in SAP inbound module",
  },
  {
    requirement: "Supplier Status Assigned",
    explanation_2:
      "Supplier's manufacturing location is permitted to supply to the Mars site according to Mars verification programme following the requirements of supplier development and continuous improvement ",
    adheringIf: "In Development, Assured, Approved",
    nonadheringIf:
      "Not Acceptable, Not Available, Not Approved, Initial Assessment, Spot Buy",
  },
  {
    requirement: "Last Audit Supplier Outcome",
    explanation_2:
      "Audit execution date and audit score is documented and use to calculate supplier next audit date.  ",
    adheringIf: "Excellent, Acceptable, Approved",
    nonadheringIf: "Pre assessment audit, Not Available, At Risk, Conditional",
  },
];
export const pack_adh_req_cols = [
  {
    title: "Requirement",
    dataIndex: "requirement",
  },
  {
    title: "Explanation",
    dataIndex: "explanation_2",
  },
  {
    title: "Adhering if:",
    dataIndex: "adheringIf",
  },
  {
    title: "Non-Adherence if:",
    dataIndex: "nonadheringIf",
  },
];
export const pack_adh_req_data = [
  {
    requirement: "Material Risk Assessment (MRA) Status",
    explanation_2:
      "Informs status of MRA as a confirmation that material assessment is found. Does not verify the content of the MRA nor the quality of the assessment of the intrinsic food safety hazards and risks associated with a material.",
    adheringIf: "Approved MRA was found",
    nonadheringIf:
      "Not Approved or not available for the specific GRD or Draft only revision",
  },
  {
    requirement: "Specification Status",
    explanation_2:
      "Material specification is approved in Veritas for targeted material/ Mars Site combination. Does not validate the information on the specification or how and where in the pipeline these risks and hazards are controlled.",
    adheringIf:
      "Approved specification can be mapped to the material and GSL combination ",
    nonadheringIf:
      "Not Approved, No Started, Does not exist, In Process, Review, Draft",
  },
  {
    requirement: "Inbound Verification Plan Readiness",
    explanation_2:
      "Material and supplier manufacturing site are set up in the Atlas and assigned to the specific receiving Mars Site with inbound verification either visual or pre-shipment. It doesn't demonstrate conformity to specification.",
    adheringIf:
      "The material/supplier combination require inbound verification in T&C SAP",
    nonadheringIf:
      "No match for Supplier/material was found in SAP inbound module",
  },
  {
    requirement: "Supplier Status Assigned",
    explanation_2:
      "Supplier's manufacturing location is permitted to supply to the Mars site according to Mars verification programme following the requirements of supplier development and continuous improvement ",
    adheringIf: "In Development, Assured, Approved",
    nonadheringIf:
      "Not Acceptable, Not Available, Not Approved, Initial Assessment, Spot Buy",
  },
  {
    requirement: "Last Audit Supplier Outcome",
    explanation_2:
      "Audit execution date and audit score is documented and use to calculate supplier next audit date.  ",
    adheringIf: "Excellent, Acceptable, Approved",
    nonadheringIf: "Pre assessment audit, Not Available, At Risk, Conditional",
  },
];
export const raw_site_columns = [
  {
    title: "Percentage",
    dataIndex: "percentage",
  },
  {
    title: "Color Coding",
    dataIndex: "color",
    render(text) {
      return {
        props: {
          style: { background: text },
        },
        children: <div></div>,
      };
    },
  },
];
export const raw_site_data = [
  {
    key: "1",
    percentage: "0-30%",
    color: "#DF4941",
  },
  {
    key: "2",
    percentage: "30-50%",
    color: "#EF981D",
  },
  {
    key: "3",
    percentage: "50-70%",
    color: "#FFCD1C",
  },
  {
    key: "4",
    percentage: "70-90%",
    color: "#C2DA61",
  },
  {
    key: "5",
    percentage: "90-100%",
    color: "#6B9962",
  },
  {
    key: "6",
    percentage: "No Data",
    color: "#000000",
  },
];
export const pack_site_columns = [
  {
    title: "Percentage",
    dataIndex: "percentage",
  },
  {
    title: "Color Coding",
    dataIndex: "color",
    render(text) {
      return {
        props: {
          style: { background: text },
        },
        children: <div></div>,
      };
    },
  },
];
export const pack_site_data = [
  {
    key: "1",
    percentage: "0-30%",
    color: "#DF4941",
  },
  {
    key: "2",
    percentage: "30-50%",
    color: "#EF981D",
  },
  {
    key: "3",
    percentage: "50-70%",
    color: "#FFCD1C",
  },
  {
    key: "4",
    percentage: "70-90%",
    color: "#C2DA61",
  },
  {
    key: "5",
    percentage: "90-100%",
    color: "#6B9962",
  },
  {
    key: "6",
    percentage: "No Data",
    color: "#000000",
  },
];
export const raw_material_columns = [
  {
    title: "Assessment",
    dataIndex: "requirement",
  },
  {
    title: "Time Since Last Goods Received",
    dataIndex: "last_recieved",
  },
  {
    title: "Overall Rating",
    dataIndex: "rating",
  },
];
export const raw_material_data = [
  {
    requirement:
      "All requirements are found and confirmed according to the criteria.",
    last_recieved: "NA",
    rating: <span className="adhering-color">Adhering</span>,
  },
  {
    requirement:
      'One or two of 5 requirements have  "No Data Available" and all others are "Approved"',
    last_recieved: "Received in the last 12 months",
    rating: (
      <span className="partially-adhering-color">Partially Adhering</span>
    ),
  },
  {
    requirement: 'One or more requirements "Not Approved"',
    last_recieved: "Received in the last 12 months",
    rating: <span className="nonadhering-color">Non Adhering</span>,
  },
  {
    requirement:
      'One "Not Approved" and one or more requirements with "No Data Available"',
    last_recieved: "Received in the last 12 months",
    rating: <span className="nonadhering-color">Non Adhering</span>,
  },
  {
    requirement: 'More than 2 of 5 requirements have "No Data Available"',
    last_recieved: "NA",
    rating: <span className="no-data-color">No Data</span>,
  },
];
export const pack_material_columns = [
  {
    title: "Assessment",
    dataIndex: "requirement",
  },
  {
    title: "Time Since Last Goods Received",
    dataIndex: "last_recieved",
  },
  {
    title: "Overall Rating",
    dataIndex: "rating",
  },
];
export const pack_material_data = [
  {
    requirement:
      "All requirements are found and confirmed according to the criteria.",
    last_recieved: "NA",
    rating: <span className="adhering-color">Adhering</span>,
  },
  {
    requirement:
      'One or two of 5 requirements have  "No Data Available" and all others are "Approved"',
    last_recieved: "Received in the last 12 months",
    rating: (
      <span className="partially-adhering-color">Partially Adhering</span>
    ),
  },
  {
    requirement: 'One or more requirements "Not Approved"',
    last_recieved: "Received in the last 12 months",
    rating: <span className="nonadhering-color">Non Adhering</span>,
  },
  {
    requirement:
      'One "Not Approved" and one or more requirements with "No Data Available"',
    last_recieved: "Received in the last 12 months",
    rating: <span className="nonadhering-color">Non Adhering</span>,
  },
  {
    requirement: 'More than 2 of 5 requirements have "No Data Available"',
    last_recieved: "NA",
    rating: <span className="no-data-color">No Data</span>,
  },
];
