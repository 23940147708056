import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialAssessmentColumns from "./columns";
import { Row, Col, Button, Modal, Table } from "antd";
import {
  setResetSearchFlag,
  setMatSearchParams,
} from "../../slicers/material-assessment-slicer";
import "./materialassessment.scss";

const MaterialTable = ({
  data = [],
  loader = 1,
  isSiteView = false,
  handleTableChange,
  apiPagination,
}) => {
  const dispatch = useDispatch();
  const { selectedView } = useSelector((state) => state.homeView);
  const { resetSearchFlag } = useSelector((state) => state.materialAssessment);
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [legends, setLegends] = useState({
    adhering: true,
    partialAdhering: true,
    nonAdhering: true,
    noData: true,
  });
  const [tableKey, setTableKey] = useState(0);
  const resetFilters = () => {
    setTableKey((tableKey) => tableKey + 1);
    dispatch(setResetSearchFlag(true));
    dispatch(setMatSearchParams({}));
  };
  useEffect(() => {
    if (!data || !Array.isArray(data)) return setTableData([]);

    const newData = data.filter(
      ({ overall_compliance }) =>
        (legends.adhering ? overall_compliance === "Adhering" : false) ||
        (legends.partialAdhering
          ? overall_compliance === "Partially Adhering"
          : false) ||
        (legends.nonAdhering ? overall_compliance === "Non-Adhering" : false) ||
        (legends.noData ? overall_compliance === "No Data" : false)
    );

    setTableData(newData);
  }, [legends, data]);

  const LegendsBar = ({ showModal }) => (
    <>
      <span className="percent-compliance">Process Adherence:</span>
      <span className="compliance-indicators">
        <Button
          size="small"
          className="legend-info-btn"
          primary
          onClick={showModal}
        >
          Legend Info.
        </Button>
      </span>
      <div className="compliance-indicators">
        <div
          className="compliance-indicators"
          onClick={() =>
            setLegends({ ...legends, adhering: !legends.adhering })
          }
        >
          <span
            className={
              legends.adhering
                ? "compliance-indicator-circles adhering"
                : "compliance-indicator-circles grey"
            }
          ></span>
          <span
            className={
              legends.adhering
                ? "compliance-indicator-text  text-dark"
                : "compliance-indicator-text  text-faded"
            }
          >
            Adhering
          </span>
        </div>
        <div
          className="compliance-indicators"
          onClick={() =>
            setLegends({
              ...legends,
              partialAdhering: !legends.partialAdhering,
            })
          }
        >
          <span
            className={
              legends.partialAdhering
                ? "compliance-indicator-circles partially-adhering"
                : "compliance-indicator-circles grey"
            }
          ></span>
          <span
            className={
              legends.partialAdhering
                ? "compliance-indicator-text  text-dark"
                : "compliance-indicator-text  text-faded"
            }
          >
            Partially Adhering
          </span>
        </div>
        <div
          className="compliance-indicators"
          onClick={() =>
            setLegends({ ...legends, nonAdhering: !legends.nonAdhering })
          }
        >
          <span
            className={
              legends.nonAdhering
                ? "compliance-indicator-circles nonadhering"
                : "compliance-indicator-circles grey"
            }
          ></span>
          <span
            className={
              legends.nonAdhering
                ? "compliance-indicator-text  text-dark"
                : "compliance-indicator-text  text-faded"
            }
          >
            Non-Adhering
          </span>
        </div>
        <div
          className="compliance-indicators"
          onClick={() => setLegends({ ...legends, noData: !legends.noData })}
        >
          <span
            className={
              legends.noData
                ? "compliance-indicator-circles no-data"
                : "compliance-indicator-circles grey"
            }
          ></span>
          <span
            className={
              legends.noData
                ? "compliance-indicator-text  text-dark"
                : "compliance-indicator-text  text-faded"
            }
          >
            No Data
          </span>
        </div>
      </div>
    </>
  );
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Row className="main-content">
      <Col span={24} className="first-row">
        <Col span={6} className="reset-search-button" align="left">
          <Button type="custom" shape="round" onClick={resetFilters}>
            Reset Search
          </Button>
        </Col>
        <Col span={18} className="tableIndicators" align="right">
          <LegendsBar showModal={showModal} />
        </Col>
      </Col>
      <Col span={24} className="material-table-section">
        <Table
          key={tableKey}
          className="custom-table"
          columns={MaterialAssessmentColumns(resetSearchFlag, selectedView)}
          dataSource={tableData}
          size="small"
          showSorterTooltip={{ placement: "bottom" }}
          loading={loader ? true : false}
          scroll={
            isSiteView
              ? {
                  x: 1500,
                  y: "55vh",
                }
              : {
                  x: 1500,
                  y: "57vh",
                }
          }
          pagination={apiPagination}
          onChange={handleTableChange}
        />
      </Col>
      <Modal
        width={"fit-content"}
        title="Legend Guide"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
      >
        <Content />
      </Modal>
    </Row>
  );
};

const Content = () => {
  return (
    <div className="material-popover-content">
      <h4 className="title">
        Process Adherence - Scoring guidance for self assessment
      </h4>
      <Table
        className="calculation-table"
        columns={[
          {
            title: "Status",
            dataIndex: "status",
            render(text, rec) {
              return {
                props: {
                  style: { color: rec.color },
                },
                children: <div>{text}</div>,
              };
            },
          },
          {
            title: "Color",
            dataIndex: "color",
            render(text, rec) {
              return {
                props: {
                  style: { background: rec.color },
                },
                children: <div></div>,
              };
            },
          },
          {
            title: "Definition",
            dataIndex: "definition",
            render(text, rec) {
              return {
                props: {
                  style: { color: rec.color },
                },
                children: <div>{text}</div>,
              };
            },
          },
        ]}
        dataSource={[
          {
            key: "1",
            status: "Adhering",
            definition:
              "All requirements are found and confirmed according to the                 criteria.",
            color: "#0000ff",
          },
          {
            key: "2",
            status: "Partially Adhering",
            definition: "Data not available for 1 or 2 requirements",
            color: "#6495ed",
          },
          {
            key: "3",
            status: "Non-Adhering",
            definition: "One or more requirements not adhering",
            color: "#bf40bf",
          },
          {
            key: "4",
            status: "No Data",
            definition: "Information not available to assess",
            color: "#282c35",
          },
        ]}
        size="middle"
        pagination={false}
        // showHeader={false}
      />
    </div>
  );
};
export default MaterialTable;
