import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MapChart from "../../components/mapComponent/MapComp";
import { withRouter } from "react-router-dom";
import { Layout, Row, Col, Button, Modal, Table } from "antd";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getWorldMappoints,
  getLeadingLaggingData,
  getThresholdsData,
} from "../../slicers/corporate-assessment-slicer";
import { getLeftSectionFilters } from "../../slicers/sidebar-filters-slicer";
import { setSelectedView } from "../../slicers/home-slicer";
import Wrapper from "../../components/wrapper";
import "./corporateassessment.scss";
import LeadingLaggingUI from "./leading-lagging-ui";
import Loader from "../../components/loader";
import RefreshFailureAlert from "../../components/alerts/refresh-failure";
import FiltersApplied from "../filter-panel/filter-applied-section";
import SidebarFilters from "../filter-panel/filters";
import RadioButtons from "../../components/raw-pack-radio-btns/radioButtons";

const { Sider, Content } = Layout;

function CorporateAssessment() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leadingUnits, setLeadingUnits] = useState({});
  const [laggingUnits, setLaggingUnits] = useState({});
  const [leadingRadioSelection, setLeadingRadioSelection] = useState("segment");
  const [laggingRadioSelection, setLaggingRadioSelection] = useState("segment");
  const [newLegends, setNewLegends] = useState({});
  const [thresholdAPIData, setThresholdAPIData] = useState([]);
  const [thresholdIDMapping, setThresholdIDMapping] = useState({});
  const [legendInfoTableData, setlegendInfoTableData] = useState([]);
  const {
    dropdownLoading,
    leading_lagging_data,
    last_Refresh_date,
    alertRefreshDate,
    currentDateIST,
    actualDateIST,
    leading_lagging_loader,
    thresholds_data,
  } = useSelector((state) => state.corporateAssessment);
  const {
    appliedFilters,
    is_out_of_scope_selected,
    oos_sites_list,
    oos_appliedFilters,
    last_selected_filter_name,
    last_selected_filter_val,
    oos_last_selected_filter_name,
    oos_last_selected_filter_val,
  } = useSelector((state) => state.sidebarFilters);
  const { selectedView } = useSelector((state) => state.homeView);
  useEffect(() => {
    let tempView = selectedView;
    if (
      selectedView === "" ||
      selectedView === "home" ||
      selectedView === undefined
    ) {
      dispatch(setSelectedView("Raw"));
    } else {
      dispatch(setSelectedView(tempView));
    }
  }, []);
  useEffect(() => {
    if (thresholds_data.length) {
      let tempArray = {};
      thresholds_data.map((threshold, i) => {
        tempArray[threshold.id] = true;
      });
      setNewLegends(tempArray);
      let temp = {};
      Object.keys(tempArray).forEach((ID, i) => {
        let relevantObj = thresholds_data.filter((rec, index) => rec.id == ID);

        temp[ID] = {
          lt: relevantObj[0].lower_threshold,
          ut: relevantObj[0].upper_threshold,
        };
      });

      setThresholdIDMapping(temp);
      //for legend info table
      let new_table_data = thresholds_data.filter(
        (data, i) => data.id != 1 && data.id != 2
      );
      setlegendInfoTableData(new_table_data);
    }
    setThresholdAPIData(thresholds_data);
  }, [thresholds_data]);
  useEffect(() => {
    dispatch(getThresholdsData());
    if (selectedView === "Raw" || selectedView === "Packaging") {
      //anusha why check only for in scope applied filters ? why not for oos
      if (Object.entries(appliedFilters).length) {
        let currentFilters;
        //change payload
        if (is_out_of_scope_selected) {
          currentFilters = {
            [oos_last_selected_filter_name]: oos_last_selected_filter_val,
          };
        } else {
          currentFilters = {
            [last_selected_filter_name]: last_selected_filter_val,
          };
        }
        dispatch(
          getLeftSectionFilters(
            "corpAssessment",
            currentFilters,
            is_out_of_scope_selected
          )
        );
        dispatch(
          getWorldMappoints(
            currentFilters,
            is_out_of_scope_selected,
            selectedView
          )
        );
        dispatch(
          getLeadingLaggingData(
            currentFilters,
            is_out_of_scope_selected,
            selectedView
          )
        );
      } else {
        dispatch(
          getLeftSectionFilters("corpAssessment", {}, is_out_of_scope_selected)
        );
        dispatch(getWorldMappoints({}, is_out_of_scope_selected, selectedView));
        dispatch(
          getLeadingLaggingData({}, is_out_of_scope_selected, selectedView)
        );
      }
    }
  }, [selectedView]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (leading_lagging_data.leading_units) {
      let temp = leading_lagging_data.leading_units.division.filter(
        (d) => d.self_name !== "Food N/A"
      );

      let newData = Object.assign({}, leading_lagging_data.leading_units);
      newData.division = temp;

      setLeadingUnits(newData);
    }
    if (leading_lagging_data.lagging_units) {
      let temp = leading_lagging_data.lagging_units.division.filter(
        (d) => d.self_name !== "Food N/A"
      );

      let newData = Object.assign({}, leading_lagging_data.lagging_units);
      newData.division = temp;
      setLaggingUnits(newData);
    }
  }, [leading_lagging_data]);

  let onLeadingUnitsRadioValueChange = (e) => {
    setLeadingRadioSelection(e.target.value);
  };
  let onLaggingUnitsRadioValueChange = (e) => {
    setLaggingRadioSelection(e.target.value);
  };

  const handleLegendClick = (ID) => {
    let temp = Object.assign({}, newLegends);
    temp[ID] = !newLegends[ID];
    setNewLegends(temp);
  };
  useEffect(() => {}, [newLegends]);
  const LegendsBar = ({ showModal, handleLegendClick }) => (
    <>
      <span className="corporate-percent-compliance">
        % of Process Adherence:
      </span>
      <div className="corporate-compliance-indicators">
        <Button size="small" className="legend-info-btn" onClick={showModal}>
          Legend Info.
        </Button>
        {thresholdAPIData.map((threshold, index) => {
          let ID = threshold.id;
          return (
            <div
              className="corporate-compliance-indicators"
              onClick={() => {
                handleLegendClick(ID);
              }}
              key={threshold.id}
            >
              <span
                style={{
                  backgroundColor: newLegends[ID]
                    ? threshold.color_codes
                    : "grey",
                }}
                className={
                  selectedView === "Raw"
                    ? "corporate-compliance-indicator-circles"
                    : "corporate-compliance-indicator-square"
                }
              ></span>
              <span
                className={
                  newLegends[ID]
                    ? "corporate-compliance-indicator-text text-dark"
                    : "corporate-compliance-indicator-text text-faded"
                }
              >
                {threshold.id == 2
                  ? "No Data"
                  : threshold.lower_threshold +
                    " - " +
                    threshold.upper_threshold +
                    "%"}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Wrapper heading={"1"}>
      <Loader
        loading={dropdownLoading > 0}
        tip={"Please wait while filters are loaded.."}
      >
        <div className="corporate_assessment">
          <Layout>
            <Sider className="sidebar">
              <SidebarFilters module="corporate_assessment" />
            </Sider>
            <Layout className={"content_wrapper"}>
              <Content>
                <Row wrap={false} className="world-map-width">
                  <Col span={18} align="left" className="radio-buttons-wrapper">
                    <RadioButtons />
                  </Col>
                  <Col span={6} className="last_updated_text" align="right">
                    <span>
                      {last_Refresh_date ? (
                        <div>
                          Daily Refresh
                          <br /> Last Refreshed Date is:{" "}
                          <span
                            className={
                              alertRefreshDate ? "alert-refresh-date" : ""
                            }
                          >
                            {last_Refresh_date}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                    {currentDateIST !== last_Refresh_date &&
                    actualDateIST !== last_Refresh_date ? (
                      <RefreshFailureAlert />
                    ) : (
                      <></>
                    )}
                  </Col>
                  <ToastContainer />
                </Row>
                <Col span={6}>
                  <h2 className="page-title"> Map View </h2>
                </Col>
                <FiltersApplied
                  appliedFilters={
                    is_out_of_scope_selected
                      ? oos_appliedFilters
                      : appliedFilters
                  }
                />
                {is_out_of_scope_selected && oos_sites_list.length > 0 ? (
                  <span className="oos-note">
                    Note: You have selected out of scope site/s{" "}
                    {oos_sites_list.map((d, i) => (
                      <span key={d}>{d + ", "}</span>
                    ))}
                    in site name filter selection
                  </span>
                ) : (
                  ""
                )}
                <Row wrap={false} className="world-map-width">
                  <Col span={17} className="map-wrapper">
                    <Row className="mqm-header-contents" wrap={false}>
                      <Col span={16}>
                        {selectedView === "Raw" ? (
                          <h4 className="mqm-title">
                            Raw Material Process Adherence Indicator
                          </h4>
                        ) : (
                          <h4 className="mqm-title">
                            Packaging Process Adherence Indicator
                          </h4>
                        )}
                      </Col>
                    </Row>

                    {!is_out_of_scope_selected ? (
                      <Row className="mqm-header-contents" wrap={false}>
                        <Col align="right" span={24}>
                          {thresholdAPIData.length ? (
                            <LegendsBar
                              showModal={showModal}
                              handleLegendClick={handleLegendClick}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    <MapChart
                      legends={newLegends}
                      thresholdAPIData={thresholdAPIData}
                      thresholdIDMapping={thresholdIDMapping}
                    />
                  </Col>
                  <Col span={7} className="lead-lag-section">
                    <Row className="lead-lag-wrapper">
                      <LeadingLaggingUI
                        title="Leading Units"
                        api_data={leadingUnits}
                        onRadioChange={onLeadingUnitsRadioValueChange}
                        radioValue={leadingRadioSelection}
                        loader={leading_lagging_loader}
                        info_content={
                          <div>
                            This table shows the top performing Segment/BU/Sub
                            BU/Sites based on selected filters
                            <br />* Indicates partial data available.
                          </div>
                        }
                      />
                      <LeadingLaggingUI
                        title="Lagging Units"
                        api_data={laggingUnits}
                        onRadioChange={onLaggingUnitsRadioValueChange}
                        radioValue={laggingRadioSelection}
                        loader={leading_lagging_loader}
                        info_content={
                          <div>
                            This table shows the bottom performing
                            Segment/BU/Sub BU/Sites based on selected filters
                            <br />* Indicates partial data available.
                          </div>
                        }
                      />
                    </Row>
                  </Col>
                  <Modal
                    width={"fit-content"}
                    title="Legend Guide"
                    visible={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer=""
                  >
                    <ContentofLegend data={legendInfoTableData} />
                  </Modal>
                </Row>
              </Content>
            </Layout>
          </Layout>
        </div>
      </Loader>
    </Wrapper>
  );
}

const ContentofLegend = (data) => {
  const columns = [
    {
      title: "Adherence Percentage",
      dataIndex: "percentage",
      render: (text, record) => {
        return (
          <div>
            {record.lower_threshold + " - " + record.upper_threshold + "%"}
          </div>
        );
      },
    },
    {
      title: "Color Code",
      dataIndex: "color_codes",
      render(text) {
        return {
          props: {
            style: { background: text },
          },
          children: <div></div>,
        };
      },
    },
  ];

  return (
    <div className="legendInfo">
      <span className="info-container">
        Process Adherence Percentage is calculated as acummulative assessment of
        each compliant Material/GSL over total materials/GSL approved to be
        received at the Mars Site
      </span>
      <div className="info-container">
        % Process Adherence = (Sum of Adhering and Partially Adhering
        entries/Total entries (Adhering + Partially Adhering + Non Adhering + No
        Data))
      </div>
      <Table
        className="info-table"
        columns={columns}
        dataSource={data.data}
        size="small"
        pagination={false}
      />
    </div>
  );
};
export default withRouter(CorporateAssessment);
