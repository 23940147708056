import React from "react";
import { useSelector } from "react-redux";

const SiteElementColumns = () => {
  const { selectedView } = useSelector((state) => state.homeView);

  const renderTitle = () => {
    if (selectedView === "Raw") {
      return "Material Risk Assessment (MRA) Status";
    } else {
      return "Packaging Risk Assessment (PRA) Status";
    }
  };

  const renderValuesFunction = (val, rec) => {
    if (val.count !== "NA") {
      return (
        <>
          <div className="abs-value">{val.count}</div>
          <div className="percent-value">{val.percent}</div>
        </>
      );
    } else {
      return (
        <>
          <div>-</div>
        </>
      );
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "self_name",
      key: "self_name",
      width: "14vw",
      sorter: (a, b) => {
        if (a.self_name.toLowerCase() !== b.self_name.toLowerCase()) {
          if (a.self_name.toLowerCase() < b.self_name.toLowerCase()) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      },
    },
    {
      title: "Inbound Verification Plan Readiness",
      children: [
        {
          title: "Adhering",
          dataIndex: "ibv_status_approved",
          key: "ibv_status_approved",
          render: renderValuesFunction,
        },
        {
          title: "Non-Adhering",
          dataIndex: "ibv_status_not_approved",
          key: "ibv_status_not_approved",
          render: renderValuesFunction,
        },
        {
          title: "No Data",
          dataIndex: "ibv_status_no_data",
          key: "ibv_status_no_data",
          render: renderValuesFunction,
        },
      ],
    },
    {
      title: `${renderTitle()}`,
      children: [
        {
          title: "Adhering",
          dataIndex: "mra_pra_status_approved",
          key: "mra_pra_status_approved",
          render: renderValuesFunction,
        },
        {
          title: "Non-Adhering",
          dataIndex: "mra_pra_status_not_approved",
          key: "mra_pra_status_not_approved",
          render: renderValuesFunction,
        },
        {
          title: "No Data",
          dataIndex: "mra_pra_status_no_data",
          key: "mra_pra_status_no_data",
          render: renderValuesFunction,
        },
      ],
    },
    {
      title: "Specification Status",
      children: [
        {
          title: "Adhering",
          dataIndex: "spec_status_approved",
          key: "spec_status_approved",
          render: renderValuesFunction,
        },
        {
          title: "Non-Adhering",
          dataIndex: "spec_status_not_approved",
          key: "spec_status_not_approved",
          render: renderValuesFunction,
        },
        {
          title: "No Data",
          dataIndex: "spec_status_no_data",
          key: "spec_status_no_data",
          render: renderValuesFunction,
        },
      ],
    },
    {
      title: "Last Supplier Audit Outcome",
      children: [
        {
          title: "Adhering",
          dataIndex: "supplier_last_audit_outcome_approved",
          key: "supplier_last_audit_outcome_approved",
          render: renderValuesFunction,
        },
        {
          title: "Non-Adhering",
          dataIndex: "supplier_last_audit_outcome_not_approved",
          key: "supplier_last_audit_outcome_not_approved",
          render: renderValuesFunction,
        },
        {
          title: "No Data",
          dataIndex: "supplier_last_audit_outcome_no_data",
          key: "supplier_last_audit_outcome_no_data",
          render: renderValuesFunction,
        },
      ],
    },
    {
      title: "Supplier Status",
      children: [
        {
          title: "Adhering",
          dataIndex: "supplier_status_approved",
          key: "supplier_status_approved",
          render: renderValuesFunction,
        },
        {
          title: "Non-Adhering",
          dataIndex: "supplier_status_not_approved",
          key: "supplier_status_not_approved",
          render: renderValuesFunction,
        },
        {
          title: "No Data",
          dataIndex: "supplier_status_no_data",
          key: "supplier_status_no_data",
          render: renderValuesFunction,
        },
      ],
    },
  ];

  return columns;
};

export default SiteElementColumns;
