import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Spin } from "antd";
import App from "../../app";
import { msalInstance, request } from "./msal-config";
import { setAppRole } from "../../slicers/manage-access-slicer";
import RequestAccess from "../../pages/request-access/index";
import PageNotFound from "../../pages/page-not-found";

export const DEVMODE = true;
const existing_routes = [
  "/",
  "/home",
  "/trending-view",
  "/map-view",
  "/assessment",
  "/summary-view",
  "/admin",
  "/approver",
  "/request-access",
  "/faqs",
];
function CheckAuthorization() {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [notAuth, setnotAuth] = useState(false);
  const [userRole, setUserRole] = useState([]);
  const [showNotFoundPage, setShowNotFoundPage] = useState(false);

  function componentToRender() {
    if (loading) {
      return (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)",
          }}
        >
          <Spin />
        </div>
      );
    } else if (notAuth) {
      return <RequestAccess />;
    } else if (userRole && userRole.length) {
      return <App />;
    } else if (showNotFoundPage) {
      return "";
    } else {
      return "";
    }
  }

  useEffect(() => {
    let current_path = window.location.pathname;
    if (existing_routes.indexOf(current_path) == -1) {
      setShowNotFoundPage(true);
    } else if (
      existing_routes.indexOf(current_path) != -1 &&
      (current_path !== "/home" || current_path !== "/")
    ) {
      setloading(true);
      msalInstance
        .handleRedirectPromise()
        .then((tokenResponse) => {
          if (!tokenResponse) {
            /*when token response is empty check if user is already logged in
          if no then redirect to login page*/
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
              msalInstance.loginRedirect();
              if (window.location.pathname != "/") window.location.replace("/");
              setShowNotFoundPage(false);
            } else {
              setShowNotFoundPage(false);
              /*If user is already logged in refresh the token*/
              sessionStorage.setItem("email", accounts[0].username);
              const param = { ...request, ...{ account: {} } };
              msalInstance.acquireTokenSilent(param).then((res) => {
                if (res && res.accessToken)
                  sessionStorage.setItem("token", res.accessToken);
                getAuthorization(res.idTokenClaims);
              });
            }
          } else {
            /*when token response is not empty*/
            sessionStorage.setItem("email", tokenResponse.account.username);
            getAuthorization(tokenResponse.idTokenClaims);
          }
        })
        .catch((err) => {
          console.error("Route configuration", err);
        })
        .finally(() => {
          setloading(false);
        });
    }
  }, []);

  async function getAuthorization(msalAccount) {
    setloading(false);
    if (msalAccount.roles) {
      setnotAuth(false);
      setUserRole(msalAccount.roles);
      const rolesArray = msalAccount.roles;
      if (
        rolesArray.includes("Super.Admin") ||
        rolesArray.includes("Super-Admin")
      ) {
        dispatch(setAppRole("Super.Admin"));
      } else if (rolesArray.includes("Approvers")) {
        dispatch(setAppRole("Approvers"));
      } else if (rolesArray.includes("Users")) {
        dispatch(setAppRole("Users"));
      }
      sessionStorage.setItem("roles", msalAccount.roles);
      sessionStorage.setItem("oid", msalAccount.oid);
      sessionStorage.setItem("name", msalAccount.name);
    } else {
      setnotAuth(true);
      sessionStorage.setItem("oid", msalAccount.oid);
      sessionStorage.setItem("name", msalAccount.name);
    }
    setloading(false);
  }

  return (
    <>
      {showNotFoundPage ? <PageNotFound /> : ""}
      {componentToRender()}
    </>
  );
}

export default CheckAuthorization;
