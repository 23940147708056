import { createSlice } from "@reduxjs/toolkit";
import CustomAxios from "../utilities/service/api-service";
import { Base_URL, getOverallPercentage } from "../utilities/constants";
import CheckAuthorization from "../utilities/auth/check-authorization";
import { showErrorMessage } from "../utilities/helper";
const initialState = {
  selectedView: "",
  homePageLoader: false,
  overallPercentRaw: "",
  overallPercentPack: "",
};

const homeViewSlicer = createSlice({
  name: "homeView",
  initialState,
  reducers: {
    setSelectedView: (state, action) => ({
      ...state,
      selectedView: action.payload,
    }),
    setHomePageLoader: (state, action) => ({
      ...state,
      homePageLoader: action.payload,
    }),
    setOverallPercentRaw: (state, action) => ({
      ...state,
      overallPercentRaw: action.payload,
    }),
    setOverallPercentPack: (state, action) => ({
      ...state,
      overallPercentPack: action.payload,
    }),
  },
});

export const {
  setSelectedView,
  setHomePageLoader,
  setOverallPercentRaw,
  setOverallPercentPack,
} = homeViewSlicer.actions;

export const getHomeOverallPerc = () => async (dispatch, getState) => {
  dispatch(setHomePageLoader(true));
  CustomAxios(Base_URL + getOverallPercentage, "GET")
    .then((response) => {
      let responseArray = response.data;
      responseArray.map((info) => {
        if (info.type === "raw") {
          dispatch(setOverallPercentRaw(info.compliance_percent));
        } else if (info.type === "packaging") {
          dispatch(setOverallPercentPack(info.compliance_percent));
        }
      });
    })
    .catch((err) => {
      if (err.status === 403) {
        CheckAuthorization();
      }
      console.error("Home Page API Failed", err);
      showErrorMessage();
    })
    .finally(() => {
      dispatch(setHomePageLoader(false));
    });
};

export default homeViewSlicer.reducer;
