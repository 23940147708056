import { createSlice } from "@reduxjs/toolkit";
import CustomAxios from "../utilities/service/api-service";
import CheckAuthorization from "../utilities/auth/check-authorization";
import {
  left_section_filters,
  Base_URL,
  get_oos_flags,
  material_assessment_dropdowns,
} from "../utilities/constants";

const initialState = {
  loading: 0,
  dropdownLoading: 0,
  loadingExportData: 0,
  data_filters: {},
  filters: {
    site_segment: [],
    division: [],
    site_business_unit: [],
    // site_sub_business_unit: [],
    site_unit_type: [],
    site_unit_name: [],
    supplier_name: [],
    material_description: [],
    overall_compliance: [],
  },
  appliedFilters: {
    site_segment: [],
    division: [],
    site_business_unit: [],
    // site_sub_business_unit: [],
    site_unit_type: [],
    site_unit_name: [],
    supplier_name: [],
    material_description: [],
    overall_compliance: [],
  },
  site_segment: [],
  division: [],
  site_business_unit: [],
  // site_sub_business_unit: [],
  site_unit_type: [],
  site_unit_name: [],
  isSiteSelected: false,
  applyFilter: true,
  selected_SiteCode: "",
  is_out_of_scope_selected: false,
  oos_sites_list: [],
  supplier_name: [],
  material_description: [],
  overall_compliance: [],
  oos_data_filters: {},
  oos_filters: {
    site_segment: [],
    division: [],
    site_business_unit: [],
    // site_sub_business_unit: [],
    site_unit_type: [],
    site_unit_name: [],
    supplier_name: [],
    material_description: [],
    overall_compliance: [],
  },
  oos_appliedFilters: {
    site_segment: [],
    division: [],
    site_business_unit: [],
    // site_sub_business_unit: [],
    site_unit_type: [],
    site_unit_name: [],
    supplier_name: [],
    material_description: [],
    overall_compliance: [],
  },
  site_segment_oos: [],
  division_oos: [],
  site_business_unit_oos: [],
  // site_sub_business_unit_oos: [],
  site_unit_type_oos: [],
  site_unit_name_oos: [],
  supplier_name_oos: [],
  material_description_oos: [],
  overall_compliance_oos: [],
  last_selected_filter_name: "",
  last_selected_filter_val: [],
  oos_last_selected_filter_name: "",
  oos_last_selected_filter_val: [],
};

const sidebarFiltersSlicer = createSlice({
  name: "sidebarFilters",
  initialState,
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      loading: state.loading + (action.payload ? 1 : -1),
    }),
    setDropdownLoading: (state, action) => ({
      ...state,
      dropdownLoading: state.dropdownLoading + (action.payload ? 1 : -1),
    }),
    setExportDataLoading: (state, action) => ({
      ...state,
      loadingExportData: state.loadingExportData + (action.payload ? 1 : -1),
    }),
    setDataFilters: (state, action) => ({
      ...state,
      data_filters: action.payload,
    }),
    setFilters: (state, action) => ({
      ...state,
      filters: action.payload,
    }),
    setAppliedFilters: (state, action) => ({
      ...state,
      appliedFilters: action.payload,
    }),
    setApplyFilter: (state, action) => ({
      ...state,
      applyFilter: action.payload,
    }),
    setDataDropdown: (state, action) => ({
      //sets otions for individual fields
      ...state,
      [action.payload[0]]: action.payload[1],
    }),
    setOutOfScopeValue: (state, action) => ({
      ...state,
      is_out_of_scope_selected: action.payload,
    }),
    setOOSSitesList: (state, action) => ({
      ...state,
      oos_sites_list: action.payload,
    }),
    //oos
    setOOSDataFilters: (state, action) => ({
      ...state,
      oos_data_filters: action.payload,
    }),
    setOOSFilters: (state, action) => ({
      ...state,
      oos_filters: action.payload,
    }),
    setOOSAppliedFilters: (state, action) => ({
      ...state,
      oos_appliedFilters: action.payload,
    }),
    setOOSDataDropdown: (state, action) => ({
      //sets options for individual fields
      ...state,
      [action.payload[0]]: action.payload[1],
    }),
    setLastSelectedFilter: (state, action) => ({
      ...state,
      last_selected_filter_name: action.payload.name,
      last_selected_filter_val: action.payload.val,
    }),
    setOOSLastSelectedFilter: (state, action) => ({
      ...state,
      oos_last_selected_filter_name: action.payload.name,
      oos_last_selected_filter_val: action.payload.val,
    }),
  },
});

export const {
  setLoading,
  setDropdownLoading,
  setDataFilters,
  setFilters,
  setAppliedFilters,
  setApplyFilter,
  setDataDropdown,
  setOutOfScopeValue,
  is_out_of_scope_selected,
  setOOSSitesList,
  setOOSDataFilters,
  setOOSFilters,
  setOOSAppliedFilters,
  setOOSDataDropdown,
  setLastSelectedFilter,
  setOOSLastSelectedFilter,
} = sidebarFiltersSlicer.actions;

export const getLeftSectionFilters =
  (module, filters, oos_flag, keyName = "") =>
  async (dispatch, getState) => {
    let urlFilters = { ...filters };
    Object.keys(urlFilters).forEach((key) => {
      if (urlFilters[key].length === 0) {
        delete urlFilters[key];
      }
    });
    dispatch(setDropdownLoading(true));
    CustomAxios(
      Base_URL +
        left_section_filters +
        "?only_oos=" +
        oos_flag +
        "&module=" +
        module,
      "POST",
      urlFilters
    )
      .then((response) => {
        if (response.data.error_code === undefined) {
          let data_filters = response.data;

          /**relevant filter selection start */

          if (Object.entries(urlFilters).length != 0 && keyName) {
            let filterResponse = Object.assign({}, response.data);
            let new_value = urlFilters[keyName] || [];

            filterResponse[keyName] = new_value;
            if (oos_flag) {
              let temp = filterResponse.division.filter(
                (d) => d !== "Food N/A"
              );
              // console.log(temp, "?????????????????????????");
              let newData = Object.assign({}, filterResponse);
              newData.division = temp;
              dispatch(setOOSFilters(newData));
            } else {
              let temp = filterResponse.division.filter(
                (d) => d !== "Food N/A"
              );

              console.log(temp, "?????????????????????????");
              let newData = Object.assign({}, filterResponse);
              newData.division = temp;
              dispatch(setFilters(newData));
            }
          }
          /**relevant filter selection  end */

          if (oos_flag) {
            dispatch(setOOSDataFilters(data_filters));
          } else {
            dispatch(setDataFilters(data_filters));
          }

          data_filters.site_business_unit &&
            data_filters.site_business_unit.length &&
            dispatch(
              oos_flag
                ? setOOSDataDropdown([
                    "site_business_unit_oos",
                    data_filters.site_business_unit,
                  ])
                : setDataDropdown([
                    "site_business_unit",
                    data_filters.site_business_unit,
                  ])
            );
          data_filters.site_segment &&
            data_filters.site_segment.length &&
            dispatch(
              oos_flag
                ? setOOSDataDropdown([
                    "site_segment_oos",
                    data_filters.site_segment,
                  ])
                : setDataDropdown(["site_segment", data_filters.site_segment])
            );
          // data_filters.division &&
          //   data_filters.division.length &&
          //   dispatch(
          //     oos_flag
          //       ? setOOSDataDropdown(["division_oos", data_filters.division])
          //       : setDataDropdown(["division", data_filters.division])
          //   );
          if (data_filters.division && data_filters.division.length) {
            let temp = data_filters.division.filter((d) => d !== "Food N/A");
            dispatch(
              oos_flag
                ? setOOSDataDropdown(["division_oos", temp])
                : setDataDropdown(["division", temp])
            );
          }
          // data_filters.site_sub_business_unit &&
          //   data_filters.site_sub_business_unit.length &&
          //   dispatch(
          //     oos_flag
          //       ? setOOSDataDropdown([
          //           "site_sub_business_unit_oos",
          //           data_filters.site_sub_business_unit,
          //         ])
          //       : setDataDropdown([
          //           "site_sub_business_unit",
          //           data_filters.site_sub_business_unit,
          //         ])
          //   );
          data_filters.site_unit_name &&
            data_filters.site_unit_name.length &&
            dispatch(
              oos_flag
                ? setOOSDataDropdown([
                    "site_unit_name_oos",
                    data_filters.site_unit_name,
                  ])
                : setDataDropdown([
                    "site_unit_name",
                    data_filters.site_unit_name,
                  ])
            );
          data_filters.site_unit_type &&
            data_filters.site_unit_type.length &&
            dispatch(
              oos_flag
                ? setOOSDataDropdown([
                    "site_unit_type_oos",
                    data_filters.site_unit_type,
                  ])
                : setDataDropdown([
                    "site_unit_type",
                    data_filters.site_unit_type,
                  ])
            );
          data_filters.supplier_name &&
            data_filters.supplier_name.length &&
            dispatch(
              oos_flag
                ? setOOSDataDropdown([
                    "supplier_name_oos",
                    data_filters.supplier_name,
                  ])
                : setDataDropdown(["supplier_name", data_filters.supplier_name])
            );
          data_filters.material_description &&
            data_filters.material_description.length &&
            dispatch(
              oos_flag
                ? setOOSDataDropdown([
                    "material_description_oos",
                    data_filters.material_description,
                  ])
                : setDataDropdown([
                    "material_description",
                    data_filters.material_description,
                  ])
            );
          data_filters.overall_compliance &&
            data_filters.overall_compliance.length &&
            dispatch(
              oos_flag
                ? setOOSDataDropdown([
                    "overall_compliance_oos",
                    data_filters.overall_compliance,
                  ])
                : setDataDropdown([
                    "overall_compliance",
                    data_filters.overall_compliance,
                  ])
            );
        }

        dispatch(setDropdownLoading(false));
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error("Left Section Filters Dropdown API Failed", err);
        dispatch(setDropdownLoading(false));
      });
  };

export const getSelectedSiteOOSStatus =
  (filters) => async (dispatch, getState) => {
    CustomAxios(Base_URL + get_oos_flags, "POST", filters)
      .then((response) => {
        if (response.data.error_code === undefined) {
          let obj = response.data.oos;
          let filteredObj = [];
          Object.keys(obj).forEach((c) => {
            if (obj[c] == true) filteredObj.push(c);
          }, {});

          dispatch(setOOSSitesList(filteredObj));
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error("Out of Scope API Failed", err);
        dispatch(setOOSSitesList([]));
      });
  };

export const getMaterialLeftSectionFilters =
  (material_filters, oos_flag, keyName) => async (dispatch, getState) => {
    let urlFilters = { ...material_filters };
    Object.keys(urlFilters).forEach((key) => {
      if (urlFilters[key].length === 0) {
        delete urlFilters[key];
      }
    });

    dispatch(setDropdownLoading(true));

    CustomAxios(
      Base_URL + material_assessment_dropdowns + "?only_oos=" + oos_flag,
      "POST",
      urlFilters
    )
      .then((response) => {
        if (response.data.error_code === undefined) {
          let data_filters = response.data;
          /**relevant filter selection start */
          if (Object.entries(urlFilters).length != 0 && keyName) {
            let filterResponse = Object.assign({}, response.data);
            let new_value = urlFilters[keyName] || [];

            filterResponse[keyName] = new_value;
            dispatch(setFilters(filterResponse));
          }
          /**relevant filter selection  end */
          dispatch(setDataFilters(data_filters));
          dispatch(setDataDropdown(data_filters));
          data_filters.supplier_id &&
            data_filters.supplier_id.length &&
            dispatch(
              setDataDropdown(["supplier_id", data_filters.supplier_id])
            );
          data_filters.material_id &&
            data_filters.material_id.length &&
            dispatch(
              setDataDropdown(["material_id", data_filters.material_id])
            );
          data_filters.overall_compliance &&
            data_filters.overall_compliance.length &&
            dispatch(
              setDataDropdown([
                "overall_compliance",
                data_filters.overall_compliance,
              ])
            );
        }
        dispatch(setDropdownLoading(false));
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error("Left Section Filters Dropdown API Failed", err);
        dispatch(setDropdownLoading(false));
      });
  };

export const getDropDownOptions =
  (module, filters, oos_flag, keyName = "") =>
  async (dispatch, getState) => {
    let urlFilters = { ...filters };
    Object.keys(urlFilters).forEach((key) => {
      if (key !== keyName) {
        delete urlFilters[key];
      } else if (urlFilters[key].length === 0) {
        delete urlFilters[key];
      }
    });
    if (Object.entries(urlFilters).length != 0) {
      dispatch(setDropdownLoading(true));
      CustomAxios(
        Base_URL +
          left_section_filters +
          "?only_oos=" +
          oos_flag +
          "&module=" +
          module,
        "POST",
        urlFilters
      )
        .then((response) => {
          if (response.data.error_code === undefined) {
            let data_filters = response.data;

            /**relevant filter selection start */
            if (Object.entries(urlFilters).length != 0 && keyName) {
              let filterResponse = Object.assign({}, response.data);
              let new_value = urlFilters[keyName] || [];

              filterResponse[keyName] = new_value;
              if (oos_flag) {
                let temp = filterResponse.division.filter(
                  (d) => d !== "Food N/A"
                );
                let newData = Object.assign({}, filterResponse);
                newData.division = temp;
                dispatch(setOOSFilters(newData));
              } else {
                let temp = filterResponse.division.filter(
                  (d) => d !== "Food N/A"
                );
                let newData = Object.assign({}, filterResponse);
                newData.division = temp;
                dispatch(setFilters(newData));
              }
            }
            /**relevant filter selection  end */

            if (oos_flag) {
              dispatch(setOOSDataFilters(data_filters));
            } else {
              dispatch(setDataFilters(data_filters));
            }

            data_filters.site_business_unit &&
              data_filters.site_business_unit.length &&
              dispatch(
                oos_flag
                  ? setOOSDataDropdown([
                      "site_business_unit_oos",
                      data_filters.site_business_unit,
                    ])
                  : setDataDropdown([
                      "site_business_unit",
                      data_filters.site_business_unit,
                    ])
              );
            data_filters.site_segment &&
              data_filters.site_segment.length &&
              dispatch(
                oos_flag
                  ? setOOSDataDropdown([
                      "site_segment_oos",
                      data_filters.site_segment,
                    ])
                  : setDataDropdown(["site_segment", data_filters.site_segment])
              );
            if (data_filters.division && data_filters.division.length) {
              let temp = data_filters.division.filter((d) => d !== "Food N/A");
              dispatch(
                oos_flag
                  ? setOOSDataDropdown(["division_oos", temp])
                  : setDataDropdown(["division", temp])
              );
            }
            // data_filters.site_sub_business_unit &&
            //   data_filters.site_sub_business_unit.length &&
            //   dispatch(
            //     oos_flag
            //       ? setOOSDataDropdown([
            //           "site_sub_business_unit_oos",
            //           data_filters.site_sub_business_unit,
            //         ])
            //       : setDataDropdown([
            //           "site_sub_business_unit",
            //           data_filters.site_sub_business_unit,
            //         ])
            //   );
            data_filters.site_unit_name &&
              data_filters.site_unit_name.length &&
              dispatch(
                oos_flag
                  ? setOOSDataDropdown([
                      "site_unit_name_oos",
                      data_filters.site_unit_name,
                    ])
                  : setDataDropdown([
                      "site_unit_name",
                      data_filters.site_unit_name,
                    ])
              );
            data_filters.site_unit_type &&
              data_filters.site_unit_type.length &&
              dispatch(
                oos_flag
                  ? setOOSDataDropdown([
                      "site_unit_type_oos",
                      data_filters.site_unit_type,
                    ])
                  : setDataDropdown([
                      "site_unit_type",
                      data_filters.site_unit_type,
                    ])
              );
            data_filters.supplier_name &&
              data_filters.supplier_name.length &&
              dispatch(
                oos_flag
                  ? setOOSDataDropdown([
                      "supplier_name_oos",
                      data_filters.supplier_name,
                    ])
                  : setDataDropdown([
                      "supplier_name",
                      data_filters.supplier_name,
                    ])
              );
            data_filters.material_description &&
              data_filters.material_description.length &&
              dispatch(
                oos_flag
                  ? setOOSDataDropdown([
                      "material_description_oos",
                      data_filters.material_description,
                    ])
                  : setDataDropdown([
                      "material_description",
                      data_filters.material_description,
                    ])
              );
            data_filters.overall_compliance &&
              data_filters.overall_compliance.length &&
              dispatch(
                oos_flag
                  ? setOOSDataDropdown([
                      "overall_compliance_oos",
                      data_filters.overall_compliance,
                    ])
                  : setDataDropdown([
                      "overall_compliance",
                      data_filters.overall_compliance,
                    ])
              );
          }

          dispatch(setDropdownLoading(false));
        })
        .catch((err) => {
          if (err.status === 403) {
            CheckAuthorization();
          }
          console.error("Left Section Filters Dropdown API Failed", err);
          dispatch(setDropdownLoading(false));
        });
    }
  };
export default sidebarFiltersSlicer.reducer;
