import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Layout, Row, Col, Radio, Switch, Button } from "antd";
import Wrapper from "../../components/wrapper";
import Loader from "../../components/loader";
import "./siteassessment.scss";
import {
  getSiteLocationBarchartData,
  getSiteTableData,
  getOverallTableData,
  getOverallAdherenceDataDownload,
  getRequirementViewDataDownload,
} from "../../slicers/site-assessment-slicer";
import { getLeadingLaggingData } from "../../slicers/corporate-assessment-slicer";
import { getLeftSectionFilters } from "../../slicers/sidebar-filters-slicer";
import { setSelectedView } from "../../slicers/home-slicer";
import SiteLocationTable from "./site-location-drilldown/site-location-table";
import SidebarFilters from "../filter-panel/filters";
import FiltersApplied from "../filter-panel/filter-applied-section";
import BarChart from "./bar-chart";
import RefreshFailureAlert from "../../components/alerts/refresh-failure";
import SiteElementTable from "./site-element-drilldown/site-element-table";
import { DownloadOutlined } from "@ant-design/icons";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RadioButtons from "../../components/raw-pack-radio-btns/radioButtons";

const { Sider, Content } = Layout;
const items = [
  {
    label: `Overall Adherence Summary`,
    key: 1,
    children: `Content of tab Location`,
  },
  {
    label: `Requirement View Summary`,
    key: 1,
    children: `Content of tab Element`,
  },
];

function SiteAssessment() {
  const [dataSegments, setDataSegments] = useState([]);
  const [dataSegDeSelect, setDataSegDeSelect] = useState([]);
  const [storedSegments, setStoredSegments] = useState(false);

  const [dataDivisions, setDataDivisions] = useState([]);
  const [dataDivisionDeSelect, setDataDivisionDeSelect] = useState([]);
  const [allDataDivs, setAllDataDivs] = useState([]);
  const [allDataDivDeSelect, setAllDataDivDeSelect] = useState([]);
  const [storedDivisions, setStoredDivisions] = useState(false);
  const [storedAllDivs, setStoredAllDivs] = useState(false);

  const [dataBUs, setDataBUs] = useState([]);
  const [dataBUDeSelect, setDataBUDeSelect] = useState([]);
  const [storedBUs, setStoredBUs] = useState(false);

  const [dataSites, setDataSites] = useState([]);

  const [page, setPage] = useState("Overall Adherence Summary");
  const pageOptions = ["Overall Adherence Summary", "Requirement View Summary"];
  const [switchOn, setSwitchOn] = useState(false);
  const [switchState, setSwitchState] = useState("chart");
  const [radioSelection, setRadioSelection] = useState("segment");
  const [locationRadioSelection, setLocationRadioSelection] =
    useState("segment");
  const [tableData, setTableData] = useState([]);
  const [overAllTable, setOverallTable] = useState([]);
  const { last_Refresh_date, currentDateIST, actualDateIST, alertRefreshDate } =
    useSelector((state) => state.corporateAssessment);
  const {
    siteLocationTableRadio,
    segment_bars,
    division_bars,
    bu_bars,
    site_bars,
    dropdownLoading,
    siteTableRadio,
  } = useSelector((state) => state.siteAssessment);

  const {
    appliedFilters,
    is_out_of_scope_selected,
    oos_sites_list,
    oos_appliedFilters,
    last_selected_filter_name,
    last_selected_filter_val,
    oos_last_selected_filter_name,
    oos_last_selected_filter_val,
  } = useSelector((state) => state.sidebarFilters);
  const { selectedView } = useSelector((state) => state.homeView);
  const dispatch = useDispatch();
  useEffect(() => {
    let tempView = selectedView;
    if (
      selectedView === "" ||
      selectedView === "home" ||
      selectedView === undefined
    ) {
      dispatch(setSelectedView("Raw"));
    } else {
      dispatch(setSelectedView(tempView));
    }
  }, []);
  useEffect(() => {
    if (selectedView === "Raw" || selectedView === "Packaging") {
      if (Object.entries(appliedFilters).length) {
        let currentFilters;
        //change payload
        if (is_out_of_scope_selected) {
          currentFilters = {
            [oos_last_selected_filter_name]: oos_last_selected_filter_val,
          };
        } else {
          currentFilters = {
            [last_selected_filter_name]: last_selected_filter_val,
          };
        }
        dispatch(
          getLeftSectionFilters(
            "corpAssessment",
            currentFilters,
            is_out_of_scope_selected
          )
        );
        dispatch(
          getSiteLocationBarchartData(
            currentFilters,
            is_out_of_scope_selected,
            selectedView
          )
        );
        dispatch(getLeadingLaggingData(currentFilters, false, selectedView));
        dispatch(
          getSiteTableData(
            currentFilters,
            is_out_of_scope_selected,
            selectedView
          )
        );
        dispatch(
          getOverallTableData(
            currentFilters,
            is_out_of_scope_selected,
            selectedView
          )
        );
      } else {
        dispatch(getLeftSectionFilters("corpAssessment", {}));
        dispatch(
          getSiteLocationBarchartData(
            {},
            is_out_of_scope_selected,
            selectedView
          )
        );
        dispatch(getLeadingLaggingData({}, false, selectedView));
        dispatch(getSiteTableData({}, is_out_of_scope_selected, selectedView));
        dispatch(
          getOverallTableData({}, is_out_of_scope_selected, selectedView)
        );
      }
    }
  }, [selectedView]);
  let onRadioChange = (e) => {
    setRadioSelection(e.target.value);
  };
  let onLocationRadioChange = (e) => {
    setLocationRadioSelection(e.target.value);
  };
  useEffect(() => {
    if (siteTableRadio && radioSelection != "" && radioSelection != undefined) {
      let data;
      if (radioSelection === "bu") {
        data = siteTableRadio.business_units;
      } else if (radioSelection === "sub_bu") {
        data = siteTableRadio.sub_business_units;
      } else if (radioSelection === "site") {
        data = siteTableRadio.sites;
      } else if (radioSelection === "divisions") {
        data = siteTableRadio.divisions || [];
      } else {
        data = siteTableRadio.segments;
      }

      setTableData(data);
    }
  }, [radioSelection, siteTableRadio]);
  useEffect(() => {
    if (
      siteLocationTableRadio &&
      locationRadioSelection != "" &&
      locationRadioSelection != undefined
    ) {
      let data;
      if (locationRadioSelection === "bu") {
        data = siteLocationTableRadio.business_units;
      } else if (locationRadioSelection === "sub_bu") {
        data = siteLocationTableRadio.sub_business_units;
      } else if (locationRadioSelection === "site") {
        data = siteLocationTableRadio.sites;
      } else if (locationRadioSelection === "divisions") {
        let noDivData = [];
        siteLocationTableRadio.divisions.map((item) => {
          if (item.self_name !== "Food N/A") {
            noDivData.push(item);
          }
        });
        data = noDivData || [];
      } else {
        data = siteLocationTableRadio.segments;
      }

      setOverallTable(data);
    }
  }, [locationRadioSelection, siteLocationTableRadio]);
  useEffect(() => {
    setDataSegments(
      segment_bars.map((segment) => ({ ...segment, selected: false }))
    );
    setDataSegDeSelect(
      segment_bars.map((segment) => ({ ...segment, selected: true }))
    );
    setStoredSegments(false);
  }, [segment_bars]);

  useEffect(() => {
    /*Temp code: API logic*/
    if (dataSegments.length) {
      if (dataSegments.filter(({ selected }) => selected).length === 0) {
        const newDivs = [];
        const allDivs = [];
        const divInStore = JSON.parse(JSON.stringify(division_bars));
        // const newBUs = [];
        // const buInStore = JSON.parse(JSON.stringify(bu_bars));
        dataSegDeSelect
          .filter(({ selected }) => selected)
          .forEach((segment) => {
            divInStore &&
              divInStore[segment.name] &&
              divInStore[segment.name].length &&
              divInStore[segment.name].forEach((data) => {
                const index = newDivs.findIndex(
                  (newRegion) => newRegion.name === data.name
                );
                if (index === -1) allDivs.push(data);
                else allDivs[index].value += data.value;
                if (data.name !== "Food N/A") {
                  if (index === -1) newDivs.push(data);
                  else newDivs[index].value += data.value;
                }
              });
          });
        /*Temp code: API logic*/
        // setDataBUs(newDivs.map((BU) => ({ ...BU, selected: false })));
        // setDataBUDeSelect(newDivs.map((BU) => ({ ...BU, selected: true })));
        // setStoredBUs(false);
        setAllDataDivs(allDivs.map((div) => ({ ...div, selected: false })));
        setAllDataDivDeSelect(
          allDivs.map((div) => ({ ...div, selected: true }))
        );
        setStoredAllDivs(false);
        setDataDivisions(newDivs.map((DIV) => ({ ...DIV, selected: false })));
        setDataDivisionDeSelect(
          newDivs.map((DIV) => ({ ...DIV, selected: true }))
        );
        setStoredDivisions(false);
      } else {
        const newDivs = [];
        const allDivs = [];
        const divInStore = JSON.parse(JSON.stringify(division_bars));

        dataSegments
          .filter(({ selected }) => selected)
          .forEach((segment) => {
            divInStore &&
              divInStore[segment.name] &&
              divInStore[segment.name].length &&
              divInStore[segment.name].forEach((data) => {
                const index = newDivs.findIndex(
                  (newRegion) => newRegion.name === data.name
                );
                if (index === -1) allDivs.push(data);
                else allDivs[index].value += data.value;
                if (data.name !== "Food N/A") {
                  if (index === -1) newDivs.push(data);
                  else newDivs[index].value += data.value;
                }
              });
          });
        /*Temp code: API logic*/
        setAllDataDivs(allDivs.map((div) => ({ ...div, selected: false })));
        setAllDataDivDeSelect(
          allDivs.map((div) => ({ ...div, selected: true }))
        );
        setStoredAllDivs(false);
        setDataDivisions(newDivs.map((DIV) => ({ ...DIV, selected: false })));
        setDataDivisionDeSelect(
          newDivs.map((DIV) => ({ ...DIV, selected: true }))
        );
        setStoredDivisions(false);
      }
    } else {
      setDataDivisions([]);
      setDataDivisionDeSelect([]);
      setStoredDivisions(false);
      setAllDataDivs([]);
      setStoredAllDivs(false);
      setDataBUs([]);
      setDataBUDeSelect([]);
      setStoredBUs(false);
      setDataSites([]);
    }
  }, [dataSegments]);
  //old code to update the divisions
  // useEffect(() => {
  //   /*Temp code: API logic*/
  //   if (dataDivisions.filter(({ selected }) => selected).length === 0) {
  //     const newBUs = [];
  //     const busInStore = JSON.parse(JSON.stringify(bu_bars));
  //     dataDivisionDeSelect
  //       .filter(({ selected }) => selected)
  //       .forEach((data) => {
  //         busInStore &&
  //           busInStore[data.name] &&
  //           busInStore[data.name].length &&
  //           busInStore[data.name].forEach((site) => {
  //             const index = newBUs.findIndex(
  //               (newBU) => newBU.name === site.name
  //             );
  //             if (index === -1) newBUs.push(site);
  //             else newBUs[index].value += site.value;
  //           });
  //       });
  //     /*Temp code: API logic*/
  //     setDataBUs(newBUs.map((data) => ({ ...data, selected: false })));
  //     setDataBUDeSelect(newBUs.map((BU) => ({ ...BU, selected: true })));
  //     setStoredBUs(false);
  //   } else {
  //     const newBUs = [];
  //     const busInStore = JSON.parse(JSON.stringify(bu_bars));
  //     dataDivisions
  //       .filter(({ selected }) => selected)
  //       .forEach((data) => {
  //         busInStore &&
  //           busInStore[data.name] &&
  //           busInStore[data.name].length &&
  //           busInStore[data.name].forEach((site) => {
  //             const index = newBUs.findIndex(
  //               (newBU) => newBU.name === site.name
  //             );
  //             if (index === -1) newBUs.push(site);
  //             else newBUs[index].value += site.value;
  //           });
  //       });
  //     /*Temp code: API logic*/
  //     setDataBUs(newBUs.map((data) => ({ ...data, selected: false })));
  //     setDataBUDeSelect(newBUs.map((BU) => ({ ...BU, selected: true })));
  //     setStoredBUs(false);
  //   }
  // }, [dataDivisions]);

  useEffect(() => {
    if (
      allDataDivs.filter(({ selected }) => selected).length === 0 &&
      dataDivisions.filter(({ selected }) => selected).length === 0
    ) {
      const allBUs = [];
      const allBusInStore = JSON.parse(JSON.stringify(bu_bars));
      allDataDivDeSelect
        .filter(({ selected }) => selected)
        .filter((data) => {
          allBusInStore &&
            allBusInStore[data.name] &&
            allBusInStore[data.name].length &&
            allBusInStore[data.name].forEach((site) => {
              const index = allBUs.findIndex(
                (newBU) => newBU.name === site.name
              );
              if (index === -1) allBUs.push(site);
              else allBUs[index].value += site.value;
            });
          // }
        });
      /*Temp code: API logic*/
      setDataBUs(allBUs.map((data) => ({ ...data, selected: false })));
      setDataBUDeSelect(allBUs.map((BU) => ({ ...BU, selected: true })));
      setStoredBUs(false);
    } else {
      const allBUs = [];
      const allBusInStore = JSON.parse(JSON.stringify(bu_bars));
      dataDivisions
        .filter(({ selected }) => selected)
        .filter((data) => {
          allBusInStore &&
            allBusInStore[data.name] &&
            allBusInStore[data.name].length &&
            allBusInStore[data.name].forEach((site) => {
              const index = allBUs.findIndex(
                (newBU) => newBU.name === site.name
              );
              if (index === -1) allBUs.push(site);
              else allBUs[index].value += site.value;
            });
        });
      /*Temp code: API logic*/
      setDataBUs(allBUs.map((data) => ({ ...data, selected: false })));
      setDataBUDeSelect(allBUs.map((BU) => ({ ...BU, selected: true })));
      setStoredBUs(false);
    }
  }, [allDataDivDeSelect, dataDivisions]);

  useEffect(() => {
    /*Temp code: API logic*/
    if (dataBUs.filter(({ selected }) => selected).length === 0) {
      const newSites = [];
      const sitesInStore = JSON.parse(JSON.stringify(site_bars));

      dataBUDeSelect
        .filter(({ selected }) => selected)
        .forEach((data) => {
          sitesInStore &&
            sitesInStore[data.name] &&
            sitesInStore[data.name].length &&
            sitesInStore[data.name].forEach((site) => {
              const index = newSites.findIndex(
                (newSite) => newSite.name === site.name
              );
              if (index === -1) newSites.push(site);
              else newSites[index].value += site.value;
            });
        });
      /*Temp code: API logic*/
      setDataSites(newSites.map((data) => ({ ...data, selected: false })));
    } else {
      const newSites = [];
      const sitesInStore = JSON.parse(JSON.stringify(site_bars));
      // console.log(sitesInStore, "???????????????????");

      dataBUs
        .filter(({ selected }) => selected)
        .forEach((data) => {
          sitesInStore &&
            sitesInStore[data.name] &&
            sitesInStore[data.name].length &&
            sitesInStore[data.name].forEach((site) => {
              const index = newSites.findIndex(
                (newSite) => newSite.name === site.name
              );
              if (index === -1) newSites.push(site);
              else newSites[index].value += site.value;
            });
        });
      /*Temp code: API logic*/
      setDataSites(newSites.map((data) => ({ ...data, selected: false })));
    }
  }, [dataBUs]);

  useEffect(() => {
    if (site_bars.length == 0) {
      setDataSites([]);
    }
    if (bu_bars.length == 0) {
      setDataBUs([]);
    }
    if (division_bars.length == 0) {
      setDataDivisions([]);
      setAllDataDivs([]);
    }
  }, [site_bars, bu_bars, division_bars]); //site_bars,

  useEffect(() => {
    if (site_bars.length == 0) {
      setDataSites([]);
    }
    if (bu_bars.length == 0) {
      setDataBUs([]);
    }
    if (division_bars.length == 0) {
      setDataDivisions([]);
      setAllDataDivs([]);
    }
  }, [site_bars, bu_bars, division_bars]); //site_bars,

  const onRadioLevelChange = ({ target: { value } }) => {
    setPage(value);
  };

  const onSwitchChange = (e) => {
    setSwitchOn(!switchOn);
  };

  useEffect(() => {
    switchOn === false ? setSwitchState("chart") : setSwitchState("table");
  }, [switchOn]);

  return (
    <Wrapper heading={"2"}>
      <Loader
        loading={dropdownLoading > 0}
        tip={"Please wait while filters are loaded.."}
      >
        <div className="site_assessment">
          <Layout>
            <Sider className="sidebar">
              <SidebarFilters module="site_assessment_location" />
            </Sider>
            <Layout className="content_wrapper">
              <Content>
                <Row className="title_row" wrap={false}>
                  <Col span={18} align="left" className="radio-buttons-wrapper">
                    <RadioButtons />
                  </Col>
                  <Col span={6} className="last_updated_text" align="right">
                    <span>
                      {last_Refresh_date ? (
                        <div>
                          Daily Refresh
                          <br /> Last Refreshed Date is:{" "}
                          <span
                            className={
                              alertRefreshDate ? "alert-refresh-date" : ""
                            }
                          >
                            {last_Refresh_date}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                    {currentDateIST !== last_Refresh_date &&
                    actualDateIST !== last_Refresh_date ? (
                      <RefreshFailureAlert />
                    ) : (
                      <></>
                    )}
                  </Col>
                  <ToastContainer />
                </Row>
                <Col span={12} align="left">
                  <h2 className="page-title"> Summary View - {page} </h2>
                </Col>
                <Col span={24} align="left">
                  <Radio.Group
                    options={pageOptions}
                    onChange={onRadioLevelChange}
                    value={page}
                    className="loc-ele-view-radio"
                  />
                </Col>
                <div className="applied-filters-wrapper">
                  <FiltersApplied
                    appliedFilters={
                      is_out_of_scope_selected
                        ? oos_appliedFilters
                        : appliedFilters
                    }
                  />
                </div>

                {page === "Overall Adherence Summary" ? (
                  <div className="charts-table-switch">
                    <span className="text">Bar Charts</span>
                    <Switch
                      className="switchtotable"
                      checked={switchOn}
                      onChange={onSwitchChange}
                    />
                    <span className="text">Table</span>
                  </div>
                ) : (
                  ""
                )}
                {is_out_of_scope_selected && oos_sites_list.length > 0 ? (
                  <span className="oos-note" style={{ display: "block" }}>
                    *Note: You have selected out of scope site/s{" "}
                    {oos_sites_list.map((d, i) => (
                      <span key={d}>{d + ", "}</span>
                    ))}
                    in site name filter selection
                  </span>
                ) : (
                  ""
                )}
                {switchState === "chart" &&
                page === "Overall Adherence Summary" ? (
                  <Row className="barChartWrapper">
                    <Col span={24} className="note-for-table">
                      <span className="note-text">
                        *Note: The below bar charts show Segments/Business
                        Units/Sites at each level as per the selections in
                        charts
                      </span>
                    </Col>
                    <Col
                      span={6}
                      className="barChartContainer"
                      style={{ padding: " 0 3px 0px 0px" }}
                    >
                      <h4 className="title">Segments</h4>
                      <BarChart
                        data={dataSegments}
                        setData={setDataSegments}
                        stored={storedSegments}
                        setStored={setStoredSegments}
                        xField="value"
                        yField="name"
                        storageKey="barChartSegments"
                      />
                    </Col>
                    <Col
                      span={6}
                      className="barChartContainer"
                      style={{ padding: " 0 3px 0px 3px" }}
                    >
                      <h4 className="title">Divisions</h4>
                      <BarChart
                        data={dataDivisions}
                        setData={setDataDivisions}
                        stored={storedDivisions}
                        setStored={setStoredDivisions}
                        xField="value"
                        yField="name"
                        storageKey="barChartDivisions"
                      />
                    </Col>
                    <Col
                      span={6}
                      className="barChartContainer"
                      style={{ padding: " 0 3px 0px 3px" }}
                    >
                      <h4 className="title">Business Units</h4>
                      <BarChart
                        data={dataBUs}
                        setData={setDataBUs}
                        stored={storedBUs}
                        setStored={setStoredBUs}
                        xField="value"
                        yField="name"
                        storageKey="barChartRegions"
                      />
                    </Col>
                    <Col
                      span={6}
                      className="barChartContainer"
                      style={{ padding: " 0 0px 0px 3px" }}
                    >
                      <h4 className="title">Sites</h4>
                      <BarChart
                        data={dataSites}
                        setData={setDataSites}
                        xField="value"
                        yField="name"
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Row>
                  {switchState !== "chart" &&
                  page === "Overall Adherence Summary" ? (
                    <Col span={24} className="note-for-table">
                      <span className="note-text">
                        *Note: This table represents the number of entries that
                        fall under different buckets at an overall level
                      </span>
                    </Col>
                  ) : page !== "Overall Adherence Summary" ? (
                    <Col span={24} className="note-for-table">
                      <span className="note-text">
                        *Note: This table represents the number/percentage of
                        entries that fall under different buckets across each
                        adherence requirement
                      </span>
                    </Col>
                  ) : (
                    ""
                  )}
                  {switchState !== "chart" &&
                  page === "Overall Adherence Summary" ? (
                    <Row className="radio-download-buttons" wrap={false}>
                      <Col span={12} className="radio-selection-group">
                        <Radio.Group
                          name="radiogroup"
                          className="radiobtn"
                          defaultValue="segment"
                          value={locationRadioSelection}
                          onChange={onLocationRadioChange}
                        >
                          <Radio value="segment">Segment</Radio>
                          <Radio value="divisions">Division</Radio>
                          <Radio value="bu">BU</Radio>
                          {/* <Radio value="sub_bu">Sub BU</Radio> */}
                          <Radio value="site">Site</Radio>
                        </Radio.Group>
                      </Col>
                      <Col span={12} className="download-button" align="right">
                        <Button
                          type="custom"
                          className="download-btn"
                          icon={<DownloadOutlined />}
                          shape="round"
                          onClick={() =>
                            dispatch(
                              getOverallAdherenceDataDownload(
                                {
                                  ...appliedFilters,
                                },
                                is_out_of_scope_selected,
                                selectedView
                              )
                            )
                          }
                        >
                          Download Report
                        </Button>
                      </Col>
                    </Row>
                  ) : page !== "Overall Adherence Summary" ? (
                    <Row className="radio-download-buttons" wrap={false}>
                      <Col span={12} className="radio-selection-group">
                        <Radio.Group
                          name="radiogroup"
                          className="radiobtn"
                          defaultValue="segment"
                          value={radioSelection}
                          onChange={onRadioChange}
                        >
                          <Radio value="segment">Segment</Radio>
                          <Radio value="divisions">Division</Radio>
                          <Radio value="bu">BU</Radio>
                          {/* <Radio value="sub_bu">Sub BU</Radio> */}
                          <Radio value="site">Site</Radio>
                        </Radio.Group>
                      </Col>
                      <Col span={12} className="download-button" align="right">
                        <Button
                          type="custom"
                          className="download-btn"
                          icon={<DownloadOutlined />}
                          shape="round"
                          onClick={() =>
                            dispatch(
                              getRequirementViewDataDownload(
                                {
                                  ...appliedFilters,
                                },
                                is_out_of_scope_selected,
                                selectedView
                              )
                            )
                          }
                        >
                          Download Report
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {switchState !== "chart" &&
                  page === "Overall Adherence Summary" ? (
                    <Col span={24} className="site-table-section">
                      <SiteLocationTable
                        data={overAllTable}
                        loader={dropdownLoading}
                      />
                    </Col>
                  ) : page !== "Overall Adherence Summary" ? (
                    <Col span={24} className="site-table-section">
                      <SiteElementTable
                        data={tableData}
                        loader={dropdownLoading}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Content>
            </Layout>
          </Layout>
        </div>
      </Loader>
    </Wrapper>
  );
}

export default withRouter(SiteAssessment);
