import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getChangeSummaryTableData } from "../../slicers/trend-view-slicer";
import { getColumnSearchProps } from "../../utilities/helper";

const ChangeSummaryColumns = (flagResetSearch, page) => {
  const { selectedView } = useSelector((state) => state.homeView);
  const { resetSearch } = useSelector((state) => state.trendView);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    if (flagResetSearch === true) {
      setSearchText("");
      setSearchedColumn("");
      dispatch(getChangeSummaryTableData(selectedView));
    }
  }, [resetSearch]);

  const renderTitle = () => {
    if (selectedView === "Raw") {
      return "Material Risk Assessment (MRA) Status";
    } else {
      return "Packaging Risk Assessment (PRA) Status";
    }
  };
  const rawMaterialCSColumns = [
    {
      title: "Material Description",
      width: "14vw",
      dataIndex: "material_id",
      key: "material_id",
      ...getColumnSearchProps(
        "Material Description",
        "material_id",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.material_id.toLowerCase() !== b.material_id.toLowerCase()
          ? a.material_id.toLowerCase() < b.material_id.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Supplier Name",
      width: "14vw",
      dataIndex: "supplier_id",
      key: "supplier_id",
      ...getColumnSearchProps(
        "Supplier Name",
        "supplier_id",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.supplier_id.toLowerCase() !== b.supplier_id.toLowerCase()
          ? a.supplier_id.toLowerCase() < b.supplier_id.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Site Name",
      width: "14vw",
      dataIndex: "site_unit_name",
      key: "site_unit_name",
      ...getColumnSearchProps(
        "Site Name",
        "site_unit_name",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.site_unit_name.toLowerCase() !== b.site_unit_name.toLowerCase()
          ? a.site_unit_name.toLowerCase() < b.site_unit_name.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: `${renderTitle()}` + " - Current Period",
      width: "14vw",
      dataIndex: "mra_pra_status_val",
      key: "mra_pra_status_val",
      ...getColumnSearchProps(
        `${renderTitle()}` + " - Current Period",
        "mra_pra_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.mra_pra_status_val.toLowerCase() !==
        b.mra_pra_status_val.toLowerCase()
          ? a.mra_pra_status_val.toLowerCase() <
            b.mra_pra_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: `${renderTitle()}` + " - Previous Period",
      width: "14vw",
      dataIndex: "prev_mra_pra_status_val",
      key: "prev_mra_pra_status_val",
      ...getColumnSearchProps(
        `${renderTitle()}` + " - Previous Period",
        "prev_mra_pra_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.prev_mra_pra_status_val.toLowerCase() !==
        b.prev_mra_pra_status_val.toLowerCase()
          ? a.prev_mra_pra_status_val.toLowerCase() <
            b.prev_mra_pra_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Specification Status - Current Period",
      width: "14vw",
      dataIndex: "spec_status_val",
      key: "spec_status_val",
      ...getColumnSearchProps(
        "Specification Status - Current Period",
        "spec_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.spec_status_val.toLowerCase() !== b.spec_status_val.toLowerCase()
          ? a.spec_status_val.toLowerCase() < b.spec_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Specification Status - Previous Period",
      width: "14vw",
      dataIndex: "prev_spec_status_val",
      key: "prev_spec_status_val",
      ...getColumnSearchProps(
        "Specification Status - Previous Period",
        "prev_spec_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.prev_spec_status_val.toLowerCase() !==
        b.prev_spec_status_val.toLowerCase()
          ? a.prev_spec_status_val.toLowerCase() <
            b.prev_spec_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Supplier Status - Current Period",
      width: "14vw",
      dataIndex: "supplier_status_val",
      key: "supplier_status_val",
      ...getColumnSearchProps(
        "Supplier Status - Current Period",
        "supplier_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.supplier_status_val.toLowerCase() !==
        b.supplier_status_val.toLowerCase()
          ? a.supplier_status_val.toLowerCase() <
            b.supplier_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Supplier Status - Previous Period",
      width: "14vw",
      dataIndex: "prev_supplier_status_val",
      key: "prev_supplier_status_val",
      ...getColumnSearchProps(
        "Supplier Status - Previous Period",
        "prev_supplier_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.prev_supplier_status_val.toLowerCase() !==
        b.prev_supplier_status_val.toLowerCase()
          ? a.prev_supplier_status_val.toLowerCase() <
            b.prev_supplier_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Last Supplier Audit Outcome - Current Period",
      width: "14vw",
      dataIndex: "supplier_audit_status_val",
      key: "supplier_audit_status_val",
      ...getColumnSearchProps(
        "Last Supplier Audit Outcome - Current Period",
        "supplier_audit_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.supplier_audit_status_val.toLowerCase() !==
        b.supplier_audit_status_val.toLowerCase()
          ? a.supplier_audit_status_val.toLowerCase() <
            b.supplier_audit_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Last Supplier Audit Outcome - Previous Period",
      width: "14vw",
      dataIndex: "prev_supplier_audit_status_val",
      key: "prev_supplier_audit_status_val",
      ...getColumnSearchProps(
        "Last Supplier Audit Outcome - Previous Period",
        "prev_supplier_audit_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.prev_supplier_audit_status_val.toLowerCase() !==
        b.prev_supplier_audit_status_val.toLowerCase()
          ? a.prev_supplier_audit_status_val.toLowerCase() <
            b.prev_supplier_audit_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
  ];
  const packagingCSColumns = [
    {
      title: "Material Description",
      width: "14vw",
      dataIndex: "material_id",
      key: "material_id",
      ...getColumnSearchProps(
        "Material Description",
        "material_id",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.material_id.toLowerCase() !== b.material_id.toLowerCase()
          ? a.material_id.toLowerCase() < b.material_id.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Supplier Name",
      width: "14vw",
      dataIndex: "supplier_id",
      key: "supplier_id",
      ...getColumnSearchProps(
        "Supplier Name",
        "supplier_id",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.supplier_id.toLowerCase() !== b.supplier_id.toLowerCase()
          ? a.supplier_id.toLowerCase() < b.supplier_id.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Site Name",
      width: "14vw",
      dataIndex: "site_unit_name",
      key: "site_unit_name",
      ...getColumnSearchProps(
        "Site Name",
        "site_unit_name",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.site_unit_name.toLowerCase() !== b.site_unit_name.toLowerCase()
          ? a.site_unit_name.toLowerCase() < b.site_unit_name.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Specification Status - Current Period",
      width: "14vw",
      dataIndex: "spec_status_val",
      key: "spec_status_val",
      ...getColumnSearchProps(
        "Specification Status - Current Period",
        "spec_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.spec_status_val.toLowerCase() !== b.spec_status_val.toLowerCase()
          ? a.spec_status_val.toLowerCase() < b.spec_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Specification Status - Previous Period",
      width: "14vw",
      dataIndex: "prev_spec_status_val",
      key: "prev_spec_status_val",
      ...getColumnSearchProps(
        "Specification Status - Previous Period",
        "prev_spec_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.prev_spec_status_val.toLowerCase() !==
        b.prev_spec_status_val.toLowerCase()
          ? a.prev_spec_status_val.toLowerCase() <
            b.prev_spec_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Supplier Status - Current Period",
      width: "14vw",
      dataIndex: "supplier_status_val",
      key: "supplier_status_val",
      ...getColumnSearchProps(
        "Supplier Status - Current Period",
        "supplier_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.supplier_status_val.toLowerCase() !==
        b.supplier_status_val.toLowerCase()
          ? a.supplier_status_val.toLowerCase() <
            b.supplier_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Supplier Status - Previous Period",
      width: "14vw",
      dataIndex: "prev_supplier_status_val",
      key: "prev_supplier_status_val",
      ...getColumnSearchProps(
        "Supplier Status - Previous Period",
        "prev_supplier_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.prev_supplier_status_val.toLowerCase() !==
        b.prev_supplier_status_val.toLowerCase()
          ? a.prev_supplier_status_val.toLowerCase() <
            b.prev_supplier_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Last Supplier Audit Outcome - Current Period",
      width: "14vw",
      dataIndex: "supplier_audit_status_val",
      key: "supplier_audit_status_val",
      ...getColumnSearchProps(
        "Last Supplier Audit Outcome - Current Period",
        "supplier_audit_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.supplier_audit_status_val.toLowerCase() !==
        b.supplier_audit_status_val.toLowerCase()
          ? a.supplier_audit_status_val.toLowerCase() <
            b.supplier_audit_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: "Last Supplier Audit Outcome - Previous Period",
      width: "14vw",
      dataIndex: "prev_supplier_audit_status_val",
      key: "prev_supplier_audit_status_val",
      ...getColumnSearchProps(
        "Last Supplier Audit Outcome - Previous Period",
        "prev_supplier_audit_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.prev_supplier_audit_status_val.toLowerCase() !==
        b.prev_supplier_audit_status_val.toLowerCase()
          ? a.prev_supplier_audit_status_val.toLowerCase() <
            b.prev_supplier_audit_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: `${renderTitle()}` + " - Current Period",
      width: "14vw",
      dataIndex: "mra_pra_status_val",
      key: "mra_pra_status_val",
      ...getColumnSearchProps(
        `${renderTitle()}` + " - Current Period",
        "mra_pra_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.mra_pra_status_val.toLowerCase() !==
        b.mra_pra_status_val.toLowerCase()
          ? a.mra_pra_status_val.toLowerCase() <
            b.mra_pra_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
    {
      title: `${renderTitle()}` + " - Previous Period",
      width: "14vw",
      dataIndex: "prev_mra_pra_status_val",
      key: "prev_mra_pra_status_val",
      ...getColumnSearchProps(
        `${renderTitle()}` + " - Previous Period",
        "prev_mra_pra_status_val",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput
      ),
      sorter: (a, b) =>
        a.prev_mra_pra_status_val.toLowerCase() !==
        b.prev_mra_pra_status_val.toLowerCase()
          ? a.prev_mra_pra_status_val.toLowerCase() <
            b.prev_mra_pra_status_val.toLowerCase()
            ? -1
            : 1
          : 0,
    },
  ];
  if (page === "Raw") {
    return rawMaterialCSColumns;
  } else if (page === "Packaging") {
    return packagingCSColumns;
  }
};
export default ChangeSummaryColumns;
