import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getManageAccessTableData } from "../../slicers/manage-access-slicer";
import ManageAccessColumns from "./columns";
import { Row, Col, Table } from "antd";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./manageaccess.scss";

const ManageAccessTable = () => {
  const dispatch = useDispatch();
  const {
    appRole,
    manageAccessTableLoader,
    manageAccessTableData,
    approvalFlag,
  } = useSelector((state) => state.manageAccess);
  useEffect(() => {
    dispatch(getManageAccessTableData());
  }, []);
  useEffect(() => {
    dispatch(getManageAccessTableData());
  }, [approvalFlag]);
  const approverTableData = manageAccessTableData.filter(
    (d) => d.request_status == "pending"
  );

  return (
    <>
      <Row className="manage-access-container">
        <Col md={24}>
          <Table
            className="manage-access-table"
            loading={manageAccessTableLoader}
            columns={ManageAccessColumns()}
            dataSource={
              appRole === "Approvers"
                ? approverTableData
                : manageAccessTableData
            }
            size="small"
            showSorterTooltip={{ placement: "bottom" }}
            scroll={{
              y: "55vh",
            }}
            pagination={true}
          />
        </Col>
      </Row>
      <ToastContainer />
    </>
  );
};
export default ManageAccessTable;
