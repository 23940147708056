import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button } from "antd";
import "./adminInputToolsModule.scss";
import ThresholdTable from "./threshold-table";
import ThresholdEditForm from "./threshold-edit-form";
import { EditOutlined } from "@ant-design/icons";
import { getInputToolsData } from "../../slicers/admin-input-tools-slicer";
import { setSelectedView } from "../../slicers/home-slicer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminInputToolsModule = () => {
  const [editFlag, setEditFlag] = useState(false);
  const [thresholdApiData, setThresholdApiData] = useState([]);

  const dispatch = useDispatch();
  const { threshold_table_data, thresholdLoader } = useSelector(
    (state) => state.adminInputTools
  );
  const { selectedView } = useSelector((state) => state.homeView);
  useEffect(() => {
    let tempView = selectedView;
    if (selectedView === "" || selectedView === "home") {
      dispatch(setSelectedView("Raw"));
    } else {
      dispatch(setSelectedView(tempView));
    }
  }, []);
  useEffect(() => {
    dispatch(getInputToolsData());
  }, [selectedView]);

  useEffect(() => {
    setThresholdApiData(threshold_table_data);
  }, [threshold_table_data]);

  const handleEdit = (val) => {
    setEditFlag(val);
  };
  const onThresholdUpdateSuccess = () => {
    dispatch(getInputToolsData());
    setEditFlag(false);
  };
  const info_content = (
    <span>
      Description*: Lower threshold and Upper threshold refers to process
      adherence percentage. Process Adherence Percentage is calculated as
      accumulative assessment of each compliant Material/GSL over total
      materials/GSL approved to be received at the Mars Site. <br />% Process
      Adherence = (Sum of Adhering and Partially Adhering entries/Total entries
      (Adhering + Partially Adhering + Non-Adhering + No Data))
      <br />
      Please refer to the FAQ section for more details.
    </span>
  );
  return (
    <div className="input-tools-wrapper">
      <ToastContainer />
      <Row className="page-title-wrapper">
        <Col className="page-title-text">Input Tool</Col>
      </Row>

      {!editFlag ? (
        <Row className="actions-content" wrap={false}>
          <Col span={24} className="button-wrapper" align="right">
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                handleEdit(true);
              }}
            >
              <EditOutlined />
              Edit Input Tool
            </Button>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <Row className="main-content">
        {!editFlag ? (
          <Col span={24} className="table-wrapper">
            <ThresholdTable data={thresholdApiData} loader={thresholdLoader} />
          </Col>
        ) : (
          <Col span={24} className="form-wrapper">
            <ThresholdEditForm
              data={thresholdApiData}
              handleEdit={handleEdit}
              onThresholdUpdateSuccess={onThresholdUpdateSuccess}
            />
          </Col>
        )}
      </Row>

      <Row className="page-note-wrapper">
        <Col span={24} className="note-text">
          {info_content}
        </Col>
      </Row>
    </div>
  );
};
export default AdminInputToolsModule;
