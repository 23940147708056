import React from "react";
export const columns = [
  {
    title: "Lower Threshold",
    dataIndex: "lower_threshold",
    key: "lower_threshold",
    align: "center",
  },
  {
    title: "Color Indicator",
    dataIndex: "color_codes",
    key: "color_codes",
    align: "center",
    render: (text) => {
      return {
        props: {
          style: { backgroundColor: text },
        },
      };
    },
  },
  {
    title: "Upper Threshold",
    dataIndex: "upper_threshold",
    key: "upper_threshold",
    align: "center",
  },
];
