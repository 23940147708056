import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Collapse } from "antd";
import {
  raw_qna_table_columns,
  raw_qna_table_data,
  pack_qna_table_columns,
  pack_qna_table_data,
} from "./faq-columns";
import titles from "../../utilities/generic-titles";
const { Panel } = Collapse;

const FaqQnA = () => {
  const onChange = (key) => {};
  const { selectedView } = useSelector((state) => state.homeView);
  const [componentsToRender, setComponentsToRender] = useState({});
  useEffect(() => {
    if (selectedView === "Raw") {
      setComponentsToRender({
        columns: raw_qna_table_columns,
        data: raw_qna_table_data,
        vocab: titles.faq_titles.raw_vocab_content,
      });
    } else if (selectedView === "Packaging") {
      setComponentsToRender({
        columns: pack_qna_table_columns,
        data: pack_qna_table_data,
        vocab: titles.faq_titles.pack_vocab_content,
      });
    }
  }, [selectedView]);
  return (
    <>
      <Collapse
        onChange={onChange}
        className="definitions-tab"
        defaultActiveKey="1111"
      >
        <Panel header={titles.faq_titles.qa_header} key="1111">
          <Table
            columns={componentsToRender.columns}
            dataSource={componentsToRender.data}
            size="small"
            pagination={false}
          />
        </Panel>
        <Panel header={titles.faq_titles.vocab_header} key="2222">
          <span style={{ fontSize: "18px", color: "black" }}>
            {componentsToRender.vocab}
          </span>
        </Panel>
      </Collapse>
    </>
  );
};
export default FaqQnA;
