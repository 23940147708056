import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Button } from "antd";
import {
  getChangeSummaryTableData,
  setResetSearch,
} from "../../slicers/trend-view-slicer";
import ChangeSummaryColumns from "./change-summary-columns";
import "./trendingview.scss";

const ChangeSummaryTable = () => {
  const dispatch = useDispatch();
  const {
    changeSummaryTableLoader,
    changeSummaryTableData,
    changeSummaryLegendData,
  } = useSelector((state) => state.trendView);
  const { selectedView } = useSelector((state) => state.homeView);
  const { resetSearch } = useSelector((state) => state.trendView);
  const [cstableKey, setCsTableKey] = useState(0);
  useEffect(() => {
    dispatch(getChangeSummaryTableData(selectedView));
  }, [selectedView]);
  const resetFilters = () => {
    setCsTableKey((cstableKey) => cstableKey + 1);
    dispatch(setResetSearch(true));
  };
  return (
    <>
      <Row className="change-summary-container">
        <Col md={24} className="first-col">
          <Col span={18} align="left" className="legend-info">
            {selectedView === "Raw" ? (
              <span className="note-text">
                *Note: Current Period is {changeSummaryLegendData[0]}, Previous
                Period is {changeSummaryLegendData[1]}
              </span>
            ) : (
              <span className="note-text">
                *Note: Current Period is {changeSummaryLegendData[0]}, Previous
                Period is{" "}
                {changeSummaryLegendData[1] === "2023 P9"
                  ? "N/A"
                  : changeSummaryLegendData[1]}
              </span>
            )}
          </Col>
          <Col span={6} className="reset-search-btn" align="right">
            <Button type="custom" shape="round" onClick={resetFilters}>
              Reset Search
            </Button>
          </Col>
        </Col>
        <Col md={24}>
          <Table
            key={cstableKey}
            className="change-summary-table"
            loading={changeSummaryTableLoader}
            columns={ChangeSummaryColumns(resetSearch, selectedView)}
            dataSource={changeSummaryTableData}
            size="small"
            showSorterTooltip={{ placement: "bottom" }}
            scroll={{
              x: 1500,
              y: "55vh",
            }}
            pagination={true}
          />
        </Col>
      </Row>
    </>
  );
};
export default ChangeSummaryTable;
