const titles = {
  faq_titles: {
    general: "General",
    def_rules: "Definitions & Rules",
    calc_header: "Calculations",
    qa_header: "Questions & Answers",
    vocab_header: "Vocabulary",
    raw_vocab_content:
      "Requirements= expected elements of the process assessed to estimate percentage of adherence",
    pack_vocab_content:
      "Requirements= expected elements of the process assessed to estimate percentage of adherence",
    input_info: "Input information",
    adh_req: "Adherence Requirements",
    adh_formula_header: "Process Adherence Formula",
    mat_adh: "Material Adherence Calculation by Requirements",
    site_adh: "Site/Segment/Global Adherence Calculation",
    raw_inpInfo_content:
      "used to identify the materials, suppliers and receving units with no impact to Adherence calculation",
    pack_inpInfo_content:
      "used to identify the materials, suppliers and receving units with no impact to Adherence calculation",
    raw_adhReq_content:
      "Adherence Requirements included to determine adherence status as described under the FAQ- Calculations",
    pack_adhReq_content:
      "Adherence Requirements included to determine adherence status as described under the FAQ- Calculations",
    adh_formula:
      "% Process Adherence = (Sum of Adhering and Partially Adhering entries/Total entries (Adhering + Partially Adhering + Non Adhering + No Data))",
    raw_mat_adh_content:
      "Requirement option = MRA Status, Specification Status, Supplier Status, Last Supplier Audit Outcome, Inbound Verification set up",
    pack_mat_adh_content:
      "Requirement option = MRA Status, Specification Status, Supplier Status, Last Supplier Audit Outcome, Inbound Verification set up",
    site_adh_content:
      "Process Adherence Percentage is calculated as acummulative assessment of each compliant Material/GSL over total materials/GSL approved to be received at the Mars Site",
  },
  filter_names: {
    segment: "Segment",
    division: "Division",
    bu: "Business Unit",
    sub_bu: "Sub Business Unit",
    unit_type: "Unit Type",
    site_name: "Site Name",
    site_code: "Site Code",
    material_description: "Material Description",
    supplier_name: "Supplier Name",
    adh_status: "Process Adherence Status",
  },
  trending_view_content: {
    sourcing_title:
      "This Release is to be used as a reference (not intended for metrics reporting)",
  },
  home_content: {
    raws_mars_inc_percent: "Mars Inc. Raw Material Process Adherence",
    packs_mars_inc_percent: "Mars Inc. Packaging Process Adherence",
    info_tag:
      "For further details on the process adherence, select from the links below",
    raws_title: "Raw Materials Quality Management Process Adherence Tool",
    raws_def:
      "This tool provides details of key indicators of the Material Quality Management (MQM) process.",
    packs_title: "Packaging Quality Management Process Adherence Tool",
    packs_def:
      "This tool provides details of key indicators of the Packaging Quality Management (PQM) process.",
    emqm_title:
      "External Manufacturing Quality Management Process Adherence Tool",
    emqm_def:
      "This tool provides details of key indicators of the External Manufacturing Quality Management (EMQM) process.",
    dqm_title: "Distribution Quality Management Process Adherence Tool",
    dqm_def:
      "This tool provides details of key indicators of the Distribution Quality Management (DQM) process.",
  },
  refersh_fail: {
    alert:
      "Issue identified with data upload. Data will be refreshed once the issue is resolved.",
  },
};

export default titles;
