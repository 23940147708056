import React from "react";
import { Tooltip } from "antd";
export const columns = [
  {
    title: "Name",
    dataIndex: "self_name",
    key: "self_name",
    width: "14vw",
    sorter: (a, b) => {
      if (a.self_name.toLowerCase() !== b.self_name.toLowerCase()) {
        if (a.self_name.toLowerCase() < b.self_name.toLowerCase()) {
          return -1;
        } else {
          return 1;
        }
      } else {
        return 0;
      }
    },
  },
  {
    title: "Overall Process Adherence %",
    dataIndex: "compliance_percent",
    key: "compliance_percent",
    width: "5vw",
    sorter: (a, b) => a.compliance_percent - b.compliance_percent,
    render: (compliance_percent, record) => (
      <>
        <span>
          <span
            className="compliance-percent"
            style={{
              backgroundColor: record.compliance_color,
            }}
          />
          &nbsp;&nbsp;
          {record.compliance_percent !== -1 ? compliance_percent : "No Data"}
          {record.compliance_percent !== -1 &&
          record.partial_data_flag === 1 ? (
            <Tooltip title="Partial Data Available">{" % * "}</Tooltip>
          ) : record.compliance_percent !== -1 &&
            record.partial_data_flag !== 1 ? (
            " % "
          ) : (
            record.compliance_percent === -1 && ""
          )}
        </span>
      </>
    ),
  },

  {
    title: "# Adhering Entries",
    dataIndex: "count_adhering",
    key: "count_adhering",
    width: "5vw",
    sorter: (a, b) => a.count_adhering - b.count_adhering,
  },
  {
    title: "# Partially Adhering Entries",
    dataIndex: "count_partial",
    key: "count_partial",
    width: "5vw",
    sorter: (a, b) => a.count_partial - b.count_partial,
  },
  {
    title: "# Entries with No Data",
    dataIndex: "count_no_data",
    key: "count_no_data",
    width: "5vw",
    sorter: (a, b) => a.count_no_data - b.count_no_data,
  },
  {
    title: "# Non Adhering Entries",
    dataIndex: "count_non_adhering",
    key: "count_non_adhering",
    width: "5vw",
    sorter: (a, b) => a.count_non_adhering - b.count_non_adhering,
  },
  {
    title: "# Total Entries",
    dataIndex: "count_total",
    key: "count_total",
    width: "5vw",
    sorter: (a, b) => a.count_total - b.count_total,
  },
  {
    title: "# Unique Entries",
    dataIndex: "count_total_material",
    key: "count_total_material",
    width: "5vw",
    sorter: (a, b) => a.count_total_material - b.count_total_material,
  },
];
