import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { setSelectedView } from "../../slicers/home-slicer";
import "./style.scss";

const RadioButtons = () => {
  const { selectedView } = useSelector((state) => state.homeView);
  const dispatch = useDispatch();
  return (
    <div className="mqm-pqm-radio">
      <Button
        className="radio-button"
        type={selectedView !== "Packaging" ? "enabled" : "na"}
        onClick={() => {
          dispatch(setSelectedView("Raw"));
        }}
      >
        Raw Material
      </Button>
      <Button
        className="radio-button"
        type={selectedView === "Packaging" ? "enabled" : "na"}
        onClick={() => {
          dispatch(setSelectedView("Packaging"));
        }}
      >
        Packaging
      </Button>
    </div>
  );
};
export default RadioButtons;
