import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import Loader from "../../components/loader";
import { Layout, Row, Col, Button, Switch } from "antd";
import titles from "../../utilities/generic-titles";
import { setSelectedView, getHomeOverallPerc } from "../../slicers/home-slicer";
import bluebulletpoint from "../../assets/images/bluebulletpoint.png";
import feedbackicon from "../../assets/images/feedbackicon.png";
import "./home.scss";
const { Content } = Layout;
const feedback_form_link = process.env.REACT_APP_FEEDBACK_FORM_URL;

const Home = () => {
  const dispatch = useDispatch();
  const { homePageLoader, overallPercentRaw, overallPercentPack } = useSelector(
    (state) => state.homeView
  );
  const history = useHistory();
  const [selectedState, setSelectedState] = useState(true);
  useEffect(() => {
    dispatch(setSelectedView("home"));
    dispatch(getHomeOverallPerc());
  }, []);
  const onChangeOfState = () => {
    setSelectedState(!selectedState);
  };
  return (
    <Wrapper heading={"0"}>
      <Loader loading={homePageLoader}>
        <Content className="home-wrapper">
          <Row className="process-adherence-display" align="center">
            <Col
              xs={24}
              sm={16}
              md={12}
              lg={12}
              className="mqm-percent"
              align="center"
            >
              <div className="block">
                <span className="text">
                  {titles.home_content.raws_mars_inc_percent}
                </span>
                <span className="percent">{overallPercentRaw} %</span>
              </div>
            </Col>
            <Col
              xs={24}
              sm={16}
              md={12}
              lg={12}
              className="pqm-percent"
              align="center"
            >
              <div className="block">
                <span className="text">
                  {titles.home_content.packs_mars_inc_percent}
                </span>
                <span className="percent">{overallPercentPack} %</span>
              </div>
            </Col>
          </Row>
          <Row className="info-tag" align="center">
            {titles.home_content.info_tag}
          </Row>
          <Row span={24} className="main-contents">
            <Col span={16} className="left-contents">
              <Col className="first-module">
                <Col className="item">
                  <Button
                    className="button"
                    onClick={() => {
                      dispatch(setSelectedView("Raw"));
                      history.push("/trending-view");
                    }}
                  >
                    Click to Enter <br /> <b>Raw Material</b>
                  </Button>
                  <Col className="raws-description">
                    <span className="title">
                      {titles.home_content.raws_title}
                    </span>
                    <span className="definition">
                      {titles.home_content.raws_def}
                    </span>
                  </Col>
                </Col>
              </Col>
              <Col className="second-module">
                <Col className="item">
                  <Button
                    className="button"
                    onClick={() => {
                      dispatch(setSelectedView("Packaging"));
                      history.push("/trending-view");
                    }}
                  >
                    Click to Enter <br /> <b>Packaging</b>
                  </Button>
                  <Col className="packs-description">
                    <span className="title">
                      {titles.home_content.packs_title}
                    </span>
                    <span className="definition">
                      {titles.home_content.packs_def}
                    </span>
                  </Col>
                </Col>
              </Col>
              <Col className="third-module">
                <Col className="item">
                  <Col className="button">
                    COMING <br />
                    SOON
                  </Col>
                  <Col className="emqm-description">
                    <span className="title">
                      {titles.home_content.emqm_title}
                    </span>
                    <span className="definition">
                      {titles.home_content.emqm_def}
                    </span>
                  </Col>
                </Col>
              </Col>
              <Col className="fourth-module">
                <Col className="item">
                  <Col className="button">
                    COMING <br />
                    SOON
                  </Col>
                  <Col className="dqm-description">
                    <span className="title">
                      {titles.home_content.dqm_title}
                    </span>
                    <span className="definition">
                      {titles.home_content.dqm_def}
                    </span>
                  </Col>
                </Col>
              </Col>
            </Col>
            <Col span={8} className="alerts-block">
              <span className="alerts-title">Alerts</span>
              <Col
                xs={8}
                sm={16}
                md={24}
                lg={32}
                align="left"
                className="alerts-section"
              >
                <span className="switch-container">
                  <span className="title">Raw Material</span>
                  <Switch onChange={onChangeOfState} className="switch-state" />
                  <span className="title">Packaging</span>
                </span>
                {selectedState === true ? (
                  <div className="raws-alerts">
                    <span className="alert-note">
                      <img src={bluebulletpoint} width="10px" height="10px" />{" "}
                      Length of last compliance has been added to the material
                      assessment. Length of last compliance is the length of
                      time the process adherence status has been non-adhering
                      for a record (mat+supplier+location). As this is a new
                      feature, current non-adhering materials count will start
                      from P9W
                    </span>
                    <span className="alert-note">
                      <img src={bluebulletpoint} width="10px" height="10px" />{" "}
                      The Process Adherence Indicator "% of Materials with
                      Approved MRA" shows a decline from P7 to P8 due to the
                      additional connector with site w/in the MRA. (previously
                      only material and supplier were linked within the MRA)
                    </span>
                    <span className="alert-note">
                      <img src={bluebulletpoint} width="10px" height="10px" />{" "}
                      Due to changes in Segment structure, we have updated the
                      heatmap to reflect those changes. This includes the
                      addition of Divisions. Division process adherence trending
                      will initiate in P9 2023
                    </span>
                    <span className="alert-note">
                      <img src={bluebulletpoint} width="10px" height="10px" />{" "}
                      SOC, JAN, EGM, BRK, BHM, GUA, MER, REC, MOM sites data
                      will be available from 2023 P05. These sites were having
                      no data/were out of scope previously.
                    </span>
                    <span className="alert-note">
                      <img src={bluebulletpoint} width="10px" height="10px" />{" "}
                      ICB purchase order data has been removed from 2023 P5, so
                      you will be able to see a change in trend as compared to
                      2023 P3, 2023 P4
                    </span>
                  </div>
                ) : (
                  <div className="raws-alerts">
                    <span className="alert-note">
                      <img src={bluebulletpoint} width="10px" height="10px" />{" "}
                      Length of last compliance has been added to the packaging
                      assessment. Length of last compliance is the length of
                      time the process adherence status has been non-adhering
                      for a record (mat+supplier+location). As this is a new
                      feature, current non-adhering materials count will start
                      from P9W
                    </span>
                  </div>
                )}
              </Col>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="footer">
              <div className="contact-card">
                <span className="title">Contact us:</span>
                <span className="feedback-text">Feedback</span>
                <img
                  src={feedbackicon}
                  className="icon-feedback"
                  onClick={() => window.open(feedback_form_link)}
                />
                <span className="email-text">Email us:</span>
                <span className="email-id">Qualityheatmaps@effem.com</span>
              </div>
            </Col>
          </Row>
        </Content>
      </Loader>
    </Wrapper>
  );
};

export default withRouter(Home);
