import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Divider, Collapse } from "antd";
import {
  raw_inp_info_cols,
  raw_inp_info_data,
  pack_inp_info_cols,
  pack_inp_info_data,
  raw_adh_req_cols,
  raw_adh_req_data,
  pack_adh_req_cols,
  pack_adh_req_data,
} from "./faq-columns";
import titles from "../../utilities/generic-titles";
import { CombinedCircles } from "../../components/data-joins";
const { Panel } = Collapse;
const FaqDefinitions = () => {
  const onChange = (key) => {};
  const { selectedView } = useSelector((state) => state.homeView);
  const [componentsToRender, setComponentsToRender] = useState({});
  useEffect(() => {
    if (selectedView === "Raw") {
      setComponentsToRender({
        inpInfoContent: titles.faq_titles.raw_inpInfo_content,
        inpInfoColumns: raw_inp_info_cols,
        inpInfoData: raw_inp_info_data,
        adhReqContent: titles.faq_titles.raw_adhReq_content,
        adhColumns: raw_adh_req_cols,
        adhData: raw_adh_req_data,
      });
    } else if (selectedView === "Packaging") {
      setComponentsToRender({
        inpInfoContent: titles.faq_titles.pack_inpInfo_content,
        inpInfoColumns: pack_inp_info_cols,
        inpInfoData: pack_inp_info_data,
        adhReqContent: titles.faq_titles.pack_adhReq_content,
        adhColumns: pack_adh_req_cols,
        adhData: pack_adh_req_data,
      });
    }
  }, [selectedView]);
  return (
    <>
      <Collapse
        onChange={onChange}
        className="definitions-tab"
        defaultActiveKey="11"
      >
        <Panel header={titles.faq_titles.input_info} key="11">
          <span style={{ color: "black", fontSize: "18px" }}>
            {componentsToRender.inpInfoContent}
          </span>
          <Divider></Divider>
          <Table
            columns={componentsToRender.inpInfoColumns}
            dataSource={componentsToRender.inpInfoData}
            size="small"
            pagination={false}
          />
        </Panel>
        <Panel header={titles.faq_titles.adh_req} key="22">
          <span className="faq-text-black">
            {componentsToRender.adhReqContent}
          </span>
          <Divider></Divider>
          <Table
            columns={componentsToRender.adhColumns}
            dataSource={componentsToRender.adhData}
            size="small"
            pagination={false}
          />
          <div className="data-joins">
            <span className="title">Data Joins</span>
            <CombinedCircles />
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default FaqDefinitions;
