import React from "react";
import RouteConfig from "./routing/routes";
import appInsights from "./appInsights";
import "./assets/styles/app.less";
import "./assets/styles/style.scss";

function App() {
  return <RouteConfig />;
}

export default appInsights(App);
