import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "@ant-design/plots";
import { Row, Col, Button, Popover } from "antd";
import { setChartParameter } from "../../slicers/trend-view-slicer";
import focusModeIcon from "../../assets/images/focusmode.png";
import "./trendingview.scss";
import { normalizeJsName } from "@microsoft/applicationinsights-core-js";

const LineChart = ({ dataTrend, time, focusMode, view }) => {
  const { chartsData } = useSelector((state) => state.trendView);
  const filteredData = chartsData.filter(
    (d) =>
      d.data_status_flag !== "no data" &&
      d.compliance_percent >= 0 &&
      d.mra_pra_status_approved_count_percent >= 0 &&
      d.spec_status_approved_count_percent >= 0 &&
      d.supplier_status_approved_count_percent >= 0
  );
  const adherenceYValues = filteredData.map((d) => d.compliance_percent);
  const minAdhY = Math.min(...adherenceYValues);
  const maxAdhY = Math.max(...adherenceYValues);
  const mraYValues = filteredData.map(
    (d) => d.mra_pra_status_approved_count_percent
  );
  const minMraY = Math.min(...mraYValues);
  const maxMraY = Math.max(...mraYValues);
  const specYValues = filteredData.map(
    (d) => d.spec_status_approved_count_percent
  );
  const minSpecY = Math.min(...specYValues);
  const maxSpecY = Math.max(...specYValues);
  const supplierYValues = filteredData.map(
    (d) => d.supplier_status_approved_count_percent
  );
  const minSupplierY = Math.min(...supplierYValues);
  const maxSupplierY = Math.max(...supplierYValues);
  const dispatch = useDispatch();
  const adherenceChartConfig = {
    data: dataTrend,
    padding: "auto",
    xField: time,
    yField: "compliance_percent",
    seriesField: "self_name",
    xAxis: {
      tickCount: "auto",
      label: {
        style: {
          fill: "#000000",
        },
      },
    },
    yAxis: {
      min: minAdhY - 1,
      max: maxAdhY + 1,
      limitInPlot: true,
      nice: true,
      label: {
        style: {
          fill: "#000000",
        },
        formatter: (val) => {
          const numVal = Number(val);
          if (isNaN(numVal)) {
            return numVal;
          }
          return numVal.toFixed(2);
        },
      },
    },
    slider: {
      start: 0.0,
      end: 1.0,
    },
    point: {
      size: 5,
      shape: "circle",
    },
    legend: {
      layout: "horizontal",
      position: "top-right",
    },
  };
  const mraChartConfig = {
    data: dataTrend,
    padding: "auto",
    xField: time,
    yField: "mra_pra_status_approved_count_percent",
    seriesField: "self_name",
    xAxis: {
      tickCount: "auto",
      label: {
        style: {
          fill: "#000000",
        },
      },
    },
    yAxis: {
      min: minMraY - 1,
      max: maxMraY + 1,
      limitInPlot: true,
      nice: true,
      label: {
        style: {
          fill: "#000000",
        },
        formatter: (val) => {
          const numVal = Number(val);
          if (isNaN(numVal)) {
            return numVal;
          }
          return numVal.toFixed(2);
        },
      },
    },
    slider: {
      start: 0.0,
      end: 1.0,
    },
    point: {
      size: 5,
      shape: "circle",
    },
    legend: {
      layout: "horizontal",
      position: "top-right",
    },
  };
  const specChartConfig = {
    data: dataTrend,
    padding: "auto",
    xField: time,
    yField: "spec_status_approved_count_percent",
    seriesField: "self_name",
    xAxis: {
      tickCount: "auto",
      label: {
        style: {
          fill: "#000000",
        },
      },
    },
    yAxis: {
      min: minSpecY - 1,
      max: maxSpecY + 1,
      limitInPlot: true,
      nice: true,
      label: {
        style: {
          fill: "#000000",
        },
        formatter: (val) => {
          const numVal = Number(val);
          if (isNaN(numVal)) {
            return numVal;
          }
          return numVal.toFixed(2);
        },
      },
    },
    slider: {
      start: 0.0,
      end: 1.0,
    },
    point: {
      size: 5,
      shape: "circle",
    },
    legend: {
      layout: "horizontal",
      position: "top-right",
    },
  };
  const supplierChartConfig = {
    data: dataTrend,
    padding: "auto",
    xField: time,
    yField: "supplier_status_approved_count_percent",
    seriesField: "self_name",
    xAxis: {
      tickCount: "auto",
      label: {
        style: {
          fill: "#000000",
        },
      },
    },
    yAxis: {
      min: minSupplierY - 1,
      max: maxSupplierY + 1,
      limitInPlot: true,
      nice: true,
      label: {
        style: {
          fill: "#000000",
        },
        formatter: (val) => {
          const numVal = Number(val);
          if (isNaN(numVal)) {
            return numVal;
          }
          return numVal.toFixed(2);
        },
      },
    },
    slider: {
      start: 0.0,
      end: 1.0,
    },
    point: {
      size: 5,
      shape: "circle",
    },
    legend: {
      layout: "horizontal",
      position: "top-right",
    },
  };
  const placeholderChartConfig = {
    data: [],
    padding: "auto",
    xField: time,
    yField: "mra_pra_status_approved_count_percent",
    seriesField: "self_name",
    xAxis: {
      tickCount: "auto",
      label: {
        style: {
          fill: "#000000",
        },
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      nice: true,
      label: {
        style: {
          fill: "#ffffff",
        },
      },
    },
    point: {
      size: 5,
      shape: "circle",
    },
    legend: {
      layout: "horizontal",
      position: "top-right",
    },
  };
  const chartsDisplayed = () => {
    if (view === "Raw") {
      if (focusMode === "") {
        return (
          <Row className="charts-container">
            <Col span={10} offset={1} className="trend-chart left-chart">
              <span className="chart-title">Overall Process Adherence %</span>
              <Popover content={"Focus mode"}>
                <img
                  className="focus-mode-icon-1"
                  src={focusModeIcon}
                  onClick={() => dispatch(setChartParameter("adherence"))}
                />
              </Popover>
              <Line {...adherenceChartConfig} />
            </Col>

            <Col span={10} offset={1} className="trend-chart">
              <span className="chart-title">% Materials with Approved MRA</span>
              <Popover content={"Focus mode"}>
                <img
                  className="focus-mode-icon-2"
                  src={focusModeIcon}
                  onClick={() => dispatch(setChartParameter("mra"))}
                />
              </Popover>
              <Line {...mraChartConfig} />
            </Col>

            <Col span={10} offset={1} className="trend-chart left-chart">
              <span className="chart-title">
                % Materials with Specifications Approved
              </span>
              <Popover content={"Focus mode"}>
                <img
                  className="focus-mode-icon-3"
                  src={focusModeIcon}
                  onClick={() => dispatch(setChartParameter("spec"))}
                />
              </Popover>
              <Line {...specChartConfig} />
            </Col>

            <Col span={10} offset={1} className="trend-chart">
              <span className="chart-title">
                % of Materials with Adhering Supplier
              </span>
              <Popover content={"Focus mode"}>
                <img
                  className="focus-mode-icon-4"
                  src={focusModeIcon}
                  onClick={() => dispatch(setChartParameter("supplier"))}
                />
              </Popover>
              <Line {...supplierChartConfig} />
            </Col>
          </Row>
        );
      } else if (focusMode === "adherence") {
        return (
          <Row className="focus-mode-container">
            <Col xs={24} sm={24} md={24} lg={24} className="focus-mode-chart">
              <Button
                type="custom"
                shape="round"
                onClick={() => dispatch(setChartParameter(""))}
              >
                Back
              </Button>
              <span className="chart-title">Overall Process Adherence %</span>
              <Line {...adherenceChartConfig} />
            </Col>
          </Row>
        );
      } else if (focusMode === "mra") {
        return (
          <Row className="focus-mode-container">
            <Col xs={24} sm={24} md={24} lg={24} className="focus-mode-chart">
              <Button
                type="custom"
                shape="round"
                onClick={() => dispatch(setChartParameter(""))}
              >
                Back
              </Button>
              <span className="chart-title">% Materials with Approved MRA</span>
              <Line {...mraChartConfig} />
            </Col>
          </Row>
        );
      } else if (focusMode === "spec") {
        return (
          <Row className="focus-mode-container">
            <Col xs={24} sm={24} md={24} lg={24} className="focus-mode-chart">
              <Button
                type="custom"
                shape="round"
                onClick={() => dispatch(setChartParameter(""))}
              >
                Back
              </Button>
              <span className="chart-title">
                % Materials with Specifications Approved
              </span>
              <Line {...specChartConfig} />
            </Col>
          </Row>
        );
      } else if (focusMode === "supplier") {
        return (
          <Row className="focus-mode-container">
            <Col xs={24} sm={24} md={24} lg={24} className="focus-mode-chart">
              <Button
                type="custom"
                shape="round"
                onClick={() => dispatch(setChartParameter(""))}
              >
                Back
              </Button>
              <span className="chart-title">
                % of Materials with Adhering Supplier
              </span>
              <Line {...supplierChartConfig} />
            </Col>
          </Row>
        );
      }
    } else if (view === "Packaging") {
      if (focusMode === "") {
        return (
          <Row className="charts-container">
            <Col span={10} offset={1} className="trend-chart left-chart">
              <span className="chart-title">Overall Process Adherence %</span>
              <Popover content={"Focus mode"}>
                <img
                  className="focus-mode-icon-1"
                  src={focusModeIcon}
                  onClick={() => dispatch(setChartParameter("adherence"))}
                />
              </Popover>
              <Line {...adherenceChartConfig} />
            </Col>
            <Col span={10} offset={1} className="trend-chart">
              <span className="chart-title">
                % Packaging with Specifications Approved
              </span>
              <Popover content={"Focus mode"}>
                <img
                  className="pqm-focus-mode-icon-3"
                  src={focusModeIcon}
                  onClick={() => dispatch(setChartParameter("spec"))}
                />
              </Popover>
              <Line {...specChartConfig} />
            </Col>
            <Col span={10} offset={1} className="trend-chart left-chart">
              <span className="chart-title">
                % of Packaging with Adhering Supplier
              </span>
              <Popover content={"Focus mode"}>
                <img
                  className="pqm-focus-mode-icon-4"
                  src={focusModeIcon}
                  onClick={() => dispatch(setChartParameter("supplier"))}
                />
              </Popover>
              <Line {...supplierChartConfig} />
            </Col>
            <Col span={10} offset={1} className="trend-chart">
              <span className="chart-title">
                % Packaging with Approved PRA - Coming Soon
              </span>
              <Line {...placeholderChartConfig} />
            </Col>
          </Row>
        );
      } else if (focusMode === "adherence") {
        return (
          <Row className="focus-mode-container">
            <Col xs={24} sm={24} md={24} lg={24} className="focus-mode-chart">
              <Button
                type="custom"
                shape="round"
                onClick={() => dispatch(setChartParameter(""))}
              >
                Back
              </Button>
              <span className="chart-title">Overall Process Adherence %</span>
              <Line {...adherenceChartConfig} />
            </Col>
          </Row>
        );
      } else if (focusMode === "spec") {
        return (
          <Row className="focus-mode-container">
            <Col xs={24} sm={24} md={24} lg={24} className="focus-mode-chart">
              <Button
                type="custom"
                shape="round"
                onClick={() => dispatch(setChartParameter(""))}
              >
                Back
              </Button>

              <span className="chart-title">
                % Packaging with Specifications Approved
              </span>

              <Line {...specChartConfig} />
            </Col>
          </Row>
        );
      } else if (focusMode === "supplier") {
        return (
          <Row className="focus-mode-container">
            <Col xs={24} sm={24} md={24} lg={24} className="focus-mode-chart">
              <Button
                type="custom"
                shape="round"
                onClick={() => dispatch(setChartParameter(""))}
              >
                Back
              </Button>

              <span className="chart-title">
                % of Packaging with Adhering Supplier
              </span>

              <Line {...supplierChartConfig} />
            </Col>
          </Row>
        );
      }
    }
  };
  return <>{chartsDisplayed()}</>;
};
export default LineChart;
