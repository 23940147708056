import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Tabs } from "antd";
import FaqCalculations from "./faq-calculations";
import FaqQnA from "./faq-qna";
import FaqDefinitions from "./faq-definitions";
import Wrapper from "../../components/wrapper";
import RadioButtons from "../../components/raw-pack-radio-btns/radioButtons";
import { setSelectedView } from "../../slicers/home-slicer";
import "./index.scss";
const { TabPane } = Tabs;
const FaqPage = () => {
  const { selectedView } = useSelector((state) => state.homeView);
  const dispatch = useDispatch();
  useEffect(() => {
    let tempView = selectedView;
    if (
      selectedView === "" ||
      selectedView === "home" ||
      selectedView === undefined
    ) {
      dispatch(setSelectedView("Raw"));
    } else {
      dispatch(setSelectedView(tempView));
    }
  }, []);
  return (
    <Wrapper heading={"6"}>
      <div className="faq-wrapper">
        <Row wrap={false} className="radio-btn-row">
          <Col span={18} align="left" className="radio-buttons-wrapper">
            <RadioButtons />
          </Col>
        </Row>
        <Row wrap={false} className="world-map-width">
          <Col span={17}>
            <h2 className="page-title"> Frequently Asked Questions </h2>
          </Col>
        </Row>
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="General" key="1">
            <FaqQnA />
          </TabPane>
          <TabPane tab="Definitions & Rules" key="2">
            <FaqDefinitions />
          </TabPane>
          <TabPane tab="Calculations" key="3">
            <FaqCalculations />
          </TabPane>
        </Tabs>
      </div>
    </Wrapper>
  );
};
export default FaqPage;
