import { createSlice } from "@reduxjs/toolkit";
import CustomAxios from "../utilities/service/api-service";
import CheckAuthorization from "../utilities/auth/check-authorization";
import { get_input_tool_data, Base_URL } from "../utilities/constants";
import {
  showErrorMessage,
  showSuccessMessage,
  showErrorWithCustomMessage,
} from "../utilities/helper";

const initialState = {
  thresholdLoader: false,
  thresholdUpdateLoader: false,
  input_tools_data: [],
  threshold_table_data: [],
  manageAccessTableLoader: false,
  manageAccessTableData: [],
};

const adminInputToolsReducer = createSlice({
  name: "adminInputTools",
  initialState,
  reducers: {
    setThresholdLoader: (state, action) => ({
      ...state,
      thresholdLoader: action.payload,
    }),
    setInputToolsData: (state, action) => ({
      ...state,
      input_tools_data: action.payload,
    }),
    setThresholdTableData: (state, action) => ({
      ...state,
      threshold_table_data: action.payload,
    }),

    setThresholdUpdateLoader: (state, action) => ({
      ...state,
      thresholdUpdateLoader: action.payload,
    }),
    setManageAccessTableLoader: (state, action) => ({
      ...state,
      manageAccessTableLoader: action.payload,
    }),
    setManageAccessTableData: (state, action) => ({
      ...state,
      manageAccessTableData: action.payload,
    }),
  },
});

export const {
  setThresholdLoader,
  setInputToolsData,
  setThresholdTableData,
  setThresholdUpdateLoader,
  setManageAccessTableLoader,
  setManageAccessTableData,
} = adminInputToolsReducer.actions;

export const getInputToolsData = () => async (dispatch, getState) => {
  dispatch(setThresholdLoader(true));
  CustomAxios(Base_URL + get_input_tool_data, "GET")
    .then((response) => {
      if (response.data.error_code === undefined) {
        let input_tools_data = response.data;
        dispatch(setInputToolsData(input_tools_data));
        let temp_data = [];
        input_tools_data.map((ele, i) => {
          if (ele.id !== 1 && ele.id !== 2) {
            temp_data.push(ele);
          }
        });
        dispatch(setThresholdTableData(temp_data));
      }
    })
    .catch((err) => {
      if (err.status === 403) {
        CheckAuthorization();
      }
      console.error("Admin Input Tools API Failed", err);
      showErrorMessage();
    })
    .finally(() => {
      dispatch(setThresholdLoader(false));
    });
};

export const updateInputTool = (Params) => async (dispatch, getState) => {
  dispatch(setThresholdUpdateLoader(true));
  CustomAxios(Base_URL + get_input_tool_data, "PUT", Params)
    .then((response) => {
      if (response.data.success) {
        showSuccessMessage("Thresholds Updated Successfully!");
      }
    })
    .catch((err) => {
      if (err.status === 403) {
        CheckAuthorization();
      }
      showErrorWithCustomMessage("Threshold Update Failed!");
    })
    .finally(() => {
      dispatch(setThresholdUpdateLoader(false));
    });
};
export default adminInputToolsReducer.reducer;
