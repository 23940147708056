import React from "react";
import { Layout, Typography } from "antd";

const { Title } = Typography;

function PageNotFound() {
  return (
    <Layout className="login-layout">
      <div className="login-wrapper">
        <div className="not-found-wrap">
          <div>
            <Title className="welcome-text" level={1}>
              Sorry! <br />
              This Page Isn't Available
              <br />
            </Title>
          </div>
        </div>
        <div className="not-found-bg" />
      </div>
    </Layout>
  );
}

export default PageNotFound;
