import React from "react";
import { useSelector } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import history from "../utilities/history";
import Home from "../pages/home";
import TrendingView from "../pages/trending-view";
import CorporateAssessment from "../pages/corporate-assessment";
import MaterialAssessment from "../pages/material-assessment";
import SiteAssessment from "../pages/site-assessment";
import PageNotFound from "../pages/page-not-found";
import AdminLayout from "../pages/admin-layout";
import RequestAccess from "../pages/request-access";
import FaqPage from "../pages/freq-asked-qs/index";

function RouteConfig() {
  const { appRole } = useSelector((state) => state.manageAccess);
  return (
    <Router history={history}>
      {
        <Switch>
          <Route path="/" exact={true}>
            <Home />
          </Route>
          <Route path="/home" exact={true}>
            <Home />
          </Route>
          <Route path="/trending-view" exact={true}>
            <TrendingView />
          </Route>
          <Route path="/map-view" exact={true}>
            <CorporateAssessment />
          </Route>
          <Route path="/assessment" exact={true}>
            <MaterialAssessment />
          </Route>
          <Route path="/summary-view" exact={true}>
            <SiteAssessment />
          </Route>
          <Route path="/approver" exact={true}>
            {appRole === "Approvers" ? <AdminLayout /> : <PageNotFound />}
          </Route>
          <Route path="/admin" exact={true}>
            {appRole === "Super.Admin" ? <AdminLayout /> : <PageNotFound />}
          </Route>
          {appRole !== "Super.Admin" &&
            appRole !== "Approvers" &&
            appRole !== "Users" && (
              <Route path="/request-access" exact={true}>
                <RequestAccess />
              </Route>
            )}
          <Route path="/faqs" exact={true}>
            <FaqPage />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      }
    </Router>
  );
}

export default RouteConfig;
