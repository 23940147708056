import { createSlice } from "@reduxjs/toolkit";
import CustomAxios, { CustomAxiosDE } from "../utilities/service/api-service";
import CheckAuthorization from "../utilities/auth/check-authorization";
import {
  Base_URL,
  site_location_bar_charts,
  site_element_radio,
  site_location_radio,
  overallAdherenceDownload,
  requirementViewDownload,
} from "../utilities/constants";

const initialState = {
  loading: 0,
  dropdownLoading: 0,
  loadingExportData: 0,
  data_filters: {},
  filters: {},
  material_filters: {
    supplier_id: [],
    material_id: [],
    overall_compliance: [],
  },
  supplier_id: [],
  material_id: [],
  overall_compliance: [],
  materials_api_data: [],
  material_pagination: {
    current: 1,
    pageSize: 50,
    total: "",
  },
  location_barchart: {},
  site_location_data: [],
  levelSelection: "segment",
  site_element_data: [],
  segment_bars: [],
  division_bars: [],
  bu_bars: [],
  site_bars: [],
  siteTableRadio: [],
  siteLocationTableRadio: [],
};

const siteAssessmentSlicer = createSlice({
  name: "siteAssessment",
  initialState,
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      loading: state.loading + (action.payload ? 1 : -1),
    }),
    setDropdownLoading: (state, action) => ({
      ...state,
      dropdownLoading: state.dropdownLoading + (action.payload ? 1 : -1),
    }),
    setExportDataLoading: (state, action) => ({
      ...state,
      loadingExportData: state.loadingExportData + (action.payload ? 1 : -1),
    }),
    setDataFilters: (state, action) => ({
      ...state,
      data_filters: action.payload,
    }),
    setDataDropdown: (state, action) => ({
      ...state,
      [action.payload[0]]: action.payload[1],
    }),
    setMaterialTableData: (state, action) => ({
      ...state,
      materials_api_data: action.payload,
    }),
    setMaterialPagination: (state, action) => ({
      ...state,
      material_pagination: action.payload,
    }),
    setLocationBarchart: (state, action) => ({
      ...state,
      location_barchart: action.payload,
      segment_bars: action.payload.segments,
      division_bars: action.payload.divisions,
      bu_bars: action.payload.business_units,
      site_bars: action.payload.sites,
    }),
    setSiteLocationTableData: (state, action) => ({
      ...state,
      site_location_data: action.payload,
    }),
    setLevelSelection: (state, action) => ({
      ...state,
      levelSelection: action.payload,
    }),
    setSiteElementTableData: (state, action) => ({
      ...state,
      site_element_data: action.payload,
    }),
    setSiteTableRadio: (state, action) => ({
      ...state,
      siteTableRadio: action.payload,
    }),
    setSiteLocationTableRadio: (state, action) => ({
      ...state,
      siteLocationTableRadio: action.payload,
    }),
  },
});

export const {
  setLoading,
  setDropdownLoading,
  setExportDataLoading,
  setDataFilters,
  setDataDropdown,
  setMaterialTableData,
  setMaterialPagination,
  setLocationBarchart,
  setSiteLocationTableData,
  setLevelSelection,
  setSiteElementTableData,
  setSiteTableRadio,
  setSiteLocationTableRadio,
} = siteAssessmentSlicer.actions;

export const getSiteLocationBarchartData =
  (filters, oos_flag, type) => async (dispatch, getState) => {
    let urlFilters = { ...filters };
    Object.keys(urlFilters).forEach((key) => {
      if (urlFilters[key].length === 0) {
        delete urlFilters[key];
      }
    });
    CustomAxios(
      Base_URL +
        site_location_bar_charts +
        "?only_oos=" +
        oos_flag +
        "&type=" +
        type,
      "POST",
      urlFilters
    )
      .then((response) => {
        if (response.data.error_code === undefined) {
          let response_data = response.data;
          dispatch(setLocationBarchart(response_data));
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error("bar chart API Failed", err);
      });
  };

export const getSiteTableData =
  (filters, oos_flag, type) => async (dispatch, getState) => {
    let urlFilters = { ...filters };
    Object.keys(urlFilters).forEach((key) => {
      if (urlFilters[key].length === 0) {
        delete urlFilters[key];
      }
    });
    dispatch(setDropdownLoading(true));
    CustomAxios(
      Base_URL + site_element_radio + "?only_oos=" + oos_flag + "&type=" + type,
      "POST",
      urlFilters
    )
      .then((response) => {
        dispatch(setSiteTableRadio(response.data));
        dispatch(setDropdownLoading(false));
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error("Site location API Failed", err);
        dispatch(setDropdownLoading(false));
      });
  };

export const getOverallTableData =
  (filters, oos_flag, type) => async (dispatch, getState) => {
    let urlFilters = { ...filters };
    Object.keys(urlFilters).forEach((key) => {
      if (urlFilters[key].length === 0) {
        delete urlFilters[key];
      }
    });
    dispatch(setDropdownLoading(true));
    CustomAxios(
      Base_URL +
        site_location_radio +
        "?only_oos=" +
        oos_flag +
        "&type=" +
        type,
      "POST",
      urlFilters
    )
      .then((response) => {
        dispatch(setSiteLocationTableRadio(response.data));
        dispatch(setDropdownLoading(false));
      })
      .catch((err) => {
        console.error("Site location API Failed", err);
        dispatch(setDropdownLoading(false));
      });
  };

export const getOverallAdherenceDataDownload =
  (filters, oosFlag, type) => async (dispatch, getState) => {
    dispatch(setExportDataLoading(true));
    let payloadFilters = { ...filters };
    //deleting empty key value pairs
    Object.keys(payloadFilters).forEach((key) => {
      if (payloadFilters[key].length === 0) {
        delete payloadFilters[key];
      }
    });
    dispatch(setExportDataLoading(true));
    CustomAxiosDE(
      Base_URL +
        overallAdherenceDownload +
        "?only_oos=" +
        oosFlag +
        "&type=" +
        type,
      "POST",
      payloadFilters,
      "blob"
    )
      .then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "overallAdherenceSummaryExport.xlsx");
          document.body.appendChild(link);
          link.click();
        }
        dispatch(setExportDataLoading(false));
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error(
          "error, Error initiating overall adherence summary download",
          err
        );
        dispatch(setExportDataLoading(false));
      });
  };

export const getRequirementViewDataDownload =
  (filters, oosFlag, type) => async (dispatch, getState) => {
    dispatch(setExportDataLoading(true));
    let payloadFilters = { ...filters };
    //deleting empty key value pairs
    Object.keys(payloadFilters).forEach((key) => {
      if (payloadFilters[key].length === 0) {
        delete payloadFilters[key];
      }
    });
    dispatch(setExportDataLoading(true));
    CustomAxiosDE(
      Base_URL +
        requirementViewDownload +
        "?only_oos=" +
        oosFlag +
        "&type=" +
        type,
      "POST",
      payloadFilters,
      "blob"
    )
      .then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "requirementViewSummaryExport.xlsx");
          document.body.appendChild(link);
          link.click();
        }
        dispatch(setExportDataLoading(false));
      })
      .catch((err) => {
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error(
          "error, Error initiating requirement view summary download",
          err
        );
        dispatch(setExportDataLoading(false));
      });
  };

export default siteAssessmentSlicer.reducer;
