import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Modal, Table } from "antd";
import { columns } from "./table-columns";
import "../siteassessment.scss";
import { getThresholdsData } from "../../../slicers/corporate-assessment-slicer";

const SiteLocationTable = ({ data = [], loader = 1 }) => {
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [newLegends, setNewLegends] = useState({});
  const [thresholdAPIData, setThresholdAPIData] = useState([]);
  const [thresholdIDMapping, setThresholdIDMapping] = useState({});
  const [legendInfoTableData, setlegendInfoTableData] = useState([]);

  const { thresholds_data } = useSelector((state) => state.corporateAssessment);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getThresholdsData());
  }, []);
  useEffect(() => {
    if (thresholds_data.length) {
      let tempArray = {};
      thresholds_data.forEach((threshold, i) => {
        tempArray[threshold.id] = true;
      });
      setNewLegends(tempArray);
      let temp = {};
      Object.keys(tempArray).forEach((ID, i) => {
        let relevantObj = thresholds_data.filter((rec, index) => rec.id == ID);

        temp[ID] = {
          lt: relevantObj[0].lower_threshold,
          ut: relevantObj[0].upper_threshold,
        };
      });

      setThresholdIDMapping(temp);
      //for legend info table
      let new_table_data = thresholds_data.filter(
        (data, i) => data.id != 1 && data.id != 2
      );
      setlegendInfoTableData(new_table_data);
    }
    setThresholdAPIData(thresholds_data);
  }, [thresholds_data]);
  useEffect(() => {
    if (!data || !Array.isArray(data)) return setTableData([]);

    function filterDeep(array, filterCondition) {
      return array.reduce((previousValue, currentValue) => {
        let children = filterDeep(currentValue.children || [], filterCondition);
        if (filterCondition(currentValue) || children.length)
          previousValue.push({
            ...currentValue,
            children: children.length && children,
          });
        return previousValue;
      }, []);
    }

    const newData = filterDeep(data, ({ compliance_percent }) => {
      return (
        (newLegends["2"] ? compliance_percent === -1 : false) ||
        (newLegends[3]
          ? compliance_percent >= thresholdIDMapping[3].lt &&
            compliance_percent <= thresholdIDMapping[3].ut
          : false) ||
        (newLegends[4]
          ? compliance_percent > thresholdIDMapping[4].lt &&
            compliance_percent <= thresholdIDMapping[4].ut
          : false) ||
        (newLegends[5]
          ? compliance_percent > thresholdIDMapping[5].lt &&
            compliance_percent <= thresholdIDMapping[5].ut
          : false) ||
        (newLegends[6]
          ? compliance_percent > thresholdIDMapping[6].lt &&
            compliance_percent <= thresholdIDMapping[6].ut
          : false) ||
        (newLegends[7]
          ? compliance_percent > thresholdIDMapping[7].lt &&
            compliance_percent <= thresholdIDMapping[7].ut
          : false)
      );
    });

    setTableData(newData);
  }, [newLegends, data]);

  const handleLegendClick = (ID) => {
    let temp = Object.assign({}, newLegends);
    temp[ID] = !newLegends[ID];
    setNewLegends(temp);
  };

  const LegendsBar = ({ showModal }) => (
    <>
      <span className="site-percent-compliance">% of Process Adherence:</span>
      <div className="site-compliance-indicators">
        <Button size="small" className="legend-info-btn" onClick={showModal}>
          Legend Info.
        </Button>
        {thresholdAPIData.map((threshold, index) => {
          let ID = threshold.id;
          return (
            <div
              className="site-compliance-indicators"
              onClick={() => {
                handleLegendClick(ID);
              }}
              key={threshold.id}
            >
              <span
                className="site-compliance-indicator-circles"
                style={{
                  backgroundColor: newLegends[ID]
                    ? threshold.color_codes
                    : "grey",
                }}
              ></span>
              <span
                className={
                  newLegends[ID]
                    ? "site-compliance-indicator-text text-dark"
                    : "site-compliance-indicator-text text-faded"
                }
              >
                {threshold.id == 2
                  ? "No Data"
                  : threshold.lower_threshold +
                    " - " +
                    threshold.upper_threshold +
                    "%"}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    setTableData(data);
  }, [data]);
  return (
    <Row className="main-content">
      <Col span={24} className="tableIndicators" align="right">
        <LegendsBar showModal={showModal} />
      </Col>
      <Col
        span={24}
        style={{ background: "#fff" }}
        className="site-table-section"
      >
        <Table
          className="custom-table"
          columns={columns}
          dataSource={tableData}
          size="small"
          loading={loader ? true : false}
          showSorterTooltip={{ placement: "bottom" }}
          scroll={{
            y: "63vh",
          }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100"],
          }}
        />
      </Col>
      <Modal
        width={"fit-content"}
        title="Legend Guide"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
      >
        <ContentofLegend data={legendInfoTableData} />
      </Modal>
    </Row>
  );
};
const ContentofLegend = (data) => {
  const columns = [
    {
      title: "Adherence Percentage",
      dataIndex: "percentage",
      render: (text, record) => {
        return (
          <div>
            {record.lower_threshold + " - " + record.upper_threshold + "%"}
          </div>
        );
      },
    },
    {
      title: "Color Code",
      dataIndex: "color_codes",
      render(text) {
        return {
          props: {
            style: { background: text },
          },
          children: <div></div>,
        };
      },
    },
  ];

  return (
    <div>
      <span className="info-container">
        Process Adherence Percentage is calculated as acummulative assessment of
        each compliant Material/GSL over total materials/GSL approved to be
        received at the Mars Site
      </span>
      <div className="info-container">
        % Process Adherence = (Sum of Adhering and Partially Adhering
        entries/Total entries (Adhering + Partially Adhering + Non Adhering + No
        Data))
      </div>
      <Table
        style={{ width: "30%", marginLeft: "35%" }}
        columns={columns}
        dataSource={data.data}
        size="small"
        pagination={false}
      />
    </div>
  );
};

export default SiteLocationTable;
