import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setAlertRefreshDate,
  setCurrentDateIST,
  setActualDateIST,
} from "../../slicers/corporate-assessment-slicer";
import { Popover } from "antd";
import titles from "../../utilities/generic-titles";
import exclamationmark from "../../assets/images/exclamationmark.png";
import "./refresh-failure.scss";

const getCurrentDate = () => {
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();
  const date = today.getDate() - 1;
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${date}/${monthNames[month]}/${year}`;
};

const getActualDate = () => {
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();
  const date = today.getDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${date}/${monthNames[month]}/${year}`;
};

const RefreshFailureAlert = () => {
  const { last_Refresh_date } = useSelector(
    (state) => state.corporateAssessment
  );
  const dispatch = useDispatch();
  const [currentDate] = useState(getCurrentDate());
  const [actualDate] = useState(getActualDate());
  useEffect(() => {
    dispatch(setCurrentDateIST(currentDate));
    dispatch(setActualDateIST(actualDate));
  }, []);
  useEffect(() => {
    if (last_Refresh_date !== currentDate && last_Refresh_date !== actualDate) {
      dispatch(setAlertRefreshDate(true));
    } else if (
      last_Refresh_date === currentDate ||
      last_Refresh_date === actualDate
    ) {
      dispatch(setAlertRefreshDate(false));
    }
  }, []);
  return (
    <Popover placement="left" content={titles.refersh_fail.alert}>
      <img src={exclamationmark} className="refresh-failure" />
    </Popover>
  );
};

export default RefreshFailureAlert;
