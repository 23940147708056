import { createSlice } from "@reduxjs/toolkit";
import CustomAxios from "../utilities/service/api-service";
import CheckAuthorization from "../utilities/auth/check-authorization";
import {
  request_access_api,
  requestAccessFilters,
  get_user_status,
  Base_URL,
} from "../utilities/constants";
import {
  showSuccessMessage,
  showErrorWithCustomMessage,
} from "../utilities/helper";

const initialState = {
  requestPageLoader: false,
  segment_options: [],
  division_options: [],
  business_unit_options: [],
  request_submit_flag: false,
  user_status: "",
  user_status_data: null,
  status_flag: 0,
};

const requestAccessReducer = createSlice({
  name: "requestAccess",
  initialState,
  reducers: {
    setRequestPageLoader: (state, action) => ({
      ...state,
      requestPageLoader: action.payload,
    }),
    setDataDropdown: (state, action) => ({
      ...state,
      [action.payload[0]]: action.payload[1],
    }),
    setSubmitFlag: (state, action) => ({
      ...state,
      request_submit_flag: action.payload,
    }),
    setUserStatus: (state, action) => ({
      ...state,
      user_status: action.payload,
    }),
    setUserStatusData: (state, action) => ({
      ...state,
      user_status_data: action.payload,
    }),
    setStatusAPIFlag: (state, action) => ({
      ...state,
      status_flag: action.payload,
    }),
  },
});

export const {
  setRequestPageLoader,
  setDataDropdown,
  setSubmitFlag,
  setUserStatus,
  setUserStatusData,
  setStatusAPIFlag,
} = requestAccessReducer.actions;

export const submitRequest = (Params) => async (dispatch, getState) => {
  dispatch(setRequestPageLoader(true));
  CustomAxios(Base_URL + request_access_api, "POST", Params)
    .then((response) => {
      if (response.data) {
        dispatch(setSubmitFlag(true));
        showSuccessMessage("Your Request Has Been Sent Successfully!");
      } else {
        dispatch(setSubmitFlag(false));
      }
    })
    .catch((err) => {
      if (err.status === 403) {
        CheckAuthorization();
      }
      dispatch(setSubmitFlag(false));
      showErrorWithCustomMessage("Your Request Submission Failed!");
    })
    .finally(() => {
      dispatch(setRequestPageLoader(false));
    });
};

export const getLeftSectionFilters =
  (filters) => async (dispatch, getState) => {
    dispatch(setRequestPageLoader(true));
    let urlFilters = { ...filters };
    Object.keys(urlFilters).forEach((key) => {
      if (urlFilters[key].length === 0) {
        delete urlFilters[key];
      }
    });

    CustomAxios(Base_URL + requestAccessFilters, "POST", urlFilters)
      .then((response) => {
        if (response.data.error_code === undefined) {
          let data_filters = response.data;

          data_filters.site_business_unit &&
            data_filters.site_business_unit.length &&
            dispatch(
              setDataDropdown([
                "business_unit_options",
                data_filters.site_business_unit,
              ])
            );
          data_filters.site_segment &&
            data_filters.site_segment.length &&
            dispatch(
              setDataDropdown(["segment_options", data_filters.site_segment])
            );
          if (data_filters.division && data_filters.division.length) {
            let temp = data_filters.division.filter((d) => d !== "Food N/A");
            dispatch(setDataDropdown(["division_options", temp]));
          }
        }
        dispatch(setRequestPageLoader(false));
      })
      .catch((err) => {
        dispatch(setRequestPageLoader(false));
        if (err.status === 403) {
          CheckAuthorization();
        }
        console.error("Left Section Filters Dropdown API Failed", err);
      });
  };
export const getUserRequestStatus = () => async (dispatch, getState) => {
  dispatch(setRequestPageLoader(true));
  dispatch(setStatusAPIFlag(1));
  let oid = sessionStorage.getItem("oid");

  CustomAxios(Base_URL + get_user_status + "/" + oid, "GET")
    .then((response) => {
      if (response.data && Object.entries(response.data).length) {
        let api_response = response.data;

        dispatch(setUserStatusData(api_response));
        dispatch(setUserStatus(api_response.request_status));
      } else {
        dispatch(setUserStatus(""));
      }
      dispatch(setRequestPageLoader(false));
      dispatch(setStatusAPIFlag(2));
    })
    .catch((err) => {
      dispatch(setRequestPageLoader(false));
      dispatch(setStatusAPIFlag(2));
      dispatch(setUserStatus(""));
      if (err.status === 403) {
        CheckAuthorization();
      }
      if (err.status === 404) {
        CheckAuthorization();
        dispatch(setUserStatus(""));
      }
      console.error("user request status API Failed", err);
    });
};
export default requestAccessReducer.reducer;
