import React from "react";
import { Layout } from "antd";
import Header from "../header";

function Wrapper(props) {
  return (
    <Layout>
      {!props.hideHeader && (
        <Header
          tab={props.heading}
          innerTab={props.innerHeading}
          reportName={props.reportName}
        />
      )}
      <Layout
        style={
          !props.hidePage
            ? {
                marginTop: "-15px",
              }
            : {}
        }
      >
        <Layout className="wrapper-layout">{props.children}</Layout>
      </Layout>
    </Layout>
  );
}

export default Wrapper;
