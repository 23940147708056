import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "antd";
import SiteElementColumns from "./table-columns";

const SiteElementTable = ({ data = [], loader = 1 }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const noFoodDivisionData = [];
    data.map((item) => {
      if (item.self_name !== "Food N/A") {
        noFoodDivisionData.push(item);
      }
    });
    if (noFoodDivisionData) {
      setTableData(noFoodDivisionData);
    } else {
      setTableData(data);
    }
  }, [data]);

  return (
    <Row className="element-view-content">
      <Col span={24} className="site-table-section">
        <Table
          className="custom-table"
          dataSource={tableData}
          columns={SiteElementColumns()}
          size="small"
          loading={loader ? true : false}
          showSorterTooltip={{ placement: "bottom" }}
          scroll={{
            x: 1500,
            y: "63vh",
          }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100"],
          }}
        />
      </Col>
    </Row>
  );
};

export default SiteElementTable;
