import React from "react";
import { Layout } from "antd";
import AdminInputToolsModule from "../admin-input-tools-module";
import ManageAccess from "../manage-access";

const { Content } = Layout;

const AdminContent = ({ collapsed, setCollapsed, activeModule }) => {
  const moduleContainer = (activeModule) => {
    if (activeModule === "Manage Access") {
      return <ManageAccess />;
    } else if (activeModule === "Input Tools") {
      return <AdminInputToolsModule />;
    }
  };
  return (
    <Layout className="site-layout">
      <Content className="admin-content">
        {moduleContainer(activeModule)}
      </Content>
    </Layout>
  );
};
export default AdminContent;
