import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Row, Col, Button, Form, Input, Select } from "antd";
import accessImg from "../../assets/images/request-page-img1.png";
import {
  getLeftSectionFilters,
  submitRequest,
} from "../../slicers/request-access-slicer";
import Loader from "../../components/loader";
import RequestStatus from "./request-status";

const { Title } = Typography;

function RequestForm() {
  const [showForm, setShowForm] = useState(true);

  const [segmentOpts, setSegmentOpts] = useState([]);
  const [divisionOpts, setDivisionOpts] = useState([]);
  const [buOpts, setBuOpts] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {
    segment_options,
    division_options,
    business_unit_options,
    requestPageLoader,
    request_submit_flag,
  } = useSelector((state) => state.requestAccess);

  useEffect(() => {
    dispatch(getLeftSectionFilters({}));
  }, []);

  useEffect(() => {
    if (request_submit_flag) {
      setShowForm(false);
    }
  }, [request_submit_flag]);
  useEffect(() => {
    console.log(form.getFieldValue(),"///////////////////////")
    if (!form.getFieldValue("bu") && !form.getFieldValue("division")) {
      let temp = new Array(...segment_options, "Corporate");

      setSegmentOpts(temp);
    } else {
      setSegmentOpts(segment_options);
    }
  }, [segment_options]);

  useEffect(() => {
    if (form.getFieldValue("segment") == "Corporate") {
      setDivisionOpts([]);
    } else {
      setDivisionOpts(division_options);
    }
  }, [division_options]);

  useEffect(() => {
    if (form.getFieldValue("segment") == "Corporate") {
      setBuOpts([]);
    } else {
      setBuOpts(business_unit_options);
    }
  }, [business_unit_options]);

  const onFinish = (values) => {
    let params = {
      user_oid: sessionStorage.getItem("oid"),
      request_status: "pending",
      requested_segment: values.segment,
      requested_bu: values.bu ? values.bu : "Not Applicable",
      requested_division: values.division ? values.division : "Not Applicable",
      reason: values.reason,
      user_function: values.function,
      name: sessionStorage.getItem("name"),
      email: sessionStorage.getItem("email"),
    };
    dispatch(submitRequest(params));
    form.resetFields();
  };

  const onValuesChange = (value, all) => {
    let changedKey = Object.keys(value);
    let changedValue = Object.values(value);
    let currentSelection = changedValue[0] ? changedValue : [];
    if (changedKey == "segment") {
      let bu = all.bu ? [all.bu] : [];
      let division = all.division ? [all.division] : [];
      dispatch(
        getLeftSectionFilters({
          site_segment: currentSelection,
          division: division,
          site_business_unit: bu,
        })
      );
    } else if (changedKey == "division") {
      let bu = all.bu ? [all.bu] : [];
      let segment = all.segment ? [all.segment] : [];
      dispatch(
        getLeftSectionFilters({
          site_segment: segment,
          division: currentSelection,
          site_business_unit: bu,
        })
      );
    } else if (changedKey == "bu") {
      let segment = all.segment ? [all.segment] : [];
      let division = all.division ? [all.division] : [];
      dispatch(
        getLeftSectionFilters({
          site_segment: segment,
          division: division,
          site_business_unit: currentSelection,
        })
      );
    }
  };
  return (
    <>
      {showForm ? (
        <>
          <Col span="20" offset="2" className="request-page-content">
            <div className="image-wrapper">
              <img src={accessImg}></img>
            </div>
            <Title className="welcome-text" level={4}>
              You do not have access to Quality Heatmaps. Please fill the below
              details to get the access:
            </Title>

            <div className="form-wrapper">
              <Loader loading={requestPageLoader} tip={"Loading"}>
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  initialValues={{ email: sessionStorage.getItem("email") }}
                  onValuesChange={onValuesChange}
                  requiredMark={true}
                >
                  <Row className="fieldContainer">
                    <Col className="fieldWrapper" span={4}>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Email is required",
                          },
                        ]}

                      >
                        <Input placeholder="Input Email" type="email" disabled="true" />
                      </Form.Item>
                    </Col>
                    <Col className="fieldWrapper" span={3}>
                      <Form.Item
                        name="segment"
                        label="Segment"
                        rules={[
                          {
                            required: true,
                            message: "Segment is required",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Segment"
                          options={
                            segmentOpts.length
                              ? segmentOpts.map((opt, i) => {
                                  return { value: opt, label: opt };
                                })
                              : []
                          }
                          allowClear
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col className="fieldWrapper" span={3}>
                      <Form.Item
                        name="division"
                        label="Division"
                        rules={[
                          {
                            required:
                              form.getFieldValue("segment") ==
                                "Mars Food & Nutrition" ||
                              form.getFieldValue("segment") == "Corporate"
                                ? false
                                : true,
                            message: "Division is required",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Division"
                          options={
                            form.getFieldValue("segment") == "Corporate"
                              ? []
                              : divisionOpts.length
                              ? divisionOpts.map((opt, i) => {
                                  return { value: opt, label: opt };
                                })
                              : []
                          }
                          allowClear
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col className="fieldWrapper" span={3}>
                      <Form.Item
                        name="bu"
                        label="BU"
                        rules={[
                          {
                            required:
                              form.getFieldValue("segment") == "Corporate"
                                ? false
                                : true,
                            message: "BU is required",
                          },
                        ]}
                      >
                        <Select
                          placeholder="BU"
                          options={
                            form.getFieldValue("segment") == "Corporate"
                              ? []
                              : buOpts.length
                              ? buOpts.map((opt, i) => {
                                  return { value: opt, label: opt };
                                })
                              : []
                          }
                          allowClear
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col className="fieldWrapper" span={5}>
                      <Form.Item
                        name="function"
                        label="Function"
                        rules={[
                          {
                            required: true,
                            message: "Function is required",
                          },
                          {
                            type: "string",
                            max: 100,
                          },
                          () => ({
                            validator: async (rule, value) => {
                              if (value) {
                                let name = rule.field;

                                if (/^\d+$/.test(value)) {
                                  throw new Error(
                                    name + " can't have only numbers"
                                  );
                                } else if (/[^a-zA-Z0-9.\ \-\,]/.test(value)) {
                                  throw new Error(
                                    name + " can't have special characters"
                                  );
                                }
                              }
                            },
                          }),
                        ]}
                      >
                        <Input placeholder="Input Function" />
                      </Form.Item>
                    </Col>
                    <Col className="fieldWrapper" span={6}>
                      <Form.Item
                        name="reason"
                        label="Reason"
                        rules={[
                          {
                            required: true,
                            message: "Reason is required",
                          },
                          {
                            type: "string",
                            max: 100,
                          },
                          () => ({
                            validator: async (rule, value) => {
                              if (value) {
                                let name = rule.field;
                                if (/^\d+$/.test(value)) {
                                  throw new Error(
                                    name + " can't have only numbers"
                                  );
                                } else if (/[^a-zA-Z0-9.\ \-\,]/.test(value)) {
                                  throw new Error(
                                    name + " can't have special characters"
                                  );
                                }
                              }
                            },
                          }),
                        ]}
                      >
                        <Input
                          placeholder="Input Reason"
                          style={{ width: "16vw" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="submit-button-wrapper">
                    <Col span="24" className="btn-container">
                      <Button type="primary" size="middle" htmlType="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Loader>
            </div>
          </Col>
          <Col span="2"></Col>
        </>
      ) : (
        <RequestStatus status={"pending"} />
      )}
    </>
  );
}

export default RequestForm;
