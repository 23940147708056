import React from "react";
import { Col, Popover, Table, Radio, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import "./corporateassessment.scss";

const LeadingLaggingUI = ({
  title = "",
  api_data = {},
  radioValue = "",
  onRadioChange,
  info_content,
  loader = false,
}) => {
  const columns = [
    {
      title:
        radioValue == "segment"
          ? "Segment"
          : radioValue == "bu"
          ? "Business Unit"
          : radioValue == "division"
          ? "Division"
          : radioValue == "site"
          ? "Site"
          : "",
      dataIndex: "self_name",
      key: "self_name",
      sorter: (a, b) =>
        a.self_name.toLowerCase() !== b.self_name.toLowerCase()
          ? a.self_name.toLowerCase() < b.self_name.toLowerCase()
            ? -1
            : 1
          : 0,
      className: "table-header",
      width: "8vw",
    },
    {
      title: "Overall Process Adherence (%)",
      dataIndex: "compliance_percent",
      key: "compliance_percent",
      width: "2vw",
      render: (value, record) => {
        let bg_color = record.compliance_color;
        let text_to_render = "";
        if (value) {
          if (
            record.partial_data_flag === true &&
            record.data_status_flag === "partial data"
          ) {
            text_to_render = (
              <>
                <span
                  className="dot"
                  style={{ backgroundColor: bg_color }}
                ></span>
                <Tooltip title="Partial Data Available">
                  {value.toFixed(2) + " % * "}
                </Tooltip>
              </>
            );
          } else if (record.data_status_flag === "full data") {
            text_to_render = (
              <>
                <span
                  className="dot"
                  style={{ backgroundColor: bg_color }}
                ></span>
                {value.toFixed(2) + " % "}
              </>
            );
          } else {
            text_to_render = "";
          }
        } else if (record.data_status_flag === "no data") {
          text_to_render = (
            <>
              <span className="dot" style={{ backgroundColor: "black" }}></span>
              <span className="no-data-cell">No Data</span>
            </>
          );
        } else {
          text_to_render = (
            <>
              <span
                className="dot"
                style={{ backgroundColor: bg_color }}
              ></span>
              <span> 0 % </span>
            </>
          );
        }
        return (
          <span>
            <span className="cell-data">{text_to_render}</span>
          </span>
        );
      },
      sorter: (a, b) => a.compliance_percent - b.compliance_percent,
      className: "table-header",
    },
  ];
  let tableData = [];
  if (radioValue == "segment") {
    tableData = api_data["segment"];
  } else if (radioValue == "bu") {
    tableData = api_data["businessUnit"];
  } else if (radioValue == "division") {
    tableData = api_data["division"];
  } else if (radioValue == "site") {
    tableData = api_data["site"];
  }

  return (
    <Col span={24} className="lagging-units-section">
      <span className="heading">{title}</span>
      <span className="info-icon">
        <Popover placement="right" content={info_content}>
          <InfoCircleFilled className="info-image" />
        </Popover>
      </span>
      <div className="content">
        <Radio.Group
          name="radiogroup"
          className="radiobtn"
          defaultValue={radioValue}
          onChange={onRadioChange}
        >
          <Radio value="segment">Segment</Radio>
          <Radio value="division">Division</Radio>
          <Radio value="bu">BU</Radio>
          {/* <Radio value="sub_bu">Sub BU</Radio> */}
          <Radio value="site">Site</Radio>
        </Radio.Group>

        <Table
          className="custom-table"
          loading={loader}
          columns={columns}
          showSorterTooltip={{ placement: "bottom" }}
          dataSource={tableData}
          pagination={(true, { defaultPageSize: 3 })}
          size="small"
        />
      </div>
    </Col>
  );
};

export default LeadingLaggingUI;
