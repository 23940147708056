import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ManageAccessTable from "./manage-access-table";
import { setSelectedView } from "../../slicers/home-slicer";
import { Layout, Row, Col, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import usericon from "../../assets/images/usericon.png";
import adminicon from "../../assets/images/adminicon.png";
import "./manageaccess.scss";
const { Content } = Layout;
const ManageAccess = () => {
  const { appRole } = useSelector((state) => state.manageAccess);
  const { selectedView } = useSelector((state) => state.homeView);
  const dispatch = useDispatch();
  useEffect(() => {
    let tempView = selectedView;
    if (selectedView === "" || selectedView === "home") {
      dispatch(setSelectedView("Raw"));
    } else {
      dispatch(setSelectedView(tempView));
    }
  }, []);
  return (
    <Content className="manage-access-wrapper">
      <Row className="page-title-wrapper">
        <Col className="page-title-text">Manage Access</Col>
      </Row>
      <Row>
        <Col md={24} className="add-buttons" align="right">
          {appRole !== "Approvers" ? (
            <>
              <Button
                type="primary"
                size="middle"
                className="add-user-btn"
                onClick={() => window.open(process.env.REACT_APP_USERS_LINK)}
              >
                <img src={usericon} className="user-icon" />
                Add User
              </Button>
              <Button
                type="primary"
                size="middle"
                className="add-super-admin-btn"
                onClick={() =>
                  window.open(process.env.REACT_APP_SUPER_ADMIN_LINK)
                }
              >
                <img src={adminicon} className="admin-icon" />
                Add Super Admin
              </Button>
              <Button
                type="primary"
                icon={<DownloadOutlined className="download-icon" />}
                size="middle"
                className="download-guide-btn"
                onClick={() => window.open(process.env.REACT_APP_UM_GUIDE_LINK)}
              >
                Get Detailed Guide
              </Button>
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <ManageAccessTable />
        </Col>
      </Row>
    </Content>
  );
};

export default ManageAccess;
