import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row } from "antd";
import "./request-access.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RequestForm from "./request-form";
import RequestStatus from "./request-status";
import { getUserRequestStatus } from "../../slicers/request-access-slicer";
import Loader from "../../components/loader";

function RequestAccess() {
  const [currentPage, setCurrentPage] = useState(true);
  const dispatch = useDispatch();
  const { user_status, status_flag } = useSelector(
    (state) => state.requestAccess
  );

  useEffect(() => {
    dispatch(getUserRequestStatus());
  }, []);

  useEffect(() => {
    //setflag and decide which screen to show here
    //form/pending/rejected
    if (status_flag == 2) {
      if (user_status == "pending") {
        setCurrentPage("pending");
      } else if (user_status == "rejected") {
        setCurrentPage("rejected");
      } else {
        setCurrentPage("form");
      }
    }
  }, [user_status, status_flag]);

  return (
    <Row className="request-page-wrapper">
      <ToastContainer />
      <div className="loaderWrapper">
        <Loader loading={status_flag != 2} tip={""}>
          {""}
        </Loader>
      </div>
      {currentPage == "pending" || currentPage == "rejected" ? (
        <RequestStatus status={currentPage} />
      ) : currentPage == "form" ? (
        <RequestForm />
      ) : (
        ""
      )}
    </Row>
  );
}

export default RequestAccess;
