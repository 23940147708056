import React from "react";
import { useSelector } from "react-redux";
import { Avatar, Dropdown, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import marslogo from "../../assets/images/marslogo.png";
import { msalInstance } from "../../utilities/auth/msal-config";
import ConnectedLOGO from "../../assets/images/ConnectedLOGO.png";

const menu = (props) => (
  <Menu>
    <Menu.Item key="0">
      <a rel="noopener noreferrer" onClick={props.handleLogout}>
        Logout
      </a>
    </Menu.Item>
  </Menu>
);

function Index(props) {
  const { appRole } = useSelector((state) => state.manageAccess);
  const { selectedView } = useSelector((state) => state.homeView);
  const handleLogout = async () => {
    sessionStorage.clear();
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length === 0) {
      msalInstance.logoutRedirect();
    } else {
      let homeAccountId = accounts[0].homeAccountId;
      const currentAccount = msalInstance.getAccountByHomeId(homeAccountId);

      await msalInstance.logoutRedirect({
        account: currentAccount,
      });
    }
  };
  const navigationPanelToRender = () => {
    if (selectedView === "home") {
      return (
        <ul className="menu">
          <li
            className={"menu-item" + " " + (props.tab === "0" ? "active" : "")}
            onClick={() => {
              if (props.tab !== "0") {
                props.history.push("/home");
              }
            }}
          >
            Home
          </li>
        </ul>
      );
    } else {
      return (
        <ul className="menu">
          <li
            className={"menu-item" + " " + (props.tab === "0" ? "active" : "")}
            onClick={() => {
              if (props.tab !== "0") {
                props.history.push("/home");
              }
            }}
          >
            Home
          </li>
          <li
            className={"menu-item" + " " + (props.tab === "5" ? "active" : "")}
            onClick={() => {
              if (props.tab !== "5") {
                props.history.push("/trending-view");
              }
            }}
          >
            Trending View
          </li>
          <li
            className={"menu-item" + " " + (props.tab === "1" ? "active" : "")}
            onClick={() => {
              if (props.tab !== "1") {
                props.history.push("/map-view");
              }
            }}
          >
            Map View
          </li>
          <li
            className={"menu-item" + " " + (props.tab === "2" ? "active" : "")}
            disabled={true}
            onClick={() => {
              if (props.tab !== "2") {
                props.history.push("/summary-view");
              }
            }}
          >
            Summary View
          </li>
          {selectedView === "Packaging" ? (
            <li
              className={
                "menu-item" + " " + (props.tab === "3" ? "active" : "")
              }
              onClick={() => {
                if (props.tab !== "3") {
                  props.history.push("/assessment");
                }
              }}
            >
              Packaging Assessment
            </li>
          ) : (
            <li
              className={
                "menu-item" + " " + (props.tab === "3" ? "active" : "")
              }
              onClick={() => {
                if (props.tab !== "3") {
                  props.history.push("/assessment");
                }
              }}
            >
              Material Assessment
            </li>
          )}
          {appRole === "Approvers" ? (
            <li
              className={
                "menu-item" + " " + (props.tab === "4" ? "active" : "")
              }
              onClick={() => {
                if (props.tab !== "4") {
                  props.history.push("/approver");
                }
              }}
            >
              Approver
            </li>
          ) : (
            ""
          )}
          {appRole === "Super.Admin" ? (
            <li
              className={
                "menu-item" + " " + (props.tab === "4" ? "active" : "")
              }
              onClick={() => {
                if (props.tab !== "4") {
                  props.history.push("/admin");
                }
              }}
            >
              Admin
            </li>
          ) : (
            ""
          )}
          <li
            className={"menu-item" + " " + (props.tab === "6" ? "active" : "")}
            onClick={() => {
              if (props.tab !== "6") {
                props.history.push("/faqs");
              }
            }}
          >
            FAQ
          </li>
        </ul>
      );
    }
  };
  return (
    <>
      <header className="site-header">
        <div className="header-left">
          <div className="site-logo">
            <img src={marslogo} width="70" height="20.18" alt="Mars" />
          </div>
          <div className="page-name">
            <ul>
              {selectedView === "Packaging" ? (
                <li>Sourcing Quality Heatmap: Packaging Materials</li>
              ) : selectedView === "home" ? (
                <li>Sourcing Quality Heatmap</li>
              ) : (
                <li>Sourcing Quality Heatmap: Raw Materials</li>
              )}
            </ul>
          </div>
          <div className="quality-logo">
            <img src={ConnectedLOGO} width="50" height="45" alt="Mars" />
          </div>
        </div>
        <div className="header-right">
          <div>{navigationPanelToRender()}</div>
          <div>
            <Dropdown
              overlay={() => menu({ ...props, handleLogout })}
              placement="bottomRight"
            >
              <a
                className="ant-dropdown-link profile-link"
                onClick={(e) => e.preventDefault()}
              >
                <Avatar
                  size={36}
                  icon={<UserOutlined />}
                  className={"user-image"}
                />
              </a>
            </Dropdown>
          </div>
        </div>
      </header>
    </>
  );
}

export default withRouter(Index);
