import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Layout, Row, Col, Button } from "antd";
import Wrapper from "../../components/wrapper";
import { DownloadOutlined } from "@ant-design/icons";
import {
  getMaterialDataDownloadExcel,
  getMaterialAssessmentData,
  setMaterialSorting,
  setDownloadFileName,
} from "../../slicers/material-assessment-slicer";
import "./materialassessment.scss";
import MaterialTable from "./material-table";
import { getLeadingLaggingData } from "../../slicers/corporate-assessment-slicer";
import { setSelectedView } from "../../slicers/home-slicer";
import FiltersApplied from "./filter-applied-section";
import SidebarFilters from "../filter-panel/filters";
import RefreshFailureAlert from "../../components/alerts/refresh-failure";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RadioButtons from "../../components/raw-pack-radio-btns/radioButtons";

const { Sider, Content } = Layout;

function MaterialAssessment() {
  const { last_Refresh_date, alertRefreshDate, currentDateIST, actualDateIST } =
    useSelector((state) => state.corporateAssessment);

  const {
    filters,
    oos_filters,
    oos_sites_list,
    is_out_of_scope_selected,
    appliedFilters,
    oos_appliedFilters,
    last_selected_filter_name,
    last_selected_filter_val,
    oos_last_selected_filter_name,
    oos_last_selected_filter_val,
  } = useSelector((state) => state.sidebarFilters);

  const {
    materials_api_data,
    loading,
    material_pagination,
    searchParams,
    searchFlag,
    donwloadFileName,
  } = useSelector((state) => state.materialAssessment);
  const { selectedView } = useSelector((state) => state.homeView);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLeadingLaggingData({}, is_out_of_scope_selected, selectedView));
  }, []);

  useEffect(() => {
    let tempView = selectedView;
    if (
      selectedView === "" ||
      selectedView === "home" ||
      selectedView === undefined
    ) {
      dispatch(setSelectedView("Raw"));
    } else {
      dispatch(setSelectedView(tempView));
    }
  }, []);

  useEffect(() => {
    if (selectedView === "Raw" || selectedView === "Packaging") {
      //anusha why check only for in scope applied filters ? why not for oos
      if (Object.entries(appliedFilters).length) {
        let currentFilters;
        //change payload
        if (is_out_of_scope_selected) {
          currentFilters = {
            [oos_last_selected_filter_name]: oos_last_selected_filter_val,
          };
        } else {
          currentFilters = {
            [last_selected_filter_name]: last_selected_filter_val,
          };
        }
        dispatch(
          getMaterialAssessmentData(
            currentFilters,
            material_pagination,
            [],
            is_out_of_scope_selected,
            searchParams,
            selectedView
          )
        );
      } else {
        dispatch(
          getMaterialAssessmentData(
            {},
            material_pagination,
            [],
            is_out_of_scope_selected,
            searchParams,
            selectedView
          )
        );
      }
    }
  }, [appliedFilters, searchParams, searchFlag, selectedView]);

  const handleTableChange = (pagination, table_filters, sorter) => {
    let sorting = [];
    if (Object.entries(sorter).length) {
      let keyName = sorter["columnKey"];
      if (sorter["order"] == "descend") {
        keyName = "-" + sorter["columnKey"];
        sorting.push(keyName);
      } else if (sorter["order"] == "ascend") {
        keyName = sorter["columnKey"];
        sorting.push(keyName);
      }
    }

    let currentFilters;
    //change payload
    if (is_out_of_scope_selected) {
      currentFilters =
        Object.entries(oos_filters).length > 0
          ? {
              [oos_last_selected_filter_name]: oos_last_selected_filter_val,
            }
          : oos_filters;
    } else {
      currentFilters =
        Object.entries(filters).length > 0
          ? {
              [last_selected_filter_name]: last_selected_filter_val,
            }
          : filters;
    }
    dispatch(
      getMaterialAssessmentData(
        currentFilters,
        pagination,
        sorting,
        is_out_of_scope_selected,
        searchParams,
        selectedView
      )
    );
    dispatch(setMaterialSorting(sorting));
  };

  return (
    <Wrapper heading={"3"}>
      <div className="material_assessment">
        <Layout>
          <Sider className="sidebar">
            <SidebarFilters
              module="material_assessment"
              pagination={material_pagination}
            />
          </Sider>
          <Layout className="content_wrapper">
            <Content>
              <Row wrap={false} className="title_row">
                <Col span={18} align="left" className="radio-buttons-wrapper">
                  <RadioButtons />
                </Col>
                <Col span={6} className="last_updated_text" align="right">
                  <span>
                    {last_Refresh_date ? (
                      <div>
                        Daily Refresh
                        <br /> Last Refreshed Date is:{" "}
                        <span
                          className={
                            alertRefreshDate ? "alert-refresh-date" : ""
                          }
                        >
                          {last_Refresh_date}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                  {currentDateIST !== last_Refresh_date &&
                  actualDateIST !== last_Refresh_date ? (
                    <RefreshFailureAlert />
                  ) : (
                    <></>
                  )}
                </Col>
                <ToastContainer />
              </Row>
              <Row className="title-download-btn" wrap={false}>
                <Col span={12}>
                  {selectedView === "Packaging" ? (
                    <h2 className="page-title">Packaging Assessment</h2>
                  ) : (
                    <h2 className="page-title">Material Assessment</h2>
                  )}
                </Col>
                <Col span={12} className="searchWrapper" align="right">
                  <Button
                    type="custom"
                    className="download-btn"
                    icon={<DownloadOutlined />}
                    shape="round"
                    onClick={() =>
                      dispatch(
                        getMaterialDataDownloadExcel(
                          {
                            ...filters,
                          },
                          selectedView
                        )
                      )
                    }
                  >
                    Download Report
                  </Button>
                </Col>
              </Row>
              <FiltersApplied
                appliedFilters={
                  is_out_of_scope_selected ? oos_appliedFilters : appliedFilters
                }
              />
              {is_out_of_scope_selected && oos_sites_list.length > 0 ? (
                <span className="oos-note">
                  Note: You have selected out of scope site/s{" "}
                  {oos_sites_list.map((d, i) => (
                    <span key={d}>{d + ", "}</span>
                  ))}
                  in site name filter selection
                </span>
              ) : (
                ""
              )}
              <MaterialTable
                handleTableChange={handleTableChange}
                apiPagination={material_pagination}
                data={materials_api_data.items ? materials_api_data.items : []}
                loader={loading}
              />
            </Content>
          </Layout>
        </Layout>
      </div>
    </Wrapper>
  );
}

export default withRouter(MaterialAssessment);
