export const SERVICE_URL = process.env.REACT_APP_SERVICE_URL;
export const ENDPOINTS = {};
export const Base_URL = SERVICE_URL;
export const Url_QA = "https://qualityheatmap-sourcing-api-qa.cloud-effem.com/";
export const verify_user = "api/generic/verifyUser";
export const getOverallPercentage = "api/get_overall_perc";
export const trendCharts = "api/trends/";
export const changeSummaryData = "api/change-summary";
export const left_section_filters = "api/dropdowns";
export const requestAccessFilters = "api/request-access-dropdowns";
export const leading_lagging_units = "api/leading_lagging_units";
export const world_map_url = "api/get_world_map";
export const material_assessment_data_url = "api/get_materials_table";
export const material_assessment_dropdowns = "api/get_materials_dropdowns";
export const material_data_download = "api/download_materials_table";
export const material_data_download_excel = "api/download_materials_table_xl";
export const overallAdherenceDownload = "api/download_site_location";
export const requirementViewDownload = "api/download_site_element";
export const GET_AUTH_DATA_API = ".auth/me";
export const get_oos_flags = "api/get_oos_flags";
export const site_location_bar_charts = "api/bar_charts";
export const site_element_radio = "api/site_element_radio";
export const site_location_radio = "api/site_location_radio";
export const request_access_api = "api/request-access";
export const get_user_status = "api/requests";
//admin apis
export const get_input_tool_data = "api/thresholds";
export const getAdminRequestData = "api/requests";
