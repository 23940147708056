import React from "react";
import { Row, Col } from "antd";
import titles from "../../utilities/generic-titles";
import { appliedSection } from "../../utilities/helper";

const FiltersApplied = ({ appliedFilters }) => {
  let actualValues = { ...appliedFilters };
  Object.keys(actualValues).forEach((key) => {
    if (actualValues[key].length === 0) {
      delete actualValues[key];
    }
  });
  return (
    <>
      <Row>
        <Col span={24}>
          <div className="selected-filters">
            <label className="filter-title">Filters Applied:</label>
            {Object.entries(actualValues).length > 0 ? (
              <>
                <div className="filter-item">
                  {titles.filter_names.segment} -{" "}
                  <span>{appliedSection(appliedFilters.site_segment)}</span>
                </div>
                <div className="filter-item">
                  {titles.filter_names.division} -{" "}
                  <span>
                    {appliedSection(appliedFilters.division)}
                  </span>
                </div>
                <div className="filter-item">
                  {titles.filter_names.bu} -{" "}
                  <span>
                    {appliedSection(appliedFilters.site_business_unit)}
                  </span>
                </div>
                {/* <div className="filter-item">
                  {titles.filter_names.sub_bu} -{" "}
                  <span>
                    {appliedSection(appliedFilters.site_sub_business_unit)}
                  </span>
                </div> */}
                <div className="filter-item">
                  {titles.filter_names.unit_type} -{" "}
                  <span>{appliedSection(appliedFilters.site_unit_type)}</span>
                </div>
                <div className="filter-item">
                  {titles.filter_names.site_name} -{" "}
                  <span>{appliedSection(appliedFilters.site_unit_name)}</span>
                </div>
              </>
            ) : (
              <span className="filter-title">No Filters are applied.</span>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FiltersApplied;
