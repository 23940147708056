import axios from "axios";
import { msalInstance, request } from "../auth/msal-config";
let numberOfAjaxCAllPending = 0;

// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        numberOfAjaxCAllPending++;
        // show loader
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        numberOfAjaxCAllPending--;

        if (numberOfAjaxCAllPending === 0) {
            //hide loader
        }
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export async function AuthorizationAxios(url, method, msalAccount) {
    try {
        let headers = {};
        const param = { ...request, ...{ account: msalAccount } };

        const token = await msalInstance.acquireTokenSilent(param);
        if (token && token.accessToken)
            headers.Authorization = `Bearer ${token.accessToken}`;

        return axios({
            url,
            method,
            headers: {
                "Content-Type": "application/json",
                CUSTOMAUTH:
                    "hAGTPqPABYtay1zGxChjOJcCQEOleSSk-o4HLPv2a95n0mx9xBmNvKT2ukzL9OSBL",
                ...headers,
            },
        });
    } catch (error) { }
}

export async function CustomAxiosPlane(url, method, body) {
    try {
        return axios({
            url,
            method,
            data: body,
        });
    } catch (error) { }
}

async function CustomAxios(
    url,
    method,
    body,
    responseType = false,
    headers = {}
) {
    try {
        const param = { ...request, ...{ account: msalInstance.getAllAccounts()[0] } };
        const token = await msalInstance.acquireTokenSilent(param);
        if (token && token.accessToken) headers.Authorization = `Bearer ${token.accessToken}`;
        return responseType
            ? axios({
                url,
                method,
                data: body,
                headers,

                responseType: "arraybuffer",
            })
            : axios({
                url,
                method,
                data: body,
                headers,
            });
    } catch (error) { }
}

export async function CustomAxiosDE(
    url,
    method,
    body,
    responseType = false,
    headers = {}
) {
    try {
        const param = { ...request, ...{ account: msalInstance.getAllAccounts()[0] } };
        const token = await msalInstance.acquireTokenSilent(param);
        if (token && token.accessToken) headers.Authorization = `Bearer ${token.accessToken}`;
        return responseType
            ? axios({
                url,
                method,
                data: body,
                headers: {
                    "Content-Type": "application/zip",
                    CUSTOMAUTH:
                        "hAGTPqPABYtay1zGxChjOJcCQEOleSSk-o4HLPv2a95n0mx9xBmNvKT2ukzL9OSBL",
                    ...headers,
                },
                responseType: "arraybuffer",
            })
            : axios({
                url,
                method,
                data: body,
                headers: {
                    "Content-Type": "application/json",
                    CUSTOMAUTH:
                        "hAGTPqPABYtay1zGxChjOJcCQEOleSSk-o4HLPv2a95n0mx9xBmNvKT2ukzL9OSBL",
                    ...headers,
                },
            });
    } catch (error) { }
}

export default CustomAxios;