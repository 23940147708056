import React from "react";
import { Typography, Tooltip, Input, Space, Button } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

export const showErrorMessage = () => {
  toast.error("Something Went Wrong! Please try again", {
    position: toast.POSITION.TOP_CENTER,
    className: "toast-message",
  });
};
export const showErrorWithCustomMessage = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_CENTER,
    className: "toast-message",
  });
};
export const showSuccessMessage = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_CENTER,
    className: "toast-message",
  });
};
export const showWarningWithCustomMessage = (msg) => {
  toast.warning(msg, {
    position: toast.POSITION.TOP_CENTER,
    className: "toast-message",
  });
};

export const handleSearch = (
  confirm,
  setSearchText,
  setSearchedColumn,
  selectedKeys,
  dataIndex
) => {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};

export const getColumnSearchProps = (
  title,
  dataIndex,
  searchText,
  setSearchText,
  searchedColumn,
  setSearchedColumn,
  searchInput
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
  }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`Search ${title}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() =>
          handleSearch(
            confirm,
            setSearchText,
            setSearchedColumn,
            selectedKeys,
            dataIndex
          )
        }
        style={{
          marginBottom: 8,
          display: "block",
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() =>
            handleSearch(
              confirm,
              setSearchText,
              setSearchedColumn,
              selectedKeys,
              dataIndex
            )
          }
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? "#1890ff" : "#0000a0",
      }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      text
    ),
});

export const appliedSection = (filterCategory) => {
  if (Array.isArray(filterCategory)) {
    if (filterCategory.length === 1) {
      return filterCategory.join();
    } else if (filterCategory.length === 0) {
      return "";
    } else {
      return (
        <Tooltip
          color="white"
          title={filterCategory.map((i) => {
            return (
              <Typography type="secondary" key={i}>
                {i}
              </Typography>
            );
          })}
          placement="bottom"
        >{`${filterCategory[0]}...`}</Tooltip>
      );
    }
  }
};
