import React from "react";
import { useSelector } from "react-redux";
import "./admin-layout.scss";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  TeamOutlined,
  FlagOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
const { Sider } = Layout;

const AdminSider = ({ collapsed, changeModule, setCollapsed }) => {
  const { appRole } = useSelector((state) => state.manageAccess);
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className="admin-sider"
    >
      <div>
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          }
        )}
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["Manage Access"]}>
        <Menu.Item
          key="Manage Access"
          icon={<TeamOutlined />}
          onClick={() => {
            changeModule("Manage Access");
          }}
        >
          <span>Manage Access</span>
        </Menu.Item>
        {appRole !== "Approvers" ? (
          <Menu.Item
            key="Input Tools"
            icon={<FlagOutlined />}
            onClick={() => {
              changeModule("Input Tools");
            }}
          >
            <span>Input Tool</span>
          </Menu.Item>
        ) : (
          ""
        )}
      </Menu>
    </Sider>
  );
};
export default AdminSider;
