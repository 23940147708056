import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { getState } from "./store";

const browserHistory = createBrowserHistory({ basename: "" });

const userName = sessionStorage.getItem("email");
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
ai.loadAppInsights();
ai.addTelemetryInitializer((envelope) => {
  let user = "";
  const data = getState();
  if (envelope.baseData.user_AccountId) {
    user = envelope.baseData.user_AccountId.toLowerCase();
  } else if (userName) {
    user = userName.toLowerCase();
  } else {
    user = data.profile.user.user_email.toLowerCase();
  }
  envelope.tags = envelope.tags || [];
  envelope.tags.push({ "ai.user.accountId": user });
});
export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
