import React, { useEffect, useState } from "react";

import { Table } from "antd";
import "./adminInputToolsModule.scss";
import { columns } from "./columns";

const ThresholdTable = ({ data = [], loader = true }) => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <Table
      className="custom-table threshold-table"
      columns={columns}
      dataSource={tableData}
      bordered
      size="small"
      pagination={false}
      loading={loader}
      width={"100px"}
    />
  );
};
export default ThresholdTable;
