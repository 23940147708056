import React from 'react';
import './joins.scss';
export const CombinedCircles = () => {
    return (
        <div className='combined-circles'>
            <ul>
                <li>
                <div className="first-circle"><span>MATERIAL+GSL</span></div>
                <p style={{color: 'black', fontSize: 10}}>MARS SITE</p>
                </li>
                <li>
                <div className="second-circle"><span>MATERIAL+GSL</span></div>
                <p style={{color: 'black', fontSize: 10}}>MRA</p>
                </li>
                <li>
                <div className="third-circle"><span>GRD</span></div>
                <p style={{color: 'black', fontSize: 10}}>SPEC</p>
                </li>
                <li>
                <div className="fourth-circle"><span>MATERIAL</span></div>
                <p style={{color: 'black', fontSize: 10}}>SUPPLIER STATUS</p>
                </li>
                <li>
                <div className="fifth-circle"><span>SITE+MATERIAL+GSL</span></div>
                <p style={{color: 'black', fontSize: 10}}>INBOUND</p>
                </li>
            </ul>
            {/* <ul>
                <li>
                    <div className="first-circle">
                        <span className='upper-circle-text'>MATERIAL+GSL</span>
                        <div className='vertical-line'></div>
                    </div>
                    <span className='lower-circle-text'>MARS SITE</span>
                </li>
                <li>
                    {" "}
                    <div className="second-circle">
                        <span className='upper-circle-text'>MATERIAL+GSL</span>
                        <div className='vertical-line'></div>
                    </div>
                    <span className='lower-circle-text'>MRA</span>
                </li>
                <li>
                    <div className="third-circle">
                        <span className='upper-circle-text'>GRD</span>
                        <div className='vertical-line'></div>
                    </div>
                    <span className='lower-circle-text'>SPEC</span>
                </li>
                <li>
                    <div className="fourth-circle">
                        <span className='upper-circle-text'>MATERIAL</span>
                        <div className='vertical-line'></div>
                    </div>
                    <span className='lower-circle-text'>SUPPLIER STATUS</span>
                </li>
                <li>
                    <div className="fifth-circle">
                        <span className='upper-circle-text'>SITE+MATERIAL+GSL</span>
                        <div className='vertical-line'></div>
                    </div>
                    <span className='lower-circle-text'>INBOUND</span>
                </li>
            </ul> */}
        </div>
    );
}