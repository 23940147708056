import React from "react";
import { Typography, Col } from "antd";
import accessImg from "../../assets/images/request-page-img1.png";
const { Title } = Typography;

function RequestStatus({ status, showForm }) {
  return (
    <>
      <Col span="16" offset="4" className="request-page-content">
        {status == "pending" ? (
          <>
            <div className="pending-image-wrapper">
              <img src={accessImg}></img>
            </div>
            <Title className="welcome-text" level={3}>
              Hello! Your request is pending with us.
              <br />
              Thank You.
            </Title>
          </>
        ) : (
          ""
        )}
        {status == "rejected" ? (
          <>
            <div className="rejected-image-wrapper">
              <img src={accessImg}></img>
            </div>
            <Title className="welcome-text" level={4}>
              We regret to inform you that, your request to access this
              application has been rejected.
              <br />
              Please reach out to your line manager in case of any
              clarification. Thank You.
            </Title>
          </>
        ) : (
          ""
        )}
      </Col>
      <Col span="4"></Col>
    </>
  );
}

export default RequestStatus;
