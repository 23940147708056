import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Input, Modal, Table, Row, Col } from "antd";
import "./adminInputToolsModule.scss";
import { columns } from "./columns";
import {
  updateInputTool,
  setThresholdUpdateLoader,
} from "../../slicers/admin-input-tools-slicer";
import Loader from "../../components/loader";
import { showWarningWithCustomMessage } from "../../utilities/helper";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const ThresholdEditForm = ({
  data = [],
  loader = 1,
  handleEdit,
  onThresholdUpdateSuccess,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [latestFormValues, setLatestFormValues] = useState([]);
  const [thresholdRange, setThresholdRange] = useState([]);
  const [thresholdIds, setThresholdIds] = useState([]);
  const [updatedIds, setUpdatedIds] = useState([]);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { thresholdUpdateLoader } = useSelector(
    (state) => state.adminInputTools
  );
  useEffect(() => {
    dispatch(setThresholdUpdateLoader(true));
  }, []);
  useEffect(() => {
    let formElements = {};
    if (data.length > 0) {
      data.forEach((rec, i) => {
        formElements["lt_" + rec.id] = rec.lower_threshold;
        formElements["ut_" + rec.id] = rec.upper_threshold;
        formElements["color_" + rec.id] = rec.color_codes;
      });
      setEditValues(formElements);
      dispatch(setThresholdUpdateLoader(false));
    }
  }, [data]);
  useEffect(() => {
    if (Object.entries(editValues).length) {
      const startsS = Object.keys(editValues).filter((rec) => {
        return rec.startsWith("lt");
      });
      let id_array = [];

      startsS.forEach((d, i) => {
        let tempArray = d.split("_");
        let valueIndex = tempArray[1];
        id_array.push(Number(valueIndex));
      });
      let smallest_id = Math.min(...id_array);
      let largest_id = Math.max(...id_array);
      let threshold_range = [];
      threshold_range.push("lt_" + smallest_id);
      threshold_range.push("ut_" + largest_id);
      setThresholdRange(threshold_range);
      setThresholdIds(id_array);
    }
  }, [editValues]);

  const onValuesChange = (value, all) => {
    form.validateFields();
    let changedKey = Object.keys(value);
    let changedValue = Object.values(value);
    let tempArray = changedKey[0].split("_");
    let valueType = tempArray[0];
    let valueIndex = tempArray[1];
    let chnagedThresholds = updatedIds;
    chnagedThresholds.push(Number(valueIndex));
    if (valueType == "lt") {
      /*if we are updating lt then check:
       lt<ut of same index/ change ut of current index minus 1 */

      let prev_ut = "ut_" + (Number(valueIndex) - 1);
      let param = {};
      param[prev_ut] = changedValue[0];
      form.setFieldsValue(param);
      chnagedThresholds.push(Number(valueIndex) - 1);
    } else if (valueType == "ut") {
      /*if we are updating ut then check:
      ut>lt of same index/ change lt of current index plus 1 /ut <ut of current index+1/
      ut cannot be equal to next threshold's ut */
      let next_lt = "lt_" + (Number(valueIndex) + 1);
      let param = {};
      param[next_lt] = changedValue[0];
      form.setFieldsValue(param);
      chnagedThresholds.push(Number(valueIndex) + 1);
    }
    setUpdatedIds([...new Set(chnagedThresholds)]);
  };

  const onFinish = (values) => {
    if (updatedIds.length == 0) {
      showWarningWithCustomMessage(
        "Please update threshold values to continue submit!"
      );
    } else {
      const isFormValid = form
        .getFieldsError()
        .filter(({ errors }) => errors.length);

      let newData = Object.assign({}, data);
      let newArr = [];
      thresholdIds.map((id, index) => {
        let lt_key_name = Object.keys(values).filter((rec) => {
          return rec.startsWith("lt") && rec.endsWith(id);
        });
        let ut_key_name = Object.keys(values).filter((rec) => {
          return rec.startsWith("ut") && rec.endsWith(id);
        });
        Object.values(newData).map((entry, i) => {
          if (entry.id == id) {
            let tempObj = {};

            tempObj.color_codes = newData[index].color_codes;
            tempObj.id = newData[index].id;
            tempObj.upper_threshold = values[ut_key_name[0]];
            tempObj.lower_threshold = values[lt_key_name[0]];
            newArr.push(tempObj);
          }
        });
      });
      if (!isFormValid.length) {
        setLatestFormValues(newArr);
        setShowModal(true);
      }
    }
  };
  const onCancel = () => {
    form.resetFields();
    handleEdit(false);
  };
  const handleOk = () => {
    // call update api here with edited threshold ids and threshold json
    let params = latestFormValues.filter(
      (rec, i) => updatedIds.indexOf(rec.id) != -1
    );
    dispatch(updateInputTool(params));
    setShowModal(false);
    onThresholdUpdateSuccess();
  };
  const handleCancel = () => {
    setShowModal(false);
  };
  return (
    <>
      <Loader loading={thresholdUpdateLoader} tip={"Loading"}>
        {Object.entries(editValues).length > 0 ? (
          <Form
            form={form}
            name="basic"
            initialValues={editValues}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            autoComplete="off"
            size="small"
            requiredMark={false}
          >
            <Row className="form-buttons">
              <Col span={24} className="button-wrapper" align="right">
                <Button
                  type="default"
                  size="middle"
                  onClick={() => {
                    onCancel();
                  }}
                  className="cancel-btn"
                >
                  Cancel
                </Button>
                <Button type="primary" size="middle" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
            {data.map((record, i) => {
              let id = record.id;
              let disableLT = i === 0 ? true : false;
              let disableUT = i === data.length - 1 ? true : false;
              return (
                <Row className="form-row" key={record.id}>
                  <Col span={2} offset={4}>
                    {"Threshold " + (i + 1)}
                  </Col>
                  <Col span={3} offset={1}>
                    <Form.Item
                      name={"lt_" + id}
                      rules={[
                        {
                          required: true,
                          message: "Please input Lower Threshold",
                        },

                        ({ getFieldValue }) => ({
                          validator: async (rule, value) => {
                            let lt_value = Number(value);
                            if (lt_value) {
                              let name = rule.field;

                              let tempArray = name.split("_");
                              let valueIndex = tempArray[1];
                              let prev_lt = "lt_" + (Number(valueIndex) - 1);
                              let current_ut = "ut_" + Number(valueIndex);
                              let current_ut_value = getFieldValue(current_ut);

                              let prev_lt_value = getFieldValue(prev_lt);
                              if (
                                lt_value < prev_lt_value &&
                                thresholdRange.indexOf(name) == -1
                              ) {
                                throw new Error(
                                  `lower threshold cannot be less than previous LT ${prev_lt_value}`
                                );
                              } else if (
                                lt_value == prev_lt_value &&
                                thresholdRange.indexOf(name) == -1
                              ) {
                                throw new Error(
                                  `lower threshold cannot be equal to previous LT ${prev_lt_value}`
                                );
                              } else if (
                                lt_value == current_ut_value &&
                                thresholdRange.indexOf(name) == -1
                              ) {
                                throw new Error(
                                  `lower threshold cannot be equal to upper threshold ${current_ut_value}`
                                );
                              } else if (
                                lt_value >= current_ut_value &&
                                thresholdRange.indexOf(name) == -1
                              ) {
                                throw new Error(
                                  `lower threshold cannot exceed upper threshold ${current_ut_value}`
                                );
                              } else if (
                                lt_value > 99 &&
                                thresholdRange.indexOf(name) == -1
                              ) {
                                throw new Error(
                                  "value cannot exceed the upper threshold limit (100)"
                                );
                              } else if (
                                lt_value <= 0 &&
                                thresholdRange.indexOf(name) == -1
                              ) {
                                throw new Error(
                                  "value cannot be less than lower threshold limit (0)"
                                );
                              }
                            }
                          },
                        }),
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="lower threshold"
                        className="inputfield"
                        size="small"
                        disabled={disableLT}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3} offset={1}>
                    <Form.Item
                      name={"color_" + id}
                      rules={[
                        {
                          required: true,
                          message: "Please input Threshold color",
                        },
                      ]}
                    >
                      <Input
                        placeholder="color"
                        className="inputfield"
                        type="color"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3} offset={1}>
                    <Form.Item
                      name={"ut_" + id}
                      rules={[
                        {
                          required: true,
                          message: "Please input Upper Threshold",
                        },

                        ({ getFieldValue }) => ({
                          validator: async (rule, value) => {
                            let ut_value = Number(value);
                            if (ut_value) {
                              let name = rule.field;

                              let tempArray = name.split("_");
                              let valueIndex = tempArray[1];
                              let current_lt = "lt_" + Number(valueIndex);
                              let next_ut = "ut_" + (Number(valueIndex) + 1);
                              let current_lt_value = getFieldValue(current_lt);
                              let next_ut_value = getFieldValue(next_ut);

                              if (
                                ut_value > next_ut_value &&
                                thresholdRange.indexOf(name) == -1
                              ) {
                                throw new Error(
                                  `upper threshold cannot exceed the next UT ${next_ut_value}`
                                );
                              } else if (
                                ut_value == next_ut_value &&
                                thresholdRange.indexOf(name) == -1
                              ) {
                                throw new Error(
                                  `upper threshold cannot be equal to next UT ${next_ut_value}`
                                );
                              } else if (
                                ut_value == current_lt_value &&
                                thresholdRange.indexOf(name) == -1
                              ) {
                                throw new Error(
                                  `upper threshold cannot be equal to lower threshold ${current_lt_value}`
                                );
                              } else if (
                                current_lt_value > ut_value &&
                                thresholdRange.indexOf(name) == -1
                              ) {
                                throw new Error(
                                  `upper threshold cannot be less than lower threshold ${current_lt_value}`
                                );
                              } else if (
                                ut_value > 99 &&
                                thresholdRange.indexOf(name) == -1
                              ) {
                                throw new Error(
                                  "value cannot exceed the upper threshold limit (100)"
                                );
                              } else if (
                                ut_value < 0 &&
                                thresholdRange.indexOf(name) == -1
                              ) {
                                throw new Error(
                                  "value cannot be less than lower threshold limit (0)"
                                );
                              }
                            }
                          },
                        }),
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="upper threshold"
                        className="inputfield"
                        disabled={disableUT}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}></Col>
                </Row>
              );
            })}
          </Form>
        ) : (
          ""
        )}
      </Loader>
      <SummaryModal
        showModal={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        data={latestFormValues}
      />
    </>
  );
};
const SummaryModal = ({ showModal, handleOk, handleCancel, data }) => {
  return (
    <Modal
      title="Confirm Threshold Update"
      open={showModal}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className="modal-confirm-text">
        Please find the updated threshold values below, Are you sure you want to
        continue ?
      </div>
      <Table
        className="custom-table threshold-table"
        columns={columns}
        dataSource={data}
        bordered
        size="small"
        pagination={false}
        width={"100px"}
      />
    </Modal>
  );
};
export default ThresholdEditForm;
